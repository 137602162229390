import { useState } from 'react';
import TabbedChart from './tabbedChart';

export function rawDataToChartMetadata(rawData) {
  const pmpToChipsCollection = (pmps) =>
    pmps.map((pmp) => ({ title: pmp.productName }));
  return rawData.map((dataItem) => ({
    dropdownTitle: dataItem.title,
    chips: pmpToChipsCollection(dataItem.productMetricPairings),
  }));
}

export default function MetricScriptsSphereViewProvider({
  metricComponentType,
  style,
  data,
}) {
  const [selectionState, setSelectionState] = useState({
    chipIndex: 0,
    dropdownIndex: 0,
  });
  const { chipIndex, dropdownIndex } = selectionState;
  const dropdownOptions = data;
  const chipsOptions = dropdownOptions[dropdownIndex].productMetricPairings;
  const filteredData = chipsOptions[chipIndex];

  // eslint-disable-next-line no-shadow
  const handleChipSelect = ({ chipIndex, dropdownIndex }) => {
    setSelectionState({ chipIndex, dropdownIndex });
  };

  return (
    <TabbedChart
      data={filteredData}
      metricComponentType={metricComponentType}
      style={style}
      onChipSelect={handleChipSelect}
      chartMetadata={rawDataToChartMetadata(data)}
    />
  );
}
