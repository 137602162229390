import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../request/config';

export const useEntityCoordinates = ({ maptualListMetadata, project }) =>
  useQuery({
    queryKey: ['entity-coordinates', maptualListMetadata.maptualListId],
    queryFn: () =>
      getEntityCoordinates(
        project?.metadata?.productLineId,
        maptualListMetadata.maptualListId
      ),
    enabled: !!maptualListMetadata.maptualListId,
  });

export const getEntityCoordinates = (productLineId, regionId) =>
  maptualApiInstance.get(
    `core/product-lines/${productLineId}/regions/${regionId}/entities/coordinates`,
    {
      timeout: 10000,
    }
  );
