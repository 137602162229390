import { useRef, useState, useEffect } from 'react';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { styled, Button, Box, Skeleton } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import Scrollbar from 'react-scrollbars-custom';
import ProfilePicNLinks from './entityProfile/profilePicNLinks';
import ProfileDetailsAndConnections from './entityProfile/profileDetailsAndConnections';
import ProfileJourneyView from './entityProfile/profileJourneyView';
import { trackEntityProfileDetailSelection } from '../../trackers/appEventTracker';
import { useEntityJourney } from '../../containers/fieldview/useEntityJourney';
import { ErrorContainer } from '../generic/errorContainer';

const useStyles = makeStyles(({ themeColors }) => ({
  scrollView: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
  },
  stepperStyle: {
    margin: 0,
    borderRadius: 10,
    padding: 0,
    marginTop: 10,
    backgroundColor: themeColors.mainBackground,
  },
  dot: {
    backgroundColor: themeColors.buttonBackgroundColor,
  },
  dotActive: {
    backgroundColor: themeColors.buttonContentColor,
  },
  stepperButton: {
    color: themeColors.buttonContentColor,
    '&:hover': {
      backgroundColor: themeColors.buttonBackgroundColor,
    },
  },
}));

const SwipeContentBox = styled(Box)(() => ({
  maxWidth: '100%',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: 5,
  marginTop: 5,
}));

const DrawerWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  width: 435,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.journeyDrawerBackgroundColor,
}));

const SkeletonLoadingBar = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ theme: { themeColors }, height }) => ({
  backgroundColor: themeColors.surfaceEmpty,
  transform: 'scale(1)',
  height,
  width: '100%',
}));

const TimelineWrapper = styled(Box)(({ theme: { spacing, themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: themeColors.modalBackgroundColor,
  width: '100%',
  height: '100%',
  gap: spacing(1.5),
  padding: spacing(3),
}));

const LoadingTimeline = () => (
  <TimelineWrapper>
    <SkeletonLoadingBar height="64px" />
    <SkeletonLoadingBar height="36px" />
    <SkeletonLoadingBar height="105px" />
    <SkeletonLoadingBar height="105px" />
    <SkeletonLoadingBar height="105px" />
  </TimelineWrapper>
);

const ErrorOnFetching = ({ handleRetry }) => (
  <TimelineWrapper>
    <ErrorContainer handleRetry={handleRetry} />
  </TimelineWrapper>
);

export function EntityJourney({
  entityName,
  entityId,
  maptualListId,
  projectId,
  entityUData,
}) {
  const {
    entityJourney,
    isEntityJourneyBusy,
    isEntityJourneyError,
    refetchEntityJourney,
  } = useEntityJourney({
    entityType: 'HCP',
    entityId,
    maptualListId,
    projectId,
  });

  const swipeableViewRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const stepCount = 2;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === 1) {
      // TODO proper mapping steps to know which view is triggered dynamically
      trackEntityProfileDetailSelection(entityName);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [connectionsLoaded, setConnectionsLoaded] = useState(false);
  useEffect(() => {
    if (connectionsLoaded) swipeableViewRef.current.updateHeight();
  }, [connectionsLoaded]);

  const [isEntityJourneyLoading, setIsEntityJourneyLoading] = useState(true);

  useEffect(() => {
    if (!isEntityJourneyBusy) {
      setIsEntityJourneyLoading(false);
    }
  }, [isEntityJourneyBusy]);

  const retryEntityJourney = () => {
    setIsEntityJourneyLoading(true);
    refetchEntityJourney();
  };

  if (isEntityJourneyError) {
    return <ErrorOnFetching handleRetry={retryEntityJourney} />;
  }

  return (
    <DrawerWrapper>
      {isEntityJourneyLoading ? (
        <LoadingTimeline />
      ) : (
        <Scrollbar
          disableTracksWidthCompensation
          noScrollX
          className={`${classes.scrollView} intercom-field-entity-journey`}
        >
          <Box padding="30px">
            <ProfilePicNLinks entityUData={entityUData} />
            <Box width="100%">
              <MobileStepper
                variant="dots"
                steps={stepCount}
                position="static"
                activeStep={activeStep}
                classes={{
                  root: classes.stepperStyle,
                  dot: classes.dot,
                  dotActive: classes.dotActive,
                }}
                nextButton={
                  <Button
                    className={classes.stepperButton}
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === stepCount - 1}
                  >
                    Next
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    className={classes.stepperButton}
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
            <SwipeContentBox>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                animateHeight
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                ignoreNativeScroll
                ref={swipeableViewRef}
              >
                <ProfileJourneyView entityJourney={entityJourney} />
                <ProfileDetailsAndConnections
                  entityUData={entityUData}
                  onConnectionsLoaded={() => {
                    if (!connectionsLoaded) {
                      setConnectionsLoaded(true);
                    }
                  }}
                />
              </SwipeableViews>
            </SwipeContentBox>
          </Box>
        </Scrollbar>
      )}
    </DrawerWrapper>
  );
}
