import { useContext } from 'react';
import { styled, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { trackEntityOverviewObjectiveDropdown } from '../../../../trackers/mixpanel';

const ObjectiveSelect = styled(Select)(({ theme: { themeColors } }) => ({
  minWidth: 237,
  maxWidth: 340,
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.menuTextColor,
  '.MuiSelect-select': {
    padding: '8px 12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.buttonBorderColor,
  },
  svg: {
    color: themeColors.buttonContentColor,
  },
}));

export const ObjectiveSelector = () => {
  const { entityType, metadata, objectiveId, setObjectiveId } = useContext(
    EntityOverviewContext
  );

  const handleObjectiveChange = (objective) => {
    trackEntityOverviewObjectiveDropdown(objective.label, entityType);
    setObjectiveId(objective.id);
  };

  return (
    <ObjectiveSelect
      data-testid="objective-selector"
      key={objectiveId}
      value={objectiveId}
      IconComponent={KeyboardArrowDownIcon}
    >
      {Array.isArray(metadata?.objectives) &&
        metadata?.objectives.map((objective) => (
          <MenuItem
            key={objective.id}
            value={objective.id}
            onClick={() => handleObjectiveChange(objective)}
          >
            {objective.label}
          </MenuItem>
        ))}
    </ObjectiveSelect>
  );
};
