import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

export const columns = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    flex: 1,
    minWidth: 165,
    valueGetter: (params) =>
      params?.row ? `${params.row?.firstName} ${params.row?.lastName}` : '',
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    flex: 1,
    minWidth: 165,
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    type: 'date',
    flex: 1,
    minWidth: 165,
    valueFormatter: (params) => {
      const value = params ? params.value : '';

      return new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
  {
    field: 'sendInviteEmail',
    headerName: '',
    minWidth: 25,
    maxWidth: 40,
    renderCell: (params) =>
      params?.row?.inviteEmailSent ? (
        <MarkEmailReadIcon />
      ) : (
        <MailOutlineIcon />
      ),
  },
  {
    field: 'edit',
    headerName: '',
    minWidth: 25,
    maxWidth: 40,
    renderCell: () => <EditOutlinedIcon />,
  },
  {
    field: 'menu',
    headerName: '',
    minWidth: 25,
    maxWidth: 40,
    renderCell: () => <MoreVertIcon />,
    valueGetter: (params) => params,
  },
];
