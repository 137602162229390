import { styled, Typography, Box } from '@mui/material';
import Sell from '@mui/icons-material/Sell';
import { InsightsCard } from './insightsCard/insightsCard';

const TagIconView = styled((props) => <Sell {...props} />)`
  height: 16px;
  width: 16px;
  margin-top: 2px;
`;

const FlexWrapper = styled(Box)(() => ({
  display: 'flex',
}));
const LeaveBehinds = styled(FlexWrapper)(({ theme: { themeColors } }) => ({
  gridGap: 10,
  alignItems: 'center',
  svg: {
    height: 16,
    width: 16,
    color: themeColors.textButtonColor,
  },
}));
const ErrorLeaveBehinds = styled(LeaveBehinds)(
  ({ theme: { themeColors } }) => ({
    p: {
      color: themeColors.primaryMaptualListFilteringColor,
    },
  })
);
const LeaveBehindsRow = styled(FlexWrapper)(() => ({
  gridGap: 16,
  flexWrap: 'wrap',
}));

export const InsightsLeaveBehinds = ({ maptualTopLeaveBehinds }) => (
  <InsightsCard title="Top Influential Leave Behinds" minimal>
    <LeaveBehindsRow>
      {maptualTopLeaveBehinds &&
        maptualTopLeaveBehinds.map((leaveBehind) => (
          <LeaveBehinds key={leaveBehind.disbursementName}>
            <TagIconView />
            <Typography variant="body2">
              {leaveBehind.disbursementName}
            </Typography>
          </LeaveBehinds>
        ))}
      {!maptualTopLeaveBehinds ||
        (maptualTopLeaveBehinds.length === 0 && (
          <ErrorLeaveBehinds>
            <Typography variant="body2">
              Not enough data available to generate leave behinds for this HCP.
            </Typography>
          </ErrorLeaveBehinds>
        ))}
    </LeaveBehindsRow>
  </InsightsCard>
);
