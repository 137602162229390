import { useQuery } from 'react-query';
import { getEntityJourney } from '../../request/projectRequests';

const getEntityJourneyFromApi = ({
  entityType,
  entityId,
  maptualListId,
  projectId,
}) => {
  if (!entityType || !entityId || !maptualListId || !projectId) {
    return Promise.reject();
  }

  return getEntityJourney({
    entityType,
    entityId,
    maptualListId,
    projectId,
  });
};

export const useEntityJourney = ({
  entityType,
  entityId,
  maptualListId,
  projectId,
}) => {
  const {
    data: entityJourney,
    isLoading: isEntityJourneyBusy,
    isError: isEntityJourneyError,
    refetch: refetchEntityJourney,
  } = useQuery(
    [projectId, maptualListId, 'entity', entityType, entityId, 'entityJourney'],
    async () => {
      try {
        const response = await getEntityJourneyFromApi({
          entityType,
          entityId,
          maptualListId,
          projectId,
        });
        return response.data;
      } catch {
        return null;
      }
    }
  );

  return {
    entityJourney,
    isEntityJourneyBusy,
    isEntityJourneyError,
    refetchEntityJourney,
  };
};
