import { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { DEFAULT_CHART_HEIGHT } from './constants';
import { getLegendColors } from './utils';
import { removeYearData } from '../../../../utils/removeYearData';
import { AppContext } from '../../../../containers/application/appContext';
import formatPercent from '../../../../utils/formatPercent';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
  },
}));

const HistoricalScriptChart = ({
  customHeight = DEFAULT_CHART_HEIGHT,
  data,
  numberFormatter,
  rowSelected,
}) => {
  const classes = useStyles();
  const { themeColors } = useTheme();
  const { options, series, xaxis, title: yAxisTitle } = data[rowSelected];
  const xAxisColors = [
    ...Array(xaxis.categories.length - 1).fill(
      themeColors.primaryMaptualListFilteringColor
    ),
    themeColors.primaryTextColor,
  ];
  const labelStyles = {
    fontSize: '12px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 400,
  };
  const formattedXAxis = removeYearData(xaxis.categories);
  const nowElement = [formattedXAxis[formattedXAxis.length - 1], 'NOW'];

  const { profile } = useContext(AppContext);
  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    if (
      profile.role.type !== 'UNKNOWN' &&
      typeof profile.role.permission.ALLOW_CSV_DOWNLOAD === 'boolean'
    ) {
      /**
       * TODO: Using ALLOW_CSV_DOWNLOAD to determine whether we should
       *  show the toolbar (that contains more than just CSV) is misleading.
       *
       *  Action: we should change the userProfileRole response
       */
      setShowToolbar(profile.role.permission.ALLOW_CSV_DOWNLOAD);
    }
  }, [profile.role]);

  const showCappedPercentage = data[0].series[0].name
    .toLowerCase()
    .includes('iz rate');

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: showToolbar,
      },
    },
    colors: getLegendColors(rowSelected, themeColors),
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: 'straight',
      width: 3,
      dashArray: options.dashArray,
    },
    grid: {
      show: true,
      borderColor: themeColors.contentCardBorderColor,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },

    xaxis: {
      tickPlacement: 'on',
      labels: {
        show: true,
        hideOverlappingLabels: true,
        style: { ...labelStyles, colors: xAxisColors },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories:
        [...formattedXAxis.slice(0, formattedXAxis.length - 1), nowElement] ||
        [],
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: numberFormatter,
        show: true,
        hideOverlappingLabels: true,
        style: {
          ...labelStyles,
          colors: themeColors.primaryMaptualListFilteringColor,
        },
      },
      title: {
        text: yAxisTitle,
        style: {
          ...labelStyles,
          color: themeColors.primaryMaptualListFilteringColor,
        },
      },
      tickAmount: computeTickAmount(series, 6),
      min: 0,
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter: showCappedPercentage
          ? (value) => formatPercent(value, 75)
          : numberFormatter,
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
  };

  return (
    <div className={classes.root}>
      <Chart
        data-testid="test-script-data-chart"
        data-test-state="check-chart-appears"
        options={chartOptions}
        series={series}
        type="line"
        height={customHeight}
        width="100%"
      />
    </div>
  );
};

const computeTickAmount = (data, maxTickAmount) => {
  const dataSet = new Set();
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in data) {
    data[key].data.forEach((value) => dataSet.add(parseInt(value, 10)));
  }
  return Math.min(dataSet.size, maxTickAmount);
};

export default HistoricalScriptChart;
