import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Fade } from '@mui/material';
import { isEmpty } from 'lodash';
import useMaptualContext from '../../../../hooks/useMaptualContext';
import { APPVIEWS } from '../../../../constants';
import ShareChartHeader from './shareChartHeader';
import DropdownSelections from '../dropdownSelections';
import ShareChartTitle from './shareChartTitle';
import ChartTabs from './chartTabs';
import ShareGraph from '../graphs/shareGraph';
import ShareTableController from './shareTableController';
import { METRIC_CHART_TAB_CADENCES } from '../tabbedChart/constants';

const useStyles = makeStyles(
  ({ insightContentCardStyle, themeColors, palette }) => ({
    root: {
      ...insightContentCardStyle(`${palette.mode}Theme`),
      backgroundColor: themeColors.contentCardBackgroundColor,
      borderColor: themeColors.contentCardBorderColor,
    },
    tabbedDashBoardChart: {
      width: '100%',
    },
    tabbedFieldChart: {
      width: '100%',
      marginBottom: 16,
    },
    shareTableRoot: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      color: '#000000',
    },
  })
);

export default function TabbedShareChart({ data, style, metricComponentType }) {
  const { appView, isStandalonePage } = useMaptualContext();
  const classes = useStyles();
  const [dropdownSelected, setDropdownSelected] = useState(0);
  const [rowSelected, setRowSelected] = useState(0);
  const [tabSelected, setTabSelected] = useState(
    data?.[dropdownSelected]?.graphTabs[0]
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setTabSelected(data?.[dropdownSelected]?.graphTabs[0]);
    }
  }, [data?.[0]?.title]);

  if (isEmpty(data)) return null;

  const handleTabSelect = (tab) => {
    if (tab.id !== tabSelected.id) {
      setTabSelected(tab);
    }
  };

  const handleRowSelect = (e, index) => {
    if (index !== rowSelected && index >= 0) {
      setRowSelected(index);
    }
  };

  const handleSelectDropdown = (e, index) => {
    if (index !== dropdownSelected && index < data.length && index >= 0) {
      setDropdownSelected(index);
      setTabSelected(data?.[index]?.graphTabs[0]);
      setRowSelected(0);
    }
  };

  function getTabDataFromId(tabId) {
    const selectedTabObject = data?.[dropdownSelected]?.graphData?.find(
      (tabData) => Object.keys(tabData)[0] === tabId
    );
    return !selectedTabObject ? null : Object.values(selectedTabObject)?.[0];
  }

  const annualTabId = data?.[dropdownSelected]?.graphTabs.find(
    (tabData) => tabData.value === METRIC_CHART_TAB_CADENCES.ANNUAL
  )?.id;
  const annualTabData = getTabDataFromId(annualTabId);

  const selectedTabData = getTabDataFromId(tabSelected.id);

  if (data?.length > 0 && dropdownSelected + 1 > data.length) {
    handleSelectDropdown(null, 0);
  }

  return (
    <Fade
      in
      timeout={1200}
      key="shareData"
      className={
        appView === APPVIEWS.FIELD || isStandalonePage
          ? classes.tabbedFieldChart
          : classes.tabbedDashBoardChart
      }
    >
      <div className={classes.tabbedDashBoardChart} style={style}>
        <div
          className={`${classes.root} intercom-field-market-share-graph`}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <ShareChartHeader />
          {data.length > 1 && (
            <div className="intercom-field-market-share-objective-selector">
              <DropdownSelections
                selectionName="Objective"
                options={data}
                selected={dropdownSelected}
                onSelect={handleSelectDropdown}
              />
            </div>
          )}
          <ShareChartTitle title={data?.[dropdownSelected]?.title} />
          <ChartTabs
            tabs={data?.[dropdownSelected]?.graphTabs}
            selectedTab={tabSelected}
            setCurrentTab={handleTabSelect}
            annualTargetRow={annualTabData?.dataList?.listItems?.[0]}
            metricComponentType={metricComponentType}
          />

          <div className={classes.shareTableRoot}>
            <ShareGraph
              selectedData={selectedTabData}
              selectedTab={tabSelected}
              customHeight={style?.customHeight}
            />
            <ShareTableController
              selectedData={selectedTabData}
              selectedTab={tabSelected}
              rowSelected={rowSelected}
              handleRowSelect={handleRowSelect}
              metricComponentType={metricComponentType}
              timeframeSelection={tabSelected.name}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}
