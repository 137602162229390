import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  getTerritoryOverviewData,
  getTerritoryOverviewMetadata,
} from '../../request/territoryOverviewRequests';
import {
  territoryOverviewMockMetadata,
  territoryOverviewMockSupplyData,
  territoryOverviewMockDemandData,
} from './fixtures/territoryOverviewMockData';
import {
  disclaimerOptions,
  Distribution,
  DISTRIBUTION_CONFIG,
} from './constants';
import { getDemoAccountStatus, enabledFixtures } from '../../request/config';
import {
  anonymizeTerritoryOverviewData,
  anonymizeTerritoryOverviewMetadata,
} from './anonymizeTerritoryOverview';

const EMPTY_DATA_RESPONSE = {
  timescaleKeys: [
    {
      id: 'month',
      name: 'Month',
    },
    {
      id: 'quarter',
      name: 'Quarter',
    },
  ],
  timescaleData: {
    month: {
      description: '',
      headers: [],
      items: [],
    },
    quarter: {
      description: '',
      headers: [],
      items: [],
    },
  },
};

const isTruthySelectedMetric = (selectedMetric) =>
  selectedMetric === 0 ? true : !!selectedMetric;

export const useTerritoryOverviewData = ({
  distributionType,
  setDistributionType,
  categoryOptions,
  setCategoryOptions,
  projectId,
  maptualListId,
  metricOptions,
  selectedMetric,
  selectedCategory,
  setMetricOptions,
  setSelectedCategory,
  setSelectedMetric,
  setSelectedTimeframeID,
}) => {
  const isDemoAccount = getDemoAccountStatus();

  const fetchTerritoryOverviewData = async () => {
    if (projectId && maptualListId && metricOptions && categoryOptions) {
      const params = {
        projectId,
        regionId: maptualListId,
        metric: metricOptions[selectedMetric].rxType,
        distribution: distributionType,
        categoryId: categoryOptions[selectedCategory].id,
      };

      try {
        let response;

        if (
          isDemoAccount ||
          (enabledFixtures && enabledFixtures.includes('entityOverview'))
        ) {
          response = {
            data:
              distributionType === Distribution.DEMAND
                ? territoryOverviewMockDemandData
                : territoryOverviewMockSupplyData,
            status: 200,
          };
        } else {
          const serverResponse = await getTerritoryOverviewData(params);

          if (serverResponse?.status === 204) {
            response = {
              data: disclaimerOptions.INVALID_REGION,
              status: 204,
            };
          } else {
            response = serverResponse;
          }
        }

        return response;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] fetchTerritoryOverviewData', err);
      }
    }

    return {};
  };

  const fetchTerritoryOverviewMetadata = async () => {
    if (projectId && maptualListId) {
      const params = {
        projectId,
        regionId: maptualListId,
      };

      try {
        if (
          isDemoAccount ||
          (enabledFixtures && enabledFixtures.includes('entityOverview'))
        ) {
          return {
            data: anonymizeTerritoryOverviewMetadata(
              territoryOverviewMockMetadata
            ),
            status: 200,
          };
        }

        const response = await getTerritoryOverviewMetadata(params);

        if (response?.status === 204) {
          return {
            data: disclaimerOptions.INVALID_REGION,
            status: response.status,
          };
        }

        return response;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] fetchTerritoryOverviewData', err);
      }
    }

    return {};
  };

  const {
    isFetching: isMetadataLoading,
    data: metadataResponse,
    refetch: refetchMetadata,
  } = useQuery({
    queryKey: ['territory-overview-metadata', projectId, maptualListId],
    queryFn: fetchTerritoryOverviewMetadata,
    cacheTime: 0,
  });

  const {
    isFetching: isDataLoading,
    isIdle: isDataRequestIdle,
    data: response,
    refetch,
  } = useQuery(
    ['territory-overview', projectId, maptualListId],
    fetchTerritoryOverviewData
  );

  const [dataResponse, setDataResponse] = useState({});

  const clearSettingOptions = () => {
    setCategoryOptions();
    setSelectedCategory();
    setMetricOptions();
    setSelectedMetric();
    setDistributionType();
    setSelectedTimeframeID('month');
  };

  useEffect(() => {
    if (
      metadataResponse?.data &&
      metadataResponse.data !== disclaimerOptions.INVALID_REGION
    ) {
      const metadata = metadataResponse.data;
      const defaultDistribution = metadata.availableDistributions?.[0];

      if (!defaultDistribution) {
        setDataResponse(EMPTY_DATA_RESPONSE);
        return;
      }

      setDistributionType(defaultDistribution || '');

      const { category: categoryKey } =
        DISTRIBUTION_CONFIG[defaultDistribution];

      const newCategoryOptions = metadata[categoryKey];

      setCategoryOptions(newCategoryOptions);
    }

    if (
      metadataResponse?.data &&
      metadataResponse.data === disclaimerOptions.INVALID_REGION
    ) {
      clearSettingOptions();
    }
  }, [metadataResponse?.data]);

  const [previousMaptualListId, setPreviousMaptualListId] =
    useState(maptualListId);

  useEffect(() => {
    if (!previousMaptualListId) {
      setPreviousMaptualListId(maptualListId);
    } else if (maptualListId !== previousMaptualListId) {
      refetchMetadata();
      setPreviousMaptualListId(maptualListId);
    }
  }, [maptualListId, previousMaptualListId]);

  useEffect(() => {
    if (
      selectedCategory !== undefined &&
      typeof distributionType === 'string' &&
      categoryOptions?.length > 0
    ) {
      setMetricOptions(categoryOptions[selectedCategory]?.metrics);
    }
  }, [distributionType, selectedCategory]);

  useEffect(() => {
    if (metricOptions?.length > 0) {
      setSelectedMetric(0);
      refetch();
    }
  }, [metricOptions]);

  useEffect(() => {
    if (
      !!metricOptions &&
      isTruthySelectedMetric(selectedMetric) &&
      !!metricOptions[selectedMetric]
    ) {
      refetch();
    }
  }, [selectedMetric]);

  useEffect(() => {
    if (response?.data) {
      if (isDemoAccount && response?.data && response?.status === 200) {
        const anonymizedResponse = anonymizeTerritoryOverviewData(
          response.data,
          distributionType
        );

        setDataResponse(anonymizedResponse);
      } else {
        setDataResponse(response?.data);
      }
    }

    return () => {
      setDataResponse(undefined);
    };
  }, [response?.data]);

  return {
    dataResponse,
    status: response?.status,
    metadataResponse,
    metadataStatus: metadataResponse?.status,
    isDataLoading,
    isDataRequestIdle,
    isMetadataLoading,
  };
};
