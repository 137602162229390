import { styled } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from './appContext';

const drawerWidth = 500;
const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  flexGrow: 1,
  position: 'relative',
  overflowX: 'clip',
}));
export const ContentWrapper = ({ children }) => {
  const { settings } = useContext(AppContext);
  return (
    <Main id="main-content" open={settings.showEvents}>
      {children}
    </Main>
  );
};
