import { useEffect, useState } from 'react';
import { styled, Skeleton, Box, Typography, Badge } from '@mui/material';
import {
  GenericTooltip,
  TooltipContent,
} from '@odaia/ui/src/tooltip';

const Wrapper = styled(Box)(({ width }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '4px',
  maxWidth: 'fit-content',
  minWidth: width,
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: themeColors.badgePrimaryColor,
  padding: '2px 4px',
  borderRadius: '3px',
  width: 'fit-content',
  maxWidth: '120px',
}));

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.badgePrimaryContentColor,
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '12px',
}));

const LoadingContainer = styled(Box)(() => ({
  margin: 'auto',
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

export const BadgeContainer = ({
  content,
  isLoading,
  maxItems = 3,
  width = 150,
}) => {
  const [initialContent, setInitialContent] = useState([]);
  const [ellipsesTooltipContent, setEllipsesTooltipContent] = useState(null);

  useEffect(() => {
    setInitialContent(content.slice(0, maxItems));
    setEllipsesTooltipContent(TooltipContent(content));
  }, [content]);

  return (
    <Wrapper width={width}>
      {isLoading && (
        <LoadingContainer>
          <StyledSkeleton height={35} width={width} />
        </LoadingContainer>
      )}
      {!isLoading &&
        initialContent &&
        initialContent.length > 0 &&
        initialContent.map((value, i) => (
          <Container key={value.id}>
            <GenericTooltip title={value.title}>
              <StyledBadge>
                <StyledTypography noWrap>{value.title}</StyledTypography>
              </StyledBadge>
            </GenericTooltip>
            {i === initialContent.length - 1 &&
              content.length !== initialContent.length && (
                <GenericTooltip title={ellipsesTooltipContent}>
                  <StyledBadge>
                    <StyledTypography>{`+${
                      content.length - initialContent.length
                    }`}</StyledTypography>
                  </StyledBadge>
                </GenericTooltip>
              )}
          </Container>
        ))}
    </Wrapper>
  );
};
