import { useTheme } from '@mui/styles';
import { Box, styled } from '@mui/material';
import { getColorFromTrend } from '../../../../utils/getColorFromTrend';
import { SparklineLineGraph } from './lineGraph';

const LineGraphBox = styled(Box)(() => ({
  height: '200px',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  padding: '0px 16px 32px 16px',
}));

export const ExpandedLineGraphContainer = ({
  row,
  chartLabels,
  valueType,
  yTitle,
  tooltipDataPointTitle,
  graphColor,
}) => {
  const { themeColors } = useTheme();
  const { data, trend } = row;

  return (
    <LineGraphBox>
      <SparklineLineGraph
        id={`expanded-line-graph-${data.join('-')}`}
        data={data}
        valueType={valueType}
        labels={chartLabels}
        color={graphColor ?? getColorFromTrend(trend, themeColors)}
        expanded
        tooltipDataPointTitle={tooltipDataPointTitle}
        yTitle={yTitle}
      />
    </LineGraphBox>
  );
};
