import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { EntityDrawerError } from '../EntityDrawerError';
import { ERROR_TYPES_ENUM, UNITS_ENUM } from '../../../constants';
import {
  formatYAxisValue,
  getCadences,
  getUnits,
  getYAxisMaxValue,
} from '../drawerHelpers';
import { createTooltip, INITIAL_TOOLTIP_STATE, Tooltip } from '../Tooltip';
import { DrawerChartSkeleton } from '../../skeletons/DrawerChartSkeleton';
import { TabGroup } from '../EntityDrawerChartTabs';
import {
  GraphControls,
  GraphSection,
  GraphTitle,
} from './sharedStyledComponents';
import { TooltipPopover } from '../../../../shared/TooltipPopover';
import { CHART_TYPES_ENUM } from '../../../../shared/graphs/constants';
import { useEntityDrawerChartData } from '../data/useEntityDrawerData';
import { EntityDrawerContext } from '../data/entityDrawerContext';

export const EntityDrawerBarGraphCard = ({
  sectionTitle,
  metrics,
  objectiveId,
}) => {
  const { entityType, entityId, regionId, projectId, productLineId } =
    useContext(EntityDrawerContext);

  const [availableCadences, setAvailableCadences] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [cadence, setCadence] = useState();
  const [unit, setUnit] = useState();
  const [selectedMetric, setSelectedMetric] = useState();

  useEffect(() => {
    const cadences = getCadences(metrics);
    const units = getUnits(metrics);

    setAvailableCadences(cadences);
    setAvailableUnits(units);
  }, [metrics]);

  useEffect(() => {
    if (availableCadences && availableCadences.length > 0) {
      const defaultCadence = availableCadences[0];
      const selectedCadenceNotAvailable = !availableCadences.find(
        (c) => c.id === cadence
      );
      if (!cadence || selectedCadenceNotAvailable) {
        setCadence(defaultCadence?.id);
      }
    }
  }, [availableCadences]);

  useEffect(() => {
    if (availableUnits && availableUnits.length > 0) {
      const defaultUnit = availableUnits[0];
      const selectedUnitNotAvailable = !availableUnits.find(
        (u) => u.id === unit
      );

      if (!unit || selectedUnitNotAvailable) {
        setUnit(defaultUnit.id);
      }
    }
  }, [availableUnits]);

  useEffect(() => {
    if (unit && cadence) {
      const metricObject = metrics.find(
        (metric) => metric.unit === unit && metric.cadences.includes(cadence)
      );

      setSelectedMetric(metricObject);
    } else if (unit) {
      const metricObject = metrics.find((metric) => metric.unit === unit);

      setSelectedMetric(metricObject);
    }
  }, [cadence, unit]);

  const { data, isFetching, isError } = useEntityDrawerChartData({
    productLineId,
    projectId,
    regionId,
    objectiveId,
    chartType: CHART_TYPES_ENUM.BAR,
    metric: selectedMetric?.rxType,
    cadence,
    entityType,
    entityId,
  });

  const [graphTitle, setGraphTitle] = useState();
  useEffect(() => {
    if (data) {
      setGraphTitle(data.title);
    }
  }, [data]);

  return (
    <GraphSection>
      <Typography variant="h4">{sectionTitle}</Typography>
      <GraphControls>
        <TabGroup
          availableTabs={availableUnits}
          tab={unit}
          setTab={setUnit}
          sectionTitle={sectionTitle}
          graphType={CHART_TYPES_ENUM.BAR}
        />
        <TabGroup
          availableTabs={availableCadences}
          tab={cadence}
          setTab={setCadence}
          sectionTitle={sectionTitle}
          graphType={CHART_TYPES_ENUM.BAR}
        />
      </GraphControls>

      {isFetching && <DrawerChartSkeleton />}
      {isError && (
        <EntityDrawerError
          errorType={ERROR_TYPES_ENUM.RETRY}
          retryType="chart"
        />
      )}

      {!!data && (
        <>
          <GraphTitle>
            <Typography variant="h6">{graphTitle}</Typography>
            <TooltipPopover
              title={data?.title}
              description={selectedMetric?.tooltipText}
            />
          </GraphTitle>

          <Box height="200px" position="relative">
            <BarGraph unit={UNITS_ENUM[unit]} rawData={data} />
          </Box>
        </>
      )}
    </GraphSection>
  );
};

export const BarGraph = ({ unit, rawData }) => {
  const { themeColors } = useTheme();

  const [tooltip, setTooltip] = useState(INITIAL_TOOLTIP_STATE);

  const labels = rawData?.data.map(
    (item) => `${item.timestamp.match(/\d{4}/)} YTD`
  );
  const data = {
    labels,
    datasets: [
      {
        data: rawData?.data.map((item) => item.data),
        backgroundColor: themeColors.dataGeneralColor,
        barThickness: 48,
        trends: rawData?.data.map((item) => item.trend),
        timestamps: rawData?.data.map((item) => `Jan 1 - ${item.timestamp}`),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: (context) => {
          createTooltip({
            context,
            currentTooltip: tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
            unit,
          });
        },
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: getYAxisMaxValue(data),
        border: { display: false },
        grid: {
          drawTicks: false,
          color: themeColors.borderPrimaryColor,
          lineWidth: 1,
        },
        ticks: {
          callback: (value) => formatYAxisValue(value, unit),
          color: themeColors.tertiaryColor,
          padding: 10,
          maxTicksLimit: 6,
        },
        title: {
          display: !!unit,
          text: unit,
          font: 12,
          color: themeColors.tertiaryColor,
        },
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          drawTicks: false,
        },
        ticks: {
          color: themeColors.tertiaryColor,
        },
      },
    },
  };

  if (rawData?.data.length === 0) {
    return <EntityDrawerError errorType={ERROR_TYPES_ENUM.NO_DATA} />;
  }

  return (
    <>
      <Bar options={options} data={data} />
      <Tooltip tooltip={tooltip} title={rawData?.title} />
    </>
  );
};
