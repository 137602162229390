export const DEFAULT_ENTITY_CADENCE = 'mth';
export const HEADER_LABEL_DEFAULT_SIZE = 16;
export const SCORE_COLUMN_WIDTH = 115;
export const SEGMENT_COLUMN_WIDTH = 208;
export const BADGE_COLUMN_WIDTH = 140;
export const CHART_LINE_DASH_PATTERN = [3, 5];
export const LINE_CHART_TOP_GAP = 1.1;
export const EM_DASH = '—';

export enum CADENCES_ENUM {
  wk = 'Weekly',
  mth = 'Monthly',
  qtr = 'Quarterly',
  yr = 'Annually',
  mat = 'MAT',
  r3m = 'R3M',
}

export enum UNITS_ENUM {
  unit = 'Units',
  dollar = 'Dollars',
}

export enum TABLE_HEADER_TYPES_ENUM {
  LABEL = 'label',
  SCORE = 'score',
  RATE = 'rate',
  CUSTOMER_SEGMENT = 'customer_segment',
  BEHAVIOURAL_SEGMENT = 'behavioural_segment',
  NUMERICAL_BADGE = 'numerical_badge',
  DRAWER = 'drawer',
}

export enum LONG_FORM_CADENCE_ENUM {
  wk = 'week',
  mth = 'month',
  qtr = 'quarter',
  yr = 'year',
  mat = 'month',
}

export enum SHORT_FORM_CADENCE_ENUM {
  WK = 'wk',
  MTH = 'mth',
  QTR = 'qtr',
  YR = 'yr',
  MAT = 'mat',
}

export enum ERROR_TYPES_ENUM {
  NON_TERRITORY = 'NON_TERRITORY',
  NO_DATA = 'NO_DATA',
  NO_FILTER_DATA = 'NO_FILTER_DATA',
  NO_SEARCH_DATA = 'NO_SEARCH_DATA',
  RETRY = 'RETRY',
}

export enum ENTITY_TYPES_ENUM {
  OUTLET = 'outlet',
  DIRECT_ACCOUNT = 'direct_account',
}

export enum TREND_STATUSES_ENUM {
  INCREASING = 2,
  NO_CHANGE = 1,
  DECREASING = 0,
  VALID_METRIC_DATA_STATUS = 0,
  CANNOT_PREDICT = -1,
  INCREASING_OUT_OF_RANGE = -2,
  DECREASING_OUT_OF_RANGE = -3,
}

export enum URL_SEARCH_PARAMS_ENUM {
  REGION_ID = 'filterRegionID',
  REGION_LABEL = 'filterRegionLabel',
}

export enum CSV_EXPORT_STATUS_ENUM {
  SUCCESS = 'Success',
  TIMEOUT = 'Timeout',
  FAILURE = 'Failure',
}
