import { maptualApiInstance } from './config';

export function getEntityViewsConfig(marketId) {
  return maptualApiInstance.get(`/field/entity-views-config`, {
    params: {
      market_id: marketId,
    },
    timeout: 30000,
  });
}

export function getEntityOverviewMetadata({
  marketId,
  productLineId,
  projectId,
  regionId,
  entityType,
}) {
  return maptualApiInstance.get(`field/entity-overview/metadata`, {
    params: {
      product_line_id: productLineId,
      project_id: projectId,
      region_id: regionId,
      market_id: marketId,
      entity_type: entityType,
    },
    timeout: 30000,
  });
}

export function getEntityOverviewData({
  productLineId,
  projectId,
  regionId,
  entityType,
  objectiveId,
  cadence,
  entitySubtype,
  filters,
}) {
  return maptualApiInstance.get(`field/entity-overview`, {
    params: {
      product_line_id: productLineId,
      project_id: projectId,
      region_id: regionId,
      entity_type: entityType,
      objective_id: objectiveId,
      cadence,
      entity_subtype: entitySubtype,
      filters,
    },
    timeout: 60000,
  });
}

export function getEntityDrawerMetadata({
  productLineId,
  marketId,
  projectId,
  regionId,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(`field/entity-overview/drawer-metadata`, {
    params: {
      product_line_id: productLineId,
      project_id: projectId,
      region_id: regionId,
      market_id: marketId,
      entity_type: entityType,
      entity_id: entityId,
    },
    timeout: 30000,
  });
}

export function getEntityDrawerChartData({
  productLineId,
  regionId,
  objectiveId,
  chartType,
  metric,
  cadence,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(`field/entity-overview/drawer-chart`, {
    params: {
      product_line_id: productLineId,
      region_id: regionId,
      objective_id: objectiveId,
      metric,
      cadence,
      entity_type: entityType,
      entity_id: entityId,
      chart_type: chartType,
    },
    timeout: 30000,
  });
}

export function getEntityDrawerProfile({ marketId, entityType, entityId }) {
  return maptualApiInstance.get(`field/entity-overview/drawer-profile`, {
    params: {
      market_id: marketId,
      entity_type: entityType,
      entity_id: entityId,
    },
    timeout: 30000,
  });
}

export function getEntityDrawerTimeline({
  projectId,
  regionId,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(`field/entity-overview/drawer-timeline`, {
    params: {
      project_id: projectId,
      region_id: regionId,
      entity_type: entityType,
      entity_id: entityId,
    },
    timeout: 30000,
  });
}

export function getEntityOverviewCSVMetadata({ projectId, entityType }) {
  return maptualApiInstance.get(`field/entity-overview/csv-metadata`, {
    params: {
      project_id: projectId,
      entity_type: entityType,
    },
    timeout: 30000,
  });
}

export function getEntityOverviewCSV({
  productLineId,
  projectId,
  regionId,
  entityType,
  objectiveId,
  cadence,
  dates,
  columns,
}) {
  const columnsString = columns.map((i) => `columns=${i}`).join('&');
  const datesString = dates.map((i) => `dates=${i}`).join('&');

  return maptualApiInstance.get(
    `field/entity-overview/csv?${datesString}&${columnsString}`,
    {
      params: {
        product_line_id: productLineId,
        project_id: projectId,
        region_id: regionId,
        objective_id: objectiveId,
        cadence,
        entity_type: entityType,
      },
      timeout: 60000,
    }
  );
}
