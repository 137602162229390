/* eslint-disable no-nested-ternary */

import makeStyles from '@mui/styles/makeStyles';
import { ListItemButton, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { TypographyDefinitions } from '@odaia/ui/src/typography';
import TrendValue from '../trendValues';
import abbreviateNumber from '../../../../utils/abbreviateNumber';
import formatPercent from '../../../../utils/formatPercent';
import HistoricalTable from './historicalTable';
import { lighten } from './utils';
import { trackTimeframeTableRowSelection } from '../../../../trackers/appEventTracker';
import { selectMetricComponentData } from '../../../../selectors/project';

const LegendMarker = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  aspect-ratio: 1/1;
  background-color: ${(props) => props.backgroundColor};
`;

const LegendTitle = styled(Typography)`
  font-weight: ${TypographyDefinitions.fontWeight.regular};
  line-height: 1.1;
  color: ${(props) => props.color};
  ${(props) =>
    props.isSelected &&
    props.isFirstElement &&
    `font-weight: ${TypographyDefinitions.fontWeight.medium}`};
`;

const LegendContentCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
`;

const dataCellWidth = 115;
const cornerRadius = 8;
const rowHeight = 45;
const cellLeftPadding = 5;
const cellPadding = 10;
const prevDataCellWidth = 90;
const useStyles = makeStyles(({ themeColors }) => ({
  headerRow: {
    width: '100%',
    height: rowHeight,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: `1fr ${prevDataCellWidth}px repeat(2, ${dataCellWidth}px)`,
  },
  headerPrimaryText: {
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.primaryMaptualListFilteringColor,
    lineHeight: 1.2,
    wrapBreak: 'break-word',
    whiteSpace: 'normal',
  },
  headerSecondaryText: {
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.primaryMaptualListFilteringColor,
    lineHeight: 1.3,
  },
  headerCellVertical: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 5,
  },
  listContainer: {
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listInnerRow: {
    width: '100%',
    display: 'grid',
    flexGrow: 1,
    gridTemplateColumns: `1fr ${prevDataCellWidth}px repeat(2, ${dataCellWidth}px)`,
  },
  mainValueStyle: {
    color: themeColors.primaryTextColor,
  },
  subValueStyle: {
    color: `${lighten(themeColors.primaryTextColor)}`,
  },
  dataCell: {
    display: 'flex',
    padding: cellPadding,
    paddingLeft: cellLeftPadding,
  },
  innerDataWrapper: {
    display: 'flex',
  },
}));

export default function TabDataListTable({
  selectedData,
  rowSelected,
  onRowSelect,
  metricComponentType,
  timeframeSelection,
}) {
  const { themeColors } = useTheme();
  const theme = themeColors;
  const classes = useStyles();
  const { entityName, maptualListSegmentName, maptualListLabel } = useSelector(
    selectMetricComponentData
  );

  if (Array.isArray(selectedData)) {
    return (
      <HistoricalTable
        data={selectedData}
        rowSelected={rowSelected}
        onRowSelect={onRowSelect}
        metricComponentType={metricComponentType}
        timeframeSelection={timeframeSelection}
      />
    );
  }

  const listHeader = Array.isArray(selectedData?.listHeader)
    ? [...selectedData.listHeader]
    : null;
  let listItems = Array.isArray(selectedData?.listItems)
    ? [...selectedData.listItems]
    : null;
  // kinda gross but time is crucial - remove accessory metadata fields
  // and only render the specific ones in data keys list
  if (listItems !== null) {
    const dataKeys = ['dataSource', 'historical', 'now', 'prediction'];
    listItems = listItems.map((item) => {
      const primaryData = item?.primaryData
        ? Object.fromEntries(
            Object.entries(item?.primaryData).filter(([key]) =>
              dataKeys.includes(key)
            )
          )
        : null;
      const secondaryData = item?.secondaryData
        ? Object.fromEntries(
            Object.entries(item?.secondaryData).filter(([key]) =>
              dataKeys.includes(key)
            )
          )
        : null;
      const tertiaryData = item?.tertiaryData
        ? Object.fromEntries(
            Object.entries(item?.tertiaryData).filter(([key]) =>
              dataKeys.includes(key)
            )
          )
        : null;
      return {
        ...item,
        primaryData,
        secondaryData,
        tertiaryData,
      };
    });
  }

  let showCappedPercentage;
  if (selectedData) {
    showCappedPercentage = selectedData.listItems[0].primaryData.dataSource
      .toLowerCase()
      .includes('iz rate');
  }

  return selectedData ? (
    <div className={classes.listContainer}>
      <div className={classes.headerRow}>
        {Array.isArray(listHeader)
          ? listHeader.map((item, i) => (
              <div
                key={`${i}header`}
                className={classes.headerCellVertical}
                style={{
                  paddingLeft: i !== 0 ? cellLeftPadding : 0,
                  borderTopLeftRadius:
                    item.key?.toLowerCase() === 'now' ? cornerRadius : 0,
                  borderTopRightRadius:
                    item.key?.toLowerCase() === 'now' ? cornerRadius : 0,
                  justifyContent: 'flex-end',
                }}
              >
                {item.primaryText ? (
                  <Typography
                    noWrap
                    className={classes.headerPrimaryText}
                    align={item.key === 'Show' ? 'center' : 'left'}
                  >
                    {item.primaryText.toUpperCase()}
                  </Typography>
                ) : null}
                {item.secondaryText && i !== 0 ? (
                  <Typography
                    noWrap
                    className={classes.headerSecondaryText}
                    align={item.key === 'Show' ? 'center' : 'left'}
                  >
                    {item.secondaryText.toUpperCase()}
                  </Typography>
                ) : null}
              </div>
            ))
          : null}
      </div>

      {Array.isArray(listItems)
        ? listItems.map((item, i) => {
            const isSelected = rowSelected === i;
            return (
              <ListItemButton
                className={`test-scripts-data-list ${classes.listContainer}`}
                key={i}
                selected={rowSelected === i}
                onClick={(event) => {
                  onRowSelect(event, i);
                  trackTimeframeTableRowSelection({
                    metricComponentType,
                    primaryLegendTitle:
                      item.primaryData.dataSource.toUpperCase(),
                    timeframeSelectionName: timeframeSelection,
                    entityName,
                    segmentName: maptualListSegmentName,
                    maptualListSelectionLabel: maptualListLabel,
                  });
                }}
                data-testid={`test-${
                  listHeader[0].primaryText.toLowerCase().split(' ')[0]
                }-data-list-item`}
                style={{
                  backgroundColor:
                    rowSelected === i ? theme.selectedTableRowColor : '',
                  borderRadius: cornerRadius,
                  paddingLeft: '5px',
                }}
              >
                <div className={classes.listInnerRow}>
                  {typeof item.primaryData === 'object'
                    ? Object.keys(item.primaryData).map((cell, j) => {
                        const localData = item.primaryData[cell];
                        return j === 0 ? (
                          <LegendContentCell key={j}>
                            <LegendMarker
                              backgroundColor={theme.graphLegendColors[i]}
                            />
                            <LegendTitle
                              color={theme.primaryTextColor}
                              isSelected={isSelected}
                              isFirstElement
                            >
                              {item.primaryData[cell] || null}
                            </LegendTitle>
                          </LegendContentCell>
                        ) : (
                          <div
                            className={classes.dataCell}
                            key={j}
                            style={{
                              borderBottomLeftRadius:
                                cell?.toLowerCase() === 'now' &&
                                (!item.secondaryData || rowSelected !== i) &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              borderBottomRightRadius:
                                cell?.toLowerCase() === 'now' &&
                                (!item.secondaryData || rowSelected !== i) &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              maxWidth:
                                item.primaryData[cell] &&
                                typeof item.primaryData[cell] === 'object'
                                  ? dataCellWidth
                                  : '',
                            }}
                          >
                            {typeof localData === 'object' ? (
                              <span className={classes.innerDataWrapper}>
                                {localData.value !== null &&
                                localData.value !== undefined ? (
                                  <Typography
                                    data-testid="test-script-volume-data"
                                    noWrap
                                    className={classes.mainValueStyle}
                                    variant="body1"
                                  >
                                    {item.primaryData[cell].isPercentage ||
                                    showCappedPercentage
                                      ? formatPercent(
                                          item.primaryData[cell].value,
                                          showCappedPercentage ? 75 : null
                                        )
                                      : abbreviateNumber(
                                          item.primaryData[cell].value,
                                          2
                                        )}
                                  </Typography>
                                ) : null}
                                {localData.secondaryValue ? (
                                  <TrendValue
                                    type={localData.secondaryValue.type}
                                    status={localData.secondaryValue.status}
                                    value={localData.secondaryValue.value}
                                    collapsed={rowSelected !== i}
                                  />
                                ) : null}
                              </span>
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </div>

                {rowSelected === i && item.secondaryData ? (
                  <div className={classes.listInnerRow}>
                    {typeof item.secondaryData === 'object'
                      ? Object.keys(item.secondaryData).map((cell, j) => {
                          const localData = item.secondaryData[cell];
                          return j === 0 ? (
                            <LegendContentCell key={j}>
                              <LegendMarker
                                backgroundColor={lighten(
                                  theme.graphLegendColors[i]
                                )}
                              />
                              <LegendTitle
                                color={lighten(theme.primaryTextColor)}
                                isSelected={isSelected}
                                isFirstElement={false}
                              >
                                {localData || null}
                              </LegendTitle>
                            </LegendContentCell>
                          ) : (
                            <div
                              className={classes.dataCell}
                              key={j}
                              style={{
                                borderBottomLeftRadius:
                                  cell?.toLowerCase() === 'now' &&
                                  !item.tertiaryData &&
                                  i === listItems.length - 1
                                    ? cornerRadius
                                    : 0,
                                borderBottomRightRadius:
                                  cell?.toLowerCase() === 'now' &&
                                  !item.tertiaryData &&
                                  i === listItems.length - 1
                                    ? cornerRadius
                                    : 0,
                                maxWidth:
                                  item.primaryData[cell] &&
                                  typeof item.primaryData[cell] === 'object'
                                    ? dataCellWidth
                                    : '',
                              }}
                            >
                              {typeof localData === 'object' ? (
                                <span className={classes.innerDataWrapper}>
                                  {localData.value !== null &&
                                  localData.value !== undefined ? (
                                    <Typography
                                      data-testid="test-national-avg"
                                      noWrap
                                      className={classes.subValueStyle}
                                      variant="body1"
                                    >
                                      {item.secondaryData[cell].isPercentage ||
                                      showCappedPercentage
                                        ? formatPercent(
                                            item.secondaryData[cell].value,
                                            showCappedPercentage ? 75 : null
                                          )
                                        : abbreviateNumber(
                                            item.secondaryData[cell].value,
                                            2
                                          )}
                                    </Typography>
                                  ) : null}
                                  {localData.secondaryValue ? (
                                    <TrendValue
                                      type={localData.secondaryValue.type}
                                      status={localData.secondaryValue.status}
                                      value={localData.secondaryValue.value}
                                      condensed={rowSelected !== i}
                                    />
                                  ) : null}
                                </span>
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </div>
                ) : null}
                {rowSelected === i && item.tertiaryData ? (
                  <div className={classes.listInnerRow}>
                    {typeof item.tertiaryData === 'object'
                      ? Object.keys(item.tertiaryData).map((cell, j) => {
                          const localData = item.tertiaryData[cell];
                          return j === 0 ? (
                            <LegendContentCell key={j}>
                              <LegendMarker
                                backgroundColor={lighten(
                                  theme.quartileRowColor
                                )}
                              />
                              <LegendTitle
                                color={lighten(theme.primaryTextColor)}
                                isSelected={isSelected}
                                isFirstElement={false}
                              >
                                {localData || null}
                              </LegendTitle>
                            </LegendContentCell>
                          ) : (
                            <div
                              className={classes.dataCell}
                              key={j}
                              style={{
                                backgroundColor:
                                  cell?.toLowerCase() === 'now'
                                    ? rowSelected === i
                                      ? theme.contentCardHighlightSelectedColor
                                      : theme.contentCardHighlightColor
                                    : '',
                                borderBottomLeftRadius:
                                  cell?.toLowerCase() === 'now' &&
                                  i === listItems.length - 1
                                    ? cornerRadius
                                    : 0,
                                borderBottomRightRadius:
                                  cell?.toLowerCase() === 'now' &&
                                  i === listItems.length - 1
                                    ? cornerRadius
                                    : 0,
                                maxWidth:
                                  item.primaryData[cell] &&
                                  typeof item.primaryData[cell] === 'object'
                                    ? dataCellWidth
                                    : '',
                              }}
                            >
                              {typeof localData === 'object' ? (
                                <span className={classes.innerDataWrapper}>
                                  {localData.value !== null &&
                                  localData.value !== undefined ? (
                                    <Typography
                                      data-testid="test-national-avg"
                                      noWrap
                                      className={classes.subValueStyle}
                                      variant="body1"
                                    >
                                      {item.tertiaryData[cell].isPercentage ||
                                      showCappedPercentage
                                        ? formatPercent(
                                            item.tertiaryData[cell].value,
                                            showCappedPercentage ? 75 : null
                                          )
                                        : abbreviateNumber(
                                            item.tertiaryData[cell].value,
                                            2
                                          )}
                                    </Typography>
                                  ) : null}
                                  {localData.secondaryValue ? (
                                    <TrendValue
                                      type={localData.secondaryValue.type}
                                      status={localData.secondaryValue.status}
                                      value={localData.secondaryValue.value}
                                      condensed={rowSelected !== i}
                                    />
                                  ) : null}
                                </span>
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </div>
                ) : null}
              </ListItemButton>
            );
          })
        : null}
    </div>
  ) : null;
}
