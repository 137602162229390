import TabbedChart from './tabbedChart';
import { useMetricScriptsMetadata } from '../../../../hooks/useMetricScriptsMetadata';
import useMetricScriptsData from '../../../../hooks/useMetricScriptsData';
import { filterMetricData } from './utils';

export default function MetricScriptsFieldViewProvider({
  metricComponentType,
  style,
  activeObjective,
}) {
  const { isLoading: isMetadataLoading, data: scriptMetadata } =
    useMetricScriptsMetadata();
  const { isLoading, data, error, setParams } =
    useMetricScriptsData(activeObjective);

  const handleChipSelect = ({ chip }) => {
    setParams({
      chipMetadata: chip.chipMetadata,
    });
  };

  const filteredScriptMetadata = filterMetricData(
    scriptMetadata,
    activeObjective
  );
  return (
    <TabbedChart
      data={data?.complete}
      metricComponentType={metricComponentType}
      style={style}
      onChipSelect={handleChipSelect}
      chartMetadata={filteredScriptMetadata}
      isLoading={isMetadataLoading || isLoading}
      disabled={data?.complete?.disabled}
      error={error}
    />
  );
}
