import { useEffect, useMemo, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  styled,
  Button,
  Typography,
  LinearProgress,
  InputBase,
  IconButton,
  Fade,
  Badge,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import FilterSelection from '../filterSelection';
import EntityList from '../entityList/entityList';
import { SearchNFilter } from '../../../modules/maptualListSearchFiltering';
import { trackSearch } from '../../../trackers/appEventTracker';
import { searchOptions } from '../constants';
import SegmentSelection from './segmentSelection';
import { useRelevantUserSpecialties } from '../../../hooks/useRelevantUserSpecialties';
import { setMaptualListFilterPreferences } from '../../../actions/projectViewActions';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';
import { formatEntityName } from '../../../utils/formatEntityName';
import { OverallObjectiveSelection } from '../../../containers/application/appViews/field/list/overallObjectiveSelection';
import { ROUTES, CUSTOM_LISTS_NAMES } from '../../../constants';
import { AppContext } from '../../../containers/application/appContext';
import { RegionSelection } from '../../../containers/application/appViews/field/navigation/regionSelection/RegionSelection';

import { useUpdateUserPreferencesMutation } from '../../../request/mutations';
import { MapToggle } from '../../../containers/application/appViews/field/map/mapToggle';
import useFeatureToggles from '../../../hooks/useFeatureToggles';
import { useShowOverallPowerscore } from '../../../hooks/useShowOverallPowerscore';
import { ObjectiveSelection } from '../../../containers/application/appViews/field/list/objectiveSelection';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';

const searchBeginThreshold = 1;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 375,
    minWidth: 375,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.themeColors.mainSurfaceBackground,
  },
  objectiveHeader: {
    fontSize: 12,
  },
  listControlsContainer: {
    display: 'flex',
    alignItems: 'stretch',
    width: 'auto',
    justifyContent: 'flex-start',
    gap: '8px',
    maxHeight: '40px',
    margin: '0px 16px 8px 16px',
  },
  segmentSelection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: 343,
    height: 42,
    margin: '0px 16px',
    backgroundColor: theme.themeColors.buttonBackgroundColor,
    border: `1px solid ${theme.themeColors.buttonBorderColor}`,
    borderRadius: 3,
  },
  listControlButton: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    maxWidth: 56,
    alignItems: 'center',
  },
  projectAndRegion: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '18px 16px 16px 16px',
    flexDirection: 'row',
    minHeight: 74,
  },
  search: {
    display: 'flex',
    flexGrow: 1,
    height: '40px',
  },
  listLoading: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 50,
  },
  listSelection: {
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 8,
  },
  navigationButtonConatiner: {
    display: 'flex',
    minHeight: '24px',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
}));

const SearchContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.themeColors.listIconColor}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  padding: '8px 12px',
  width: 'auto',
  height: '100%',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  paddingLeft: 5,
  transition: theme.transitions.create('width'),
  flexGrow: 2,
  color: theme.themeColors.secondaryTextColor,
  '& > input::placeholder': {
    opacity: 1,
  },
  '.MuiInputBase-input': {
    padding: '0px',
  },
}));

const StyledRoot = styled('div')(({ theme: { themeColors } }) => ({
  width: 375,
  minWidth: 375,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.mainBackground,
  borderRight: `1px solid ${themeColors.borderLowContrast}`,
}));

const StyledOptionsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  gap: 16,
  alignItems: 'flex-end',
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
}));

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
}));

const RegionButtonContainer = styled('div')(() => ({
  height: 34,
  marginTop: 6,
}));

const OpenPopoverButton = styled(Button)(() => ({
  width: '56px',
  height: '44px',
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '12px 0px 12px 21px',
  margin: 0,
  verticalAlign: 'top',
  minWidth: 'fit-content',

  '&.MuiButton-root': {
    height: '100%',
  },
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  display: 'block',
  marginRight: '16px',
  height: '100%',
  width: '56px',
  '& .MuiBadge-badge': {
    backgroundColor: themeColors.notificationBadgeSurface,
    color: themeColors.notificationBadgeContent,
  },
}));

const HCPTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '18px',
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const formatFiltersFromRoute = (filterTerritories, territoriesIdLabelMap) => {
  const findFilterLabelId = (formattedFilter) =>
    territoriesIdLabelMap?.find(
      (territory) => formatEntityName(territory?.label) === formattedFilter
    )?.id;

  if (!!filterTerritories && filterTerritories.length > 0) {
    const hasMoreThanOneFilterOption = /,/.test(filterTerritories);
    const filters = {
      territories: {},
    };
    if (hasMoreThanOneFilterOption) {
      const territoriesKeys = filterTerritories.split(',');

      territoriesKeys.forEach((key) => {
        if (key.length < 1) {
          return;
        }

        const formattedKey = formatEntityName(key);
        const filterId = findFilterLabelId(formattedKey, territoriesIdLabelMap);

        filters.territories[filterId] = {
          label: formattedKey,
          id: filterId,
        };
      });

      return filters;
    }

    const formattedFilter = formatEntityName(filterTerritories);
    const filterId = findFilterLabelId(formattedFilter, territoriesIdLabelMap);

    if (!filterId && territoriesIdLabelMap) {
      return {};
    }

    filters.territories[filterId] = {
      label: formattedFilter,
      id: filterId,
    };
    return filters;
  }

  return {};
};

export default function EntitySelection({
  selectEntity,
  selectMaptualListSegment,
  selectCustomList,
  selectSortingOption,
  viewer,
}) {
  const classes = useStyles();
  const { maptualListSegment, sortingOption, entityMetadata } = viewer;
  const { profile } = useContext(AppContext);
  const featureToggles = useFeatureToggles();
  const {
    project,
    maptualListMetadata,
    isMaptualListsLoading,
    maptualList: powerList,
    isMaptualListLoading: isPowerListLoading,
    maptualListObjectives,
    customListType,
  } = useContext(FieldContext);
  const isAuthorized = useIsAuthorized();

  const territoriesIdLabelMap = powerList?.metadata?.childEntities;
  const { projectId } = useParams();
  const maptualListFilters = useSelector(
    (state) =>
      state.projectViewer.maptualListFilterPreferences?.[projectId] ?? {}
  );

  const isOverallPowerscoreVisible = useShowOverallPowerscore(
    profile?.userGroup,
    project?.metadata?.marketId
  );

  const [displayTerm, setDisplayTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const defaultSegment = 'All';
  const [segment, setSegment] = useState(defaultSegment);
  const [selectedUserCreatedList, setSelectedUserCreatedList] = useState(null);
  const relevantUserSpecialties = useRelevantUserSpecialties();

  const URLlocation = useLocation();
  const routeContains = (route) => URLlocation.pathname.includes(route);

  const [maptualList, setMaptualList] = useState(null);
  const [isMaptualListBusy, setIsMaptualListBusy] = useState(null);
  const [segmentSelectionDisabled, setsegmentSelectionDisabled] =
    useState(false);

  if (
    routeContains(ROUTES.CURATION) &&
    (!segmentSelectionDisabled || segment !== defaultSegment)
  ) {
    setSegment(defaultSegment);
    setsegmentSelectionDisabled(true);
  }
  if (!routeContains(ROUTES.CURATION) && segmentSelectionDisabled) {
    setsegmentSelectionDisabled(false);
  }

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const dispatch = useDispatch();

  const location = new URLSearchParams(window.location.search);
  const displayHCPOnListLoad = (
    filteredMaptualList,
    currentEntityMetadataSelection,
    originalMaptualList
  ) => {
    const selectedHcpExistsInFilteredList = filteredMaptualList.items.find(
      (item) =>
        String(item?.entityId) ===
        String(currentEntityMetadataSelection?.entityId)
    );

    const selectedHcpExistsInMaptualList = originalMaptualList.list.items.find(
      (item) =>
        String(item?.entityId) ===
        String(currentEntityMetadataSelection?.entityId)
    );

    const isFilteredListRender =
      filteredMaptualList.items[0] &&
      filteredMaptualList.items.length < originalMaptualList.list.items.length;

    if (selectedHcpExistsInFilteredList) {
      selectEntityById(selectedHcpExistsInFilteredList.entityId);
    } else if (isFilteredListRender) {
      const { entityId: firstItemInFilteredListId } =
        filteredSearchList.items[0];
      selectEntityById(firstItemInFilteredListId);
    } else if (selectedHcpExistsInMaptualList) {
      selectEntityById(selectedHcpExistsInMaptualList.entityId);
    } else if (maptualList?.list?.items?.[0]) {
      const { entityId: firstItemInMaptualListId } = maptualList.list.items[0];
      selectEntityById(firstItemInMaptualListId);
    }
  };

  useEffect(() => {
    const filterTerritories = location.get('filterTerritories');

    if (!!filterTerritories && territoriesIdLabelMap) {
      const filtersFromRoute = formatFiltersFromRoute(
        filterTerritories,
        territoriesIdLabelMap
      );

      setPreferencesMaptualListFilters({
        ...maptualListFilters,
        ...filtersFromRoute,
      });
      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.pushState({ path: urlWithoutQuery }, '', urlWithoutQuery);
    }
  }, [territoriesIdLabelMap]);

  const updateUserPreferencesMutation = useUpdateUserPreferencesMutation();

  const setPreferencesMaptualListFilters = (updatedPreferences) => {
    if (project?.metadata?.projectId) {
      const newPreferences = {
        ...viewer?.maptualListFilterPreferences,
      };
      newPreferences[project.metadata.projectId] = {
        ...updatedPreferences,
      };
      dispatch(setMaptualListFilterPreferences(newPreferences));
      updateUserPreferencesMutation.mutate({
        maptualListFilterPreferences: newPreferences,
      });
    }
  };

  const formatFilterQueries = (filterStates) => {
    const formatFilters = { ...filterStates };
    const hasAllOrNoSubsegmentFilters =
      Object.keys(formatFilters?.segment || []).length !== 1;

    const hasAllOrNoSpecialtyFilters = Object.keys(
      formatFilters?.specialties || []
    ).length
      ? Object.keys(formatFilters.specialties).length ===
        relevantUserSpecialties.length
      : true;
    if (hasAllOrNoSpecialtyFilters) {
      formatFilters.specialties = {
        specialtiesToInclude: relevantUserSpecialties,
        specialtiesToExclude: project?.metadata?.specialties,
      };
    } else {
      formatFilters.specialties = {
        specialtiesToInclude: Object.keys(formatFilters.specialties).filter(
          (s) => project?.metadata?.specialties?.includes(s)
        ),
        specialtiesToExclude: [],
      };
    }

    if (hasAllOrNoSubsegmentFilters) {
      delete formatFilters.segment;
    } else {
      const segmentConfig =
        formatFilters.segment[Object.keys(formatFilters.segment)[0]];
      formatFilters.segment = {
        ...segmentConfig,
        segmentName: segment,
      };
    }
    return formatFilters;
  };

  const formatMixpanelFilters = (filters) => {
    const mixpanelFilters = { ...filters };

    if (mixpanelFilters.segment) {
      mixpanelFilters.segment = Object.keys(mixpanelFilters.segment).map(
        (segId) => mixpanelFilters.segment[segId].label
      );
    }

    if (mixpanelFilters.specialties) {
      mixpanelFilters.specialties = Object.keys(
        mixpanelFilters.specialties
      ).map((specId) => mixpanelFilters.specialties[specId].label);
    }

    return mixpanelFilters;
  };

  const filteredSearchList = useMemo(
    () =>
      SearchNFilter(
        maptualList?.list || [],
        searchTerm,
        segment !== defaultSegment ? segment : null,
        formatFilterQueries(maptualListFilters),
        customListType,
        sortingOption === searchOptions.bySegmentScore,
        selectedUserCreatedList
      ),
    [
      maptualList?.list,
      searchTerm,
      segment,
      maptualListFilters,
      sortingOption,
      selectedUserCreatedList,
      customListType,
    ]
  );

  // Function that triggers a Redux action (selectEntity)
  const selectEntityById = (selectedId) => {
    const currentlySelectedEntity = entityMetadata?.entityId;
    if (currentlySelectedEntity === selectedId) return;

    const entityItems = filteredSearchList?.items
      ? filteredSearchList.items
      : [];

    const hcp = entityItems.find(
      (entity) => String(entity.entityId) === String(selectedId)
    );

    if (hcp) selectEntity(hcp);
  };

  useEffect(() => {
    setIsMaptualListBusy(isPowerListLoading || isMaptualListsLoading);
  }, [isMaptualListsLoading, isPowerListLoading]);

  useEffect(() => {
    setMaptualList(powerList);
  }, [powerList]);

  useEffect(() => {
    if (Array.isArray(filteredSearchList?.items)) {
      displayHCPOnListLoad(filteredSearchList, entityMetadata, maptualList);
    }
  }, [filteredSearchList]);

  useEffect(() => {
    if (entityMetadata?.entityId?.length)
      selectEntityById(entityMetadata.entityId);
  }, [entityMetadata?.entityId]);

  return (
    <StyledRoot>
      <div className={classes.projectAndRegion}>
        <div className={classes.navigationButtonConatiner}>
          <HCPTitle variant="h3">
            {CUSTOM_LISTS_NAMES[customListType] || CUSTOM_LISTS_NAMES.hcps}
          </HCPTitle>
        </div>
        <StyledOptionsContainer>
          {maptualListMetadata?.listName && (
            <RegionButtonContainer>
              <RegionSelection regionName={maptualListMetadata?.listName} />
            </RegionButtonContainer>
          )}
          {featureToggles(profile.userGroup, 'mapView') &&
            isAuthorized(['view-map']) && <MapToggle />}
        </StyledOptionsContainer>
      </div>
      <Fade in timeout={600}>
        <div className={classes.listControlsContainer}>
          <div className={classes.search}>
            <SearchContainer className="intercom-field-entity-search-bar">
              <StyledSearchIcon />
              <StyledInputBase
                placeholder="Search..."
                fullWidth
                value={displayTerm}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(event) => {
                  if (
                    event.target.value &&
                    event.target.value.length > searchBeginThreshold
                  ) {
                    setSearchTerm(event.target.value);
                    trackSearch(event.target.value);
                  } else setSearchTerm('');
                  setDisplayTerm(event.target.value);
                }}
              />
              {displayTerm ? (
                <StyledIconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setDisplayTerm('');
                    setSearchTerm('');
                  }}
                >
                  <CancelIcon />
                </StyledIconButton>
              ) : null}
            </SearchContainer>
          </div>
          <div
            className={`${classes.listControlButton} intercom-field-entity-filters-button`}
          >
            <StyledBadge badgeContent={Object.keys(maptualListFilters).length}>
              <OpenPopoverButton
                data-testid="test-open-filter-button"
                aria-label="openFilterButton"
                variant="outlined"
                onClick={handleClickOpenDialog}
                startIcon={<TuneIcon />}
              />
            </StyledBadge>
            <FilterSelection
              maptualListFilters={maptualListFilters}
              setMaptualListFilters={setPreferencesMaptualListFilters}
              formatMixpanelFilters={formatMixpanelFilters}
              openDialog={openDialog}
              closeDialog={handleCloseDialog}
              segment={segment}
              selectedUserCreatedList={selectedUserCreatedList}
              territories={maptualList?.metadata?.childEntities}
            />
          </div>
        </div>
      </Fade>
      {!!maptualListObjectives?.length && maptualListObjectives?.length > 1 && (
        <Fade in timeout={400}>
          <div
            className={classes.listSelection}
            data-testid="objective-selection-dropdown"
          >
            {isOverallPowerscoreVisible ? (
              <OverallObjectiveSelection />
            ) : (
              <ObjectiveSelection />
            )}
          </div>
        </Fade>
      )}
      <Fade in timeout={400}>
        <div
          className={`${classes.segmentSelection} intercom-field-segment-selector`}
        >
          <SegmentSelection
            defaultSegment={defaultSegment}
            segment={segment}
            setSegment={setSegment}
            selectedUserCreatedList={selectedUserCreatedList}
            setSelectedUserCreatedList={setSelectedUserCreatedList}
            maptualListSegment={maptualListSegment}
            selectCustomList={selectCustomList}
            selectMaptualListSegment={selectMaptualListSegment}
            maptualListFilters={maptualListFilters}
            onMaptualListFiltersChange={setPreferencesMaptualListFilters}
            disabled={segmentSelectionDisabled}
          />
        </div>
      </Fade>
      {!isMaptualListBusy && (
        <EntityList
          entityList={filteredSearchList?.items ? filteredSearchList : null}
          listScope={maptualList?.metadata?.listName || ''}
          isMaptualListBusy={isMaptualListBusy}
          entityMetadata={entityMetadata}
          selectEntity={selectEntity}
          selectedId={entityMetadata?.entityId}
          selectedSegment={segment}
          onSelect={selectEntityById}
          formatMixpanelFilters={formatMixpanelFilters}
          selectSortingOption={selectSortingOption}
          maptualListFilters={maptualListFilters}
          setMaptualListFilters={setPreferencesMaptualListFilters}
          projectId={project?.metadata?.projectId}
          maptualListId={maptualList?.metadata?.maptualListId}
          subSegments={
            maptualList?.segments?.find((item) => item.name === segment)
              ?.subSegments
          }
          profileRole={profile?.role}
          searchTerm={searchTerm}
          segment={segment}
          selectedUserCreatedList={selectedUserCreatedList}
        />
      )}
      {isMaptualListBusy ? (
        <div className={classes.listLoading}>
          <Typography
            color="textSecondary"
            className={classes.objectiveHeader}
            style={{ marginBottom: 0, marginTop: 60 }}
          >
            {`Loading ${
              maptualListMetadata?.listName
                ? `${maptualListMetadata.listName} `
                : ''
            }List ...`}
          </Typography>
          <LinearProgress style={{ margin: 16 }} />
        </div>
      ) : null}
    </StyledRoot>
  );
}
