import { useContext, useEffect, useState } from 'react';

import { Box, Drawer, styled } from '@mui/material';
import { DataGridPremium, GridSortItem } from '@mui/x-data-grid-premium';
import { EntityError } from './EntityError';
import { EntityOverviewContext } from '../data/entityOverviewContext';
import {
  filterRowsBySearchValue,
  isEmptyItem,
  sortWithNullValuesAtEnd,
} from './tableHelpers';
import { EntityOverviewDrawer } from './drawer';
import { findRowDataInfo } from './drawer/drawerHelpers';
import {
  trackEntityOverviewColumnSorting,
  trackEntityOverviewEntitySelected,
} from '../../../trackers/mixpanel';
import { EntityTableHeader } from './EntityTableHeader';
import { EntityTableCell } from './EntityTableCell';
import {
  ENTITY_TYPES_ENUM,
  ERROR_TYPES_ENUM,
  HEADER_LABEL_DEFAULT_SIZE,
  SCORE_COLUMN_WIDTH,
  TABLE_HEADER_TYPES_ENUM,
  SEGMENT_COLUMN_WIDTH,
  BADGE_COLUMN_WIDTH,
} from '../constants';

const TABLE_PAGE_SIZE = 25;

const TableWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}));

const getCellValue = (type, rowData) => {
  switch (type) {
    case TABLE_HEADER_TYPES_ENUM.SCORE:
      return rowData?.maptualFractionalScore;
    case TABLE_HEADER_TYPES_ENUM.LABEL:
      return rowData?.name;
    case TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT:
      return rowData?.map((segment) => segment.displayName).join(', ');
    case TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT:
      return rowData?.join(', ');
    case TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE: {
      if (!rowData || !rowData.length) {
        return null;
      }

      const regex = /(?<number>\d+)/;
      const match = rowData[0]?.match(regex);

      if (match?.groups?.number) {
        return Number(match.groups.number);
      }

      return null;
    }
    default:
      return Number(rowData[rowData.length - 1]);
  }
};

export const buildRows = (headers, items) => {
  const rows = [];

  items?.forEach((item, index) => {
    if (!item.every(isEmptyItem)) {
      const headerToItemMap = {};

      for (let i = 0; i < headers?.length; i++) {
        headerToItemMap[i] = item[i];
      }

      headerToItemMap.id = index;
      rows.push(headerToItemMap);
    }
  });

  return rows;
};

export const buildColumns = (headers) => {
  const columns = [];

  headers?.forEach(
    (
      { label, subLabel, type, graph, chart, tooltipText, tooltipList },
      index
    ) => {
      const { titlePrefix: chartTitlePrefix, labels: chartLabels } =
        chart || {};

      let headerColumn = {
        field: `${index}`,
        maxHeight: '100%',
        renderHeader: () =>
          EntityTableHeader({
            label,
            subLabel,
            type,
            tooltipText,
            tooltipList,
          }),
        sortingOrder: ['desc', 'asc', null],
        valueGetter: ({ row }) => getCellValue(type, row[index]?.data),
        renderCell: ({ row }) =>
          EntityTableCell({
            row: row[index],
            type,
            graph,
            chartLabels,
            label,
            subLabel,
            chartTitlePrefix,
          }),
      };

      switch (type) {
        case TABLE_HEADER_TYPES_ENUM.SCORE:
          headerColumn = {
            ...headerColumn,
            minWidth: SCORE_COLUMN_WIDTH,
            sortComparator: (value1, value2) => value1 - value2,
          };
          break;

        case TABLE_HEADER_TYPES_ENUM.LABEL:
          headerColumn = {
            ...headerColumn,
            sortComparator: (value1, value2) => value1.localeCompare(value2),
          };
          break;
        case TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT:
        case TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT:
          headerColumn = {
            ...headerColumn,
            sortComparator: (value1, value2) => value1.localeCompare(value2),
            width: SEGMENT_COLUMN_WIDTH,
          };
          break;

        case TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE:
          headerColumn = {
            ...headerColumn,
            sortComparator: (v1, v2, cellParams1) => {
              const sortModel = cellParams1.api.getSortModel();
              const sortColumn = sortModel.find(
                (sm: GridSortItem) => sm.field === cellParams1.field
              );

              return sortWithNullValuesAtEnd(v1, v2, sortColumn?.sort);
            },
            width: BADGE_COLUMN_WIDTH,
          };
          break;

        default:
          headerColumn = {
            ...headerColumn,
            width: label.length > HEADER_LABEL_DEFAULT_SIZE ? 180 : 136,
          };
          break;
      }

      columns.push(headerColumn);
    }
  );

  return columns;
};

const StyledDatagrid = styled(DataGridPremium, {
  shouldForwardProp: (prop) => prop !== 'dynamicRowStyles',
})(({ theme: { themeColors, spacing }, dynamicRowStyles }) => ({
  ...dynamicRowStyles,
  border: '0',
  boxShadow: 'none',

  '.MuiDataGrid-root': {
    overflow: 'visible',
  },

  '.MuiDataGrid-iconSeparator': {
    display: 'none',
  },

  '.MuiDataGrid-columnHeaders': {
    color: themeColors.neutral80,
    borderColor: themeColors.borderLowContrast,
    height: 'fit-content',
    paddingBottom: spacing(1.5),
    maxHeight: '100%',
  },

  '.MuiDataGrid-iconButtonContainer': {
    width: 28,
  },

  '.MuiDataGrid-columnHeaderDraggableContainer': {
    alignItems: 'end',
  },

  '.MuiDataGrid-virtualScroller': {
    '&::-webkit-scrollbar': {
      width: 14,
      height: 14,
    },
    '&::-webkit-scrollbar-track': {
      background: themeColors.generalScrollbarTrackColor,
      border: 'none',
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      background: themeColors.generalScrollbarTrackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      background: themeColors.neutral30,
      borderRadius: 100,
      border: `3px solid ${themeColors.generalScrollbarTrackColor}`,
      '&:hover': {
        background: themeColors.neutral80,
      },
    },
  },

  '.MuiDataGrid-footerContainer': {
    border: 'none',
    justifyContent: 'left',
  },

  '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
    background: themeColors.mainBackground,
    boxShadow: 'none',
  },
}));

const StyledDrawer = styled(Drawer)(({ theme: { themeColors } }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: themeColors.modalBackgroundColor,
  },
})) as typeof Drawer;

const ErrorContainer = styled(Box)({
  marginTop: 16,
});

const DataGridColumnsInitialState = {
  pagination: {
    paginationModel: { pageSize: TABLE_PAGE_SIZE, page: 0 },
  },
};

export const EntityTable = () => {
  const { isDataFetching, data, entityType, searchValue, setErrorType } =
    useContext(EntityOverviewContext);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();

  const [nameColumnIndex, setNameColumnIndex] = useState();
  const [drawerOpenerColumnIndex, setDrawerOpenerColumnIndex] = useState();
  const [dynamicRowStyles, setDynamicRowStyles] = useState();
  const [dynamicRowStylesLoading, setDynamicRowStylesLoading] = useState(true);

  useEffect(() => {
    if (
      !isDataFetching &&
      data &&
      data.headers?.length > 0 &&
      data.items?.length > 0
    ) {
      setIsTableLoading(true);

      const { headers, items } = data;
      const tempColumns = buildColumns(headers);
      const tempRows = buildRows(headers, items);

      setColumns(tempColumns);

      if (searchValue) {
        const filteredRowsBySearchValue = filterRowsBySearchValue(
          data.headers,
          tempRows,
          searchValue
        );
        if (filteredRowsBySearchValue.length === 0) {
          setErrorType(ERROR_TYPES_ENUM.NO_SEARCH_DATA);
        }
        setRows(filteredRowsBySearchValue);
      } else {
        setErrorType(null);
        setRows(tempRows);
      }
    }
  }, [isDataFetching, data, searchValue]);

  useEffect(() => {
    if (rows?.length > 0 && columns?.length > 0 && entityType) {
      setIsTableLoading(false);

      Object.entries(rows[0]).forEach(([key, value]) => {
        if (value?.data && Object.keys(value.data).includes('name')) {
          setNameColumnIndex(key);
        }

        if (value?.data && value.data.drawerOpener) {
          setDrawerOpenerColumnIndex(key);
        }
      });
    }
  }, [rows, columns, entityType]);

  useEffect(() => {
    if (
      nameColumnIndex !== undefined &&
      drawerOpenerColumnIndex !== undefined
    ) {
      setDynamicRowStyles({
        '.MuiDataGrid-row': {
          '&:hover, &.Mui-hovered': {
            background: 'none',
          },

          [`> [data-colindex="${nameColumnIndex}"]:hover`]: {
            textDecoration: 'underline',
            cursor: 'pointer',
          },

          [`> [data-colindex="${drawerOpenerColumnIndex}"]:hover`]: {
            cursor: 'pointer',
          },
        },
      });

      setDynamicRowStylesLoading(false);
    }
  }, [nameColumnIndex, drawerOpenerColumnIndex]);

  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();

  const onCellClick = (params) => {
    if (
      params.field === nameColumnIndex ||
      params.field === drawerOpenerColumnIndex
    ) {
      const name = findRowDataInfo(Object.entries(params?.row), 'name');
      const address = findRowDataInfo(Object.entries(params?.row), 'address');
      const id = findRowDataInfo(Object.entries(params?.row), 'id');

      trackEntityOverviewEntitySelected(name, entityType);
      setDrawerData({ name, address, id, entityType });
    }
  };

  const onSortModelChange = (model) => {
    if (data?.headers[model[0]?.field]?.label) {
      trackEntityOverviewColumnSorting(
        data.headers[model[0].field].label,
        entityType
      );
    }
  };

  useEffect(() => {
    if (drawerData) {
      setShowDrawer(true);
    }
  }, [drawerData]);

  const noTableData = data?.items?.length === 0;
  const noRowData = rows?.length === 0;
  if (noTableData || noRowData) {
    return (
      <ErrorContainer>
        <EntityError />
      </ErrorContainer>
    );
  }

  const disableVirtualization = import.meta.env.VITE_TEST_ENV === 'true';

  return (
    !isDataFetching &&
    !isTableLoading &&
    !dynamicRowStylesLoading && (
      <>
        <TableWrapper data-testid="entity-overview-table">
          <StyledDatagrid
            columns={columns || []}
            rows={rows || []}
            disableSelectionOnClick
            disableColumnMenu
            rowHeight={72}
            columnHeaderHeight={65}
            disableMultipleSelection
            loading={isDataFetching || !rows || !columns}
            initialState={{
              ...DataGridColumnsInitialState,
              pinnedColumns: { right: [`${columns.length - 1}`] },
              sorting: entityType !== ENTITY_TYPES_ENUM.DIRECT_ACCOUNT && {
                sortModel: [{ field: '0', sort: 'desc' }],
              },
            }}
            onSortModelChange={(model) => onSortModelChange(model)}
            onCellClick={onCellClick}
            disableRowSelectionOnClick
            rowBuffer={columns?.length || 0}
            pagination
            disableColumnReorder
            disableColumnResize
            autosizeOnMount
            dynamicRowStyles={dynamicRowStyles}
            disableVirtualization={disableVirtualization}
          />
        </TableWrapper>
        <StyledDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          PaperProps={{
            elevation: 0,
          }}
          ModalProps={{
            style: {
              position: 'absolute',
            },
            slotProps: {
              backdrop: {
                style: {
                  position: 'fixed',
                },
              },
            },
          }}
        >
          <EntityOverviewDrawer
            drawerData={drawerData}
            setDrawerVisibility={setShowDrawer}
          />
        </StyledDrawer>
      </>
    )
  );
};
