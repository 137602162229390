import { useContext, useState, useEffect, useRef, ChangeEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  styled,
  Checkbox,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import { ProductPerformanceChartContext } from './data/productPerformanceChartContext';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';
import { ExtendedChartLineData } from './data/useProductPerformanceChartContext';
import { formatTimestampLabel, isPredictionCadence } from '../helpers';
import {
  SHORT_FORM_CADENCE,
  BASKET_TYPE,
  TREND_STATUS,
  EM_DASH,
  METRIC_DATA_TYPE,
} from '../constants';
import {
  ChartLineData,
  ChartValue,
  Trend,
} from './data/useProductPerformanceData';
import TrendValue from '../../entitycontents/trendValues';
import { useProductPerformanceMixpanelTracker } from './useProductPerformanceMixpanelTracker';
import { Cadence } from '../types';

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

const TableWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  overflow: 'auto',
  scrollbarColor: `${themeColors.generalScrollbarButtonColor} ${themeColors.generalScrollbarTrackColor}`,
}));

const StyledTableHead = styled(TableHead)(({ theme: { themeColors } }) => ({
  '.MuiTableCell-root': {
    color: themeColors.tableCellHeaderDefaultContentSecondary,
    borderBottom: `1px solid ${themeColors.tableHeaderBorder}`,
    height: 16,
    borderRadius: '4px 4px 0 0',
  },
}));

const StyledTableBody = styled(TableBody)(({ theme: { themeColors } }) => ({
  '.MuiTableCell-root': {
    color: themeColors.tableCellDefaultContent,
    borderBottom: `1px solid ${themeColors.tableBodyBorder}`,
    borderRadius: '0 0 4px 4px',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(
  ({ theme: { themeColors, spacing }, isFirstColumn, shouldHighlight }) => ({
    padding: `${spacing(1)} ${spacing(2)}`,
    textAlign: isFirstColumn ? 'left' : 'right',
    lineHeight: 'unset',
    whiteSpace: 'nowrap',
    backgroundColor: shouldHighlight
      ? themeColors.tableCellHighlightedSurface
      : themeColors.cardBackgroundColor,
    left: 0,
    zIndex: 1,
  })
);

const StyledHeaderCell = styled(StyledTableCell)(({ isFirstColumn }) => ({
  position: 'sticky',
  top: 0,
  zIndex: isFirstColumn ? 2 : 1,
}));

const StyledBodyCell = styled(StyledTableCell)(({ isFirstColumn }) => ({
  position: isFirstColumn ? 'sticky' : 'static',
}));

const CellContent = styled(Box)(({ theme: { spacing }, isFirstColumn }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1),
  justifyContent: isFirstColumn ? 'flex-start' : 'flex-end',
  height: 20,
}));

const StyledCheckbox = styled(Checkbox)(({ lineColor }) => ({
  padding: 0,
  '&.Mui-checked': {
    color: lineColor,
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}));

const buildHeaders = (
  data: ChartLineData[],
  cadence: SHORT_FORM_CADENCE
): string[] => [
  null,
  ...data[0].chartValues.map((chartValue) =>
    formatTimestampLabel(chartValue.timestamp, cadence)
  ),
];

interface Row {
  basketType: BASKET_TYPE;
  chartLineColor: string;
  values: (ChartValue | { data: string })[];
}

const buildRows = (data: ExtendedChartLineData[]): Row[] =>
  data.map((lineData) => ({
    basketType: lineData.basketType,
    chartLineColor: lineData.chartLineColor,
    values: [
      {
        data: lineData.label,
      },
      ...lineData.chartValues,
    ],
  }));

export const formatDataValue = (
  data: number,
  trend: Trend,
  index: number,
  selectedCadence: SHORT_FORM_CADENCE,
  selectedCardTab: METRIC_DATA_TYPE
): string | number => {
  const nthDecimalDigits = 10;
  const isProductColumn = index === 0;
  if (isProductColumn) {
    return data;
  }

  const isLowConfidencePrediction =
    isPredictionCadence(selectedCadence, selectedCardTab) &&
    trend?.status < TREND_STATUS.VALID_METRIC_DATA_STATUS;
  if (isLowConfidencePrediction) {
    return EM_DASH;
  }

  if (selectedCardTab === METRIC_DATA_TYPE.SHARE) {
    const formattedData = (
      Math.round(data * 100 * nthDecimalDigits) / nthDecimalDigits
    ).toFixed(1);
    return `${formattedData}%`;
  }

  return (Math.round(data * nthDecimalDigits) / nthDecimalDigits).toFixed(1);
};

export const ProductPerformanceTable = () => {
  const { isMetadataLoading, selectedCadence, selectedCardTab } = useContext(
    ProductPerformanceMetadataContext
  );

  const {
    isDataLoading,
    isDataError,
    isDataIdle,
    data,
    setData,
    availableLineColors,
    setAvailableLineColors,
  } = useContext(ProductPerformanceChartContext);

  const { trackGraphAddProduct } = useProductPerformanceMixpanelTracker();

  if (isMetadataLoading || isDataIdle || isDataLoading) {
    return <SkeletonBar animation="wave" height={212} />;
  }

  if (isDataError || !data || data.length === 0) {
    return null;
  }

  const tableRef = useRef(null);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (
      headers?.length > 0 &&
      rows?.length > 0 &&
      tableRef?.current?.scrollWidth
    ) {
      tableRef.current.scrollLeft = tableRef.current.scrollWidth;
    }
  }, [tableRef?.current, headers, rows]);

  useEffect(() => {
    const headers = buildHeaders(data, selectedCadence?.id);
    const rows = buildRows(data);

    setHeaders(headers);
    setRows(rows);
  }, [data]);

  const handleProductSelect = (
    rowNumber: number,
    event: ChangeEvent<HTMLInputElement>,
    productName: string,
    basketType: BASKET_TYPE,
    chartLineColor: string
  ) => {
    let color = '';
    const isChecked = event.target.checked;

    if (isChecked) {
      color = availableLineColors[basketType].shift();
      setAvailableLineColors(availableLineColors);
    } else {
      availableLineColors[basketType].unshift(chartLineColor);
      setAvailableLineColors(availableLineColors);
    }

    setData((prevData) =>
      prevData.map((line) =>
        line.label === productName
          ? {
              ...line,
              chartLineColor: color,
            }
          : line
      )
    );

    if (isChecked && rowNumber > 2) {
      trackGraphAddProduct(productName);
    }
  };

  const hasPrediction = isPredictionCadence(
    selectedCadence?.id,
    selectedCardTab
  );

  const areAllColorsTaken = (basketType) =>
    availableLineColors[basketType].length === 0;

  const shouldHighlightColumn = (index) => {
    const numOfColumns = headers.length;
    const isLastColumn = index === numOfColumns - 1;
    const isSecondToLastColumn = index === numOfColumns - 2;
    return (
      (hasPrediction && isSecondToLastColumn) ||
      (!hasPrediction && isLastColumn)
    );
  };

  const isRowDisabled = (row) =>
    areAllColorsTaken(row.basketType) && !row.chartLineColor;

  const shouldShowTrend = (index) => {
    const numOfColumns = headers.length;
    return (
      hasPrediction &&
      (index === numOfColumns - 1 || index === numOfColumns - 2)
    );
  };

  return (
    <TableWrapper
      ref={tableRef}
      data-testid="product-performance-table-wrapper"
    >
      <Table>
        <StyledTableHead>
          <TableRow>
            {headers.map((header, i) => (
              <StyledHeaderCell
                key={`${i}-${header}-header`}
                isFirstColumn={i === 0}
                shouldHighlight={shouldHighlightColumn(i)}
              >
                <Typography variant="labelXs">{header}</Typography>
              </StyledHeaderCell>
            ))}
          </TableRow>
        </StyledTableHead>

        <StyledTableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow key={row.name}>
              {row.values.map(({ data, trend }, i) => (
                <StyledBodyCell
                  key={`${i}-${data}-value`}
                  isFirstColumn={i === 0}
                  shouldHighlight={shouldHighlightColumn(i)}
                >
                  <CellContent isFirstColumn={i === 0}>
                    {i === 0 && (
                      <Tooltip
                        title={
                          isRowDisabled(row) && (
                            <Typography variant="body2" width="225px">
                              Maximum {row.basketType} products selected.
                              Deselect a {row.basketType} product to select
                              another.
                            </Typography>
                          )
                        }
                      >
                        <StyledCheckbox
                          checked={row.chartLineColor}
                          disabled={isRowDisabled(row)}
                          onChange={(e) =>
                            handleProductSelect(
                              rowIndex,
                              e,
                              data,
                              row.basketType,
                              row.chartLineColor
                            )
                          }
                          lineColor={row.chartLineColor}
                          data-testid={`checkbox-${data}`}
                        />
                      </Tooltip>
                    )}

                    {shouldShowTrend(i) && (
                      <TrendValue
                        type={trend?.type}
                        status={trend?.status}
                        value={
                          trend?.status > TREND_STATUS.CANNOT_PREDICT
                            ? trend?.value
                            : null
                        }
                        noMargin
                      />
                    )}

                    <Typography variant="labelSm">
                      {formatDataValue(
                        data,
                        trend,
                        i,
                        selectedCadence?.id as Cadence,
                        selectedCardTab
                      )}
                    </Typography>
                  </CellContent>
                </StyledBodyCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </Table>
    </TableWrapper>
  );
};
