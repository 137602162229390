import { useQuery } from 'react-query';
import { getEntityOpportunityChartMetadata } from '../../../../../request/projectRequests';
import { MetricData } from '../../types';
import { EntityType } from '../../../../../clients/maptualAPI';

export const useOpportunityMetadata = (
  objectiveId: string,
  productLineId: string,
  entityId: string
): {
  data: MetricData;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: ['opportunity-metadata', objectiveId, productLineId, entityId],
    queryFn: async () => {
      const response = await getEntityOpportunityChartMetadata(
        objectiveId,
        productLineId,
        entityId,
        EntityType.Hcp
      );

      return response.data;
    },
    enabled: !!objectiveId,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
