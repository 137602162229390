import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Popover,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import { SnackbarDisplay } from '../generic/snackbar';
import { trackPowerscoreFeedback } from '../../trackers/mixpanel';

const SELECTION_STATES = {
  YES: 'YES',
  NO: 'NO',
  NOT_SURE: 'NOT SURE',
  NONE: 'NONE',
};

const StyledButton = styled(Button)(({ theme: { themeColors }, isActive }) => ({
  '& .MuiSvgIcon-root': {
    color: themeColors.utilityActiveContentColor,
  },
  backgroundColor: isActive
    ? themeColors.utilityActiveSurfaceColor
    : 'transparent',
  gap: 4,
  padding: 8,
}));

const StyledPopover = styled(Popover)(({ theme: { themeColors } }) => ({
  marginTop: 3,
  '& .MuiPopover-paper': {
    width: 375,
    backgroundColor: themeColors.popoverBackground,
    borderColor: themeColors.borderPrimaryColor,
  },
}));

const FlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PowerScoreDescription = styled(FlexBox)({
  padding: '24px 24px 16px',
});

const PowerScoreFeedback = styled(FlexBox)({
  padding: '16px 24px 24px',
});

const TitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

const DescriptionText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
}));

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
});

const AcknowledgementText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.secondaryTextColor,
  })
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: 'flex',
  justifyContent: 'right',
  gap: 8,
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  fontSize: 14,
  height: 32,
  '&:first-of-type, :not(:first-child):not(:last-child), &:last-of-type': {
    borderRadius: 4,
  },
}));

const StyledSnackbarTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    textAlign: 'left',
    color: themeColors.alertPrimaryInfoTitleColor,
  })
);

const StyledSnackbarBody = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.alertPrimaryInfoDescriptionColor,
}));

export const PowerScorePopover = ({
  powerscore,
  objectiveScores,
  entity,
  projectName,
  locality,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [snackPack, setSnackPack] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [selectionState, setSelectionState] = useState(SELECTION_STATES.NONE);

  const handlePowerScoreClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setIsSnackBarOpen(true);
    } else if (snackPack.length && messageInfo && isSnackBarOpen) {
      setIsSnackBarOpen(false);
    }
  }, [snackPack, messageInfo, isSnackBarOpen]);

  const handleFeedbackButtonClick = (event, selection) => {
    const newSelection = selection || SELECTION_STATES.NONE;
    if (newSelection === SELECTION_STATES.NONE) {
      setIsSnackBarOpen(false);
    } else {
      setSnackPack((prev) => [...prev, { key: new Date().getTime() }]);
    }
    trackPowerscoreFeedback({
      userInput: newSelection,
      numberObjectives: objectiveScores.length > 1 ? 'Multiple' : 'Single',
      scoreType: objectiveScores.length > 1 ? 'Overall' : 'Objective',
      score: powerscore,
      projectName,
      hcpName: entity?.metadata?.entityName,
      region: locality,
    });
    setSelectionState(newSelection);
  };

  return (
    <>
      <StyledButton onClick={handlePowerScoreClick} isActive={isPopoverOpen}>
        <PowerScore score={powerscore} objectiveScoreList={objectiveScores} />
        <ExpandMoreRoundedIcon data-testid="powerscore-arrow-drop-down-button" />
      </StyledButton>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FlexBox>
          <PowerScoreDescription gap={1}>
            <TitleText variant="h5"> PowerScore Breakdown</TitleText>
            <DescriptionText variant="body2">
              PowerScore signifies an HCP’s propensity to help you achieve your
              business objective.
            </DescriptionText>
            <DescriptionText variant="body2">
              The PowerScore takes into account information like historical Rx
              as well as data points specific to MAPTUAL’s algorithms, like
              Segment memberships and predicted behaviors.
            </DescriptionText>
          </PowerScoreDescription>
          <StyledDivider />
          <PowerScoreFeedback gap={2}>
            <AcknowledgementText variant="title3">
              Do you understand why this HCP has this PowerScore?
            </AcknowledgementText>
            <StyledToggleButtonGroup
              value={selectionState}
              exclusive
              onChange={handleFeedbackButtonClick}
            >
              <StyledToggleButton
                value={SELECTION_STATES.YES}
                variant="contained"
              >
                Yes
              </StyledToggleButton>
              <StyledToggleButton
                value={SELECTION_STATES.NO}
                variant="contained"
              >
                No
              </StyledToggleButton>
              <StyledToggleButton
                value={SELECTION_STATES.NOT_SURE}
                variant="contained"
              >
                I'm Not Sure
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          </PowerScoreFeedback>
        </FlexBox>
      </StyledPopover>
      <SnackbarDisplay
        key={
          messageInfo
            ? `powerscore-feedback-${entity?.metadata?.entityId}-${messageInfo.key}`
            : undefined
        }
        isOpen={isSnackBarOpen}
        handleClose={() => setIsSnackBarOpen(false)}
        handleUndo={() =>
          handleFeedbackButtonClick(null, SELECTION_STATES.NONE)
        }
        handleExited={() => {
          setMessageInfo(undefined);
        }}
        message={
          <Box display="flex" flexDirection="column" gap={0.5}>
            <StyledSnackbarTitle variant="title3">
              Thank you for submitting your feedback.
            </StyledSnackbarTitle>
            <StyledSnackbarBody variant="subtitle1">
              This data will help us improve our PowerScore explainability.
            </StyledSnackbarBody>
          </Box>
        }
      />
    </>
  );
};
