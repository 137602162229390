import { useContext } from 'react';
import { FieldContext } from '../../fieldContext';
import { NavigationButton as NavigationIconButton } from '../../../shared/navigationButton';

export const NavigationButton = () => {
  const { navigationOpen, setNavigationOpen } = useContext(FieldContext);
  return (
    <NavigationIconButton
      handleClick={() => setNavigationOpen(!navigationOpen)}
    />
  );
};
