import { useMutation, useQueryClient } from 'react-query';
import {
  attachProductTree,
  cloneProductTree,
  createProductTree,
} from './configViewRequests';

import {
  deleteProjectTemplate,
  postNewProjectTemplate,
  updateProjectTemplate,
  postNewProject,
} from './newProjectRequests';

import { queryKeys } from './queries';
import { putUserPreference } from './requests';

export const useCreateProductTreeMutation = (options) =>
  useMutation((requestBody) => createProductTree(requestBody), options);

export const useCloneProductTreeMutation = (options) =>
  useMutation((requestBody) => cloneProductTree(requestBody), options);

export const useAttachProductTreeMutation = (options) =>
  useMutation(
    ({ productLineId, requestBody }) =>
      attachProductTree(productLineId, requestBody),
    options
  );

export const useCreateProjectTemplateMutation = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(postNewProjectTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projectTemplates);
    },
    ...options,
  });
};

export const useCreateProjectMutation = (options = {}) =>
  useMutation(postNewProject, options);

export const useUpdateProjectTemplateMutation = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateProjectTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projectTemplates);
    },
    ...options,
  });
};
export const useDeleteProjectTemplateMutation = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(deleteProjectTemplate, {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projectTemplates);
      options.onSuccess?.();
    },
  });
};
export const useUpdateUserPreferencesMutation = () => {
  const queryClient = useQueryClient();
  const userProfile = queryClient.getQueryData(queryKeys.userProfile);
  return useMutation({
    mutationFn: async (preference) => {
      if (userProfile?.userId) {
        const newPreference = {
          ...userProfile?.preference,
          ...preference,
        };
        putUserPreference(newPreference);
      }
    },
    onMutate: async (preference) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.userProfile });

      const previousUserProfile = queryClient.getQueryData(
        queryKeys.userProfile
      );

      queryClient.setQueryData(queryKeys.userProfile, (old) => ({
        ...old,
        preference: { ...old.preference, ...preference },
      }));

      return { previousUserProfile };
    },

    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        queryKeys.userProfile,
        context.previousUserProfile
      );
    },
  });
};
