import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { trackShareGraphDropDownSelection } from '../../../trackers/appEventTracker';
import { isSphereView } from '../../../trackers/context';
import useSphereContext from '../../headoffice/useSphereContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} 0`,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DropdownSelections({
  options,
  selected,
  onSelect,
  selectionName,
  entityName = '',
}) {
  const classes = useStyles();
  const sphereContext = useSphereContext();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="market-share-selection-label">{selectionName}</InputLabel>
      <Select
        label={selectionName}
        labelId="market-share-selection-label"
        data-testid={`test-${selectionName}-dropdown`
          .toLowerCase()
          .split(' ')
          .join('-')}
        id="market-share-selection"
        value={selected}
        onChange={(event) => {
          onSelect(event, event.target.value);
          try {
            const currentSelection = options[event.target.value].title;
            const sphereOptions = isSphereView()
              ? {
                  dropdownSelection: currentSelection,
                  segmentName: sphereContext.segmentName,
                  maptualListSelectionLabel:
                    sphereContext.maptualListSelectionLabel,
                }
              : {};
            trackShareGraphDropDownSelection({
              entityName,
              selection: currentSelection,
              sphereOptions,
            });
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }}
      >
        {Array.isArray(options)
          ? options.map((item, i) => (
              <MenuItem
                className="test-market-share-selection-list"
                key={item.title}
                value={i}
              >
                {item.title || ''}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
}
