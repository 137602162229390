import { useQuery } from 'react-query';
import { getEntity } from '../../request/projectRequests';

export const useEntity = ({ entityId, maptualListId, projectId }) => {
  const {
    data: entity,
    isLoading: isEntityBusy,
    refetch: refetchEntity,
  } = useQuery([projectId, maptualListId, 'entity', entityId], async () => {
    try {
      const response = await getEntityFromApi({
        entityId,
        maptualListId,
        projectId,
      });
      return response.data;
    } catch {
      return null;
    }
  });

  const getEntityFromApi = ({ entityId, maptualListId, projectId }) => {
    if (!entityId || !maptualListId || !projectId) {
      return Promise.reject();
    }
    return getEntity({
      entityId,
      maptualListId,
      projectId,
    });
  };

  return {
    entity,
    isEntityBusy,
    refetchEntity,
  };
};
