import React, { useState } from 'react';
import { Box, Button, Popover, styled, Typography } from '@mui/material';
import { MoreHorizOutlined, Apartment, WorkOutline } from '@mui/icons-material';
import { StethoscopeIcon } from '../../application/appViews/field/navigation/viewsList/StethoscopeIcon';
import { ROUTES } from '../../../constants';

const EllipsisIcon = styled(MoreHorizOutlined)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral70,
    width: 16,
    height: 16,
    transformOrigin: 'center',
  })
);

const StyledButton = styled(Button)(({ theme: { themeColors }, isActive }) => ({
  padding: 0,
  minWidth: 40,
  minHeight: 40,

  '& .MuiSvgIcon-root': {
    color: themeColors.utilityActiveContentColor,
  },

  backgroundColor: isActive
    ? themeColors.utilityActiveSurfaceColor
    : 'transparent',
}));

const StyledPopover = styled(Popover)(({ theme: { themeColors } }) => ({
  marginTop: 3,
  '& .MuiPopover-paper': {
    width: 320,
    backgroundColor: themeColors.popoverBackground,
    borderColor: themeColors.borderPrimaryColor,
  },
}));

const FlexBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing(2, 0),
}));

const StyledRouteRow = styled(Box)(({ theme: { spacing, themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: spacing(1.5, 3.5),

  '> :first-child': {
    marginRight: spacing(1.5),
  },

  '&:hover': {
    backgroundColor: themeColors.tablePopoverHoverSurface,
    cursor: 'pointer',
  },
}));

const StyledApartmentIcon = styled(Apartment)(({ theme: { themeColors } }) => ({
  flexShrink: 0,
  height: 20,
  width: 20,

  '& path': {
    fill: themeColors.listIconColor,
  },
}));

const StyledWorkOutlineIcon = styled(WorkOutline)(
  ({ theme: { themeColors } }) => ({
    flexShrink: 0,
    height: 20,
    width: 20,

    '& path': {
      fill: themeColors.listIconColor,
    },
  })
);

const Text = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  wordWrap: 'break-word',
}));

export const ExternalLinkPopover = ({
  row,
  regionName,
  toHCPClick,
  toAccountViewClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  if (!row) {
    return null;
  }

  const handleExternalLinkClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        onClick={handleExternalLinkClick}
        isActive={isPopoverOpen}
        data-testid={`${regionName}-open-external-link-popover-btn`}
      >
        <EllipsisIcon data-testid="ellipsis-icon" />
      </StyledButton>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FlexBox>
          <StyledRouteRow
            onClick={toHCPClick}
            data-testid={`${regionName}-hcp-external-link-popover-btn`}
          >
            <StethoscopeIcon />
            <Text variant="body1">{`View HCPs in ${regionName}`}</Text>
          </StyledRouteRow>

          <StyledRouteRow
            onClick={() => toAccountViewClick(ROUTES.OUTLET)}
            data-testid={`${regionName}-outlet-external-link-popover-btn`}
          >
            <StyledApartmentIcon />
            <Text variant="body1">{`View Retail Outlets in ${regionName}`}</Text>
          </StyledRouteRow>

          <StyledRouteRow
            onClick={() => toAccountViewClick(ROUTES.DIRECT_ACCOUNT)}
            data-testid={`${regionName}-direct-account-external-link-popover-btn`}
          >
            <StyledWorkOutlineIcon />
            <Text variant="body1">{`View Direct Accounts in ${regionName}`}</Text>
          </StyledRouteRow>
        </FlexBox>
      </StyledPopover>
    </>
  );
};
