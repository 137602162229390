import { useContext } from 'react';
import { styled, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { ModalDisplayCustomStyle } from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';
import {
  transformNewToExisting,
  transformToTemplatePayload,
} from './newProjectTransformation';
import { useDeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { ProjectModalMode } from './projectModalMode';
import { PROJECT_CREATION_STEPS } from './common/constants';
import {
  useCreateProjectTemplateMutation,
  useUpdateProjectTemplateMutation,
  useCreateProjectMutation,
} from '../../request/mutations';

const StyledModalBody = styled('div')(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  padding: '5px 20px 45px',
}));

const StyledProjectDetails = styled('span')(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

export default function NewProjectConfirmationModal({ isOpen, onClose }) {
  const { projectModalMode, activeProject, activeTemplate } =
    useDeployDialogContext();
  const { projectConfig, objectives, setProjectCreationStep } =
    useContext(NewProjectContext);

  const createProjectTemplateMutation = useCreateProjectTemplateMutation();
  const updateProjectTemplateMutation = useUpdateProjectTemplateMutation();
  const createProjectMutation = useCreateProjectMutation();
  const handleConfirmClick = async () => {
    if (projectModalMode === ProjectModalMode.templateCreate) {
      await createProjectTemplateMutation.mutateAsync(
        transformToTemplatePayload({ projectConfig, objectives })
      );
      setProjectCreationStep(PROJECT_CREATION_STEPS.SUCCESS);
    } else if (projectModalMode === ProjectModalMode.templateEdit) {
      await updateProjectTemplateMutation.mutateAsync({
        id: activeProject.id,
        requestBody: transformToTemplatePayload({
          projectConfig,
          objectives,
        }),
      });
      setProjectCreationStep(PROJECT_CREATION_STEPS.SUCCESS);
    } else {
      let nextStep = PROJECT_CREATION_STEPS.SUCCESS;
      const transformedBody = transformNewToExisting({
        projectConfig,
        objectives,
        templateId: activeTemplate.id,
      });
      try {
        await createProjectMutation.mutateAsync(transformedBody);
      } catch (err) {
        nextStep = PROJECT_CREATION_STEPS.ERROR;
      }
      setProjectCreationStep(nextStep);
    }
  };

  const isLoading =
    createProjectTemplateMutation.isLoading ||
    updateProjectTemplateMutation.isLoading ||
    createProjectMutation.isLoading;

  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader
        onClose={onClose}
        shouldShowBottomBorder={false}
        shouldShowXIcon={false}
      >
        Confirmation
      </ModalHeader>
      <StyledModalBody>
        {!projectModalMode ? (
          <>
            Are you sure you want to complete the project creation for{' '}
            <StyledProjectDetails>
              {projectConfig.projectName}
            </StyledProjectDetails>{' '}
            from the{' '}
            <StyledProjectDetails>
              {projectConfig.productLineName}
            </StyledProjectDetails>{' '}
            project template?
          </>
        ) : null}
        {projectModalMode === ProjectModalMode.templateCreate && (
          <>
            Are you sure you want to create{' '}
            <StyledProjectDetails>
              {projectConfig.projectName}
            </StyledProjectDetails>{' '}
            template from the{' '}
            <StyledProjectDetails>
              {projectConfig.productLineName} customer market
            </StyledProjectDetails>
          </>
        )}
        {projectModalMode === ProjectModalMode.templateEdit && (
          <>
            Are you sure you want to save changes to{' '}
            <StyledProjectDetails>
              {activeProject.projectName}
            </StyledProjectDetails>{' '}
            template?
          </>
        )}
        {projectModalMode === ProjectModalMode.runProjectFromTemplate && (
          <>
            Are you sure you want to run{' '}
            <StyledProjectDetails>
              {projectConfig.projectName}
            </StyledProjectDetails>{' '}
            from the{' '}
            <StyledProjectDetails>
              {activeProject.templateName}{' '}
            </StyledProjectDetails>
            template?
          </>
        )}
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Button
          onClick={handleConfirmClick}
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size="16px" /> : undefined}
        >
          Confirm and Complete
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
