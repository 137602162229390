import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { EnqueueSnackbar } from "@odaia/ui/src/components/snackbar";

export type MapViewContextType = {
  project: Record<string, unknown> | null;
  maptualListMetadata: Record<string, unknown> | null;
  maptualList: Record<string, unknown> | null;
  isMaptualListLoading: boolean;
  isMaptualListsLoading: boolean;
  maptualListObjectives: Record<string, unknown>[];
  entityCoordinatesData: unknown;
  isLoadingEntityCoords: boolean;
  isErrorEntityCoords: boolean;
  objective: Record<string, unknown> | null;
  setObjective: Dispatch<SetStateAction<Record<string, unknown> | null>>;
  objectiveIndex: number | null;
  setObjectiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  entityPrecallInsights: Record<string, unknown>[] | undefined;
  isEntityPrecallInsightsLoading: boolean;
  entityRuleInsights: Record<string, unknown> | undefined;
  isEntityRuleInsightsLoading: boolean;
  objectivesMetadata: Record<string, unknown>[] | undefined;
  isObjectivesMetadataLoading: boolean;
  enqueueSnackbar: EnqueueSnackbar;
  canUserFilterMapView: boolean;
  relevantUserSpecialties: string[] | undefined;
};

export const MapViewContext = createContext<MapViewContextType | undefined>(
  undefined
);

export const useMapViewContext = () => {
  const context = useContext(MapViewContext);
  if (context === undefined) {
    throw new Error(
      "useMapViewContext must be used within a MapViewContextProvider"
    );
  }
  return context;
};
