import { useQuery } from 'react-query';
import { getEntityPrecallInsights } from '../../../../../../request/projectRequests';

export const useEntityPrecallInsights = ({
  maptualListMetadata,
  project,
  objectiveId,
  entityId = null,
}) =>
  useQuery({
    queryKey: [
      'map-entity-insights',
      maptualListMetadata.maptualListId,
      objectiveId,
      entityId,
    ],
    queryFn: async () => {
      try {
        const response = await getEntityPrecallInsights({
          entityId: entityId || maptualListMetadata.maptualListId,
          projectId: project.metadata.projectId,
          objectiveId,
          regionId: maptualListMetadata.maptualListId,
          entityType: entityId ? 'hcp' : 'regionalTeam',
        });
        return response.data;
      } catch {
        return [];
      }
    },
    enabled:
      !!maptualListMetadata.maptualListId &&
      !!objectiveId &&
      !!project.metadata.projectId,
  });
