import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTheme } from '@mui/material';
import { useMarketPerformanceChart } from './useMarketPerformanceChart';
import { BASKET_TYPE } from '../../constants';
import { MarketPerformanceMetadataContextType } from './useMarketPerformanceMetadataContext';
import { ChartDataset, ExtendedChartDataset } from '../../types';

export interface MarketPerformanceChartContextType {
  isDataLoading: boolean;
  isDataError: boolean;
  isDataIdle: boolean;
  refetchData: () => void;
  title: string;
  data: ExtendedChartDataset[];
  setData: Dispatch<SetStateAction<ExtendedChartDataset[]>>;
}

export const useMarketPerformanceChartContext = (
  marketPerformanceMetadataContext: MarketPerformanceMetadataContextType
): MarketPerformanceChartContextType => {
  const {
    projectId,
    regionId,
    objectiveId,
    entityId,
    selectedMetric,
    selectedCadence,
    selectedCardTab,
  } = marketPerformanceMetadataContext;

  const {
    isLoading: isDataLoading,
    data: dataFromApi,
    isError: isDataError,
    isIdle: isDataIdle,
    refetch: refetchData,
  } = useMarketPerformanceChart({
    projectId,
    regionId,
    objectiveId,
    entityId,
    metricDataType: selectedCardTab,
    metric: selectedMetric,
    cadence: selectedCadence?.id,
  });

  const { themeColors } = useTheme();

  const BASKET_COLORS = {
    [BASKET_TYPE.TARGET]: themeColors.dataGeneralColor3,
    [BASKET_TYPE.COMPETITOR]: themeColors.dataCompetition3,
  };

  const [data, setData] = useState<ExtendedChartDataset[]>([]);

  const getChartLineColor = (basketType: BASKET_TYPE): string =>
    BASKET_COLORS[basketType] ?? '';

  const addLineColors = (data: ChartDataset[]): ExtendedChartDataset[] =>
    data.map((lineData) => {
      const { basketType } = lineData;

      return { ...lineData, chartLineColor: getChartLineColor(basketType) };
    });

  const title = dataFromApi?.title ?? '';
  const rawData = dataFromApi?.data;

  useEffect(() => {
    if (!isDataLoading && rawData) {
      const dataWithLineColors = addLineColors(rawData);
      setData(dataWithLineColors);
    }
  }, [rawData, isDataLoading]);

  return {
    isDataLoading,
    isDataError,
    isDataIdle,
    refetchData,
    title,
    data,
    setData,
  };
};
