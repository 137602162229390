import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';

import { userCreatedListOptions } from '@odaia/shared-components/src/userCreatedListsConfig';
import { updateUserCreatedListPreferences } from '../reducers/projectViewReducer';
import {
  selectCustomListName,
  selectEntityName,
  selectMaptualListSegmentName,
} from '../selectors/project';
import { trackAddToCustomListSelection } from '../trackers/mixpanel';

import { AppContext } from '../containers/application/appContext';
import { FieldContext } from '../containers/application/appViews/field/fieldContext';
import { EntityTitleBarContext } from '../components/fieldView/entityTitleBarContext';

function toggleHcpIdInList(hcpList, hcpId) {
  const newHcpList = [...hcpList];

  if (hcpList.includes(hcpId)) {
    return newHcpList.filter((id) => id !== hcpId);
  }

  newHcpList.push(hcpId);

  return newHcpList;
}

export function getUpdatedUserCreatedLists(
  hcpId,
  hcpCustomLists,
  projectProductLineId,
  optionId
) {
  const hcpIdList = hcpCustomLists?.[projectProductLineId]?.[optionId];

  let newHcpIdList = [];

  if (hcpIdList) {
    newHcpIdList = toggleHcpIdInList(hcpIdList, hcpId);
  } else {
    newHcpIdList = [hcpId];
  }

  return {
    ...hcpCustomLists,
    [projectProductLineId]: {
      ...hcpCustomLists?.[projectProductLineId],
      [optionId]: newHcpIdList,
    },
  };
}

export const useToggleHcpInList = (hcpId, optionId, source = 'Checklist') => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { profile } = useContext(AppContext);
  const { project, maptualList, updateMaptualListUserCreatedProperties } =
    useContext(FieldContext);
  const { userLists } = useContext(EntityTitleBarContext);

  const hcpCustomLists = userLists || {};
  const projectProductLineId = project?.metadata?.productLineId;
  const entityName = useSelector(selectEntityName);
  const maptualListName = maptualList?.metadata?.listName;
  const selectedUserCreatedList = useSelector(selectCustomListName);
  const segment = useSelector(selectMaptualListSegmentName);

  const userId = profile?.userId;

  return async () => {
    const newUserCreatedLists = getUpdatedUserCreatedLists(
      hcpId,
      hcpCustomLists,
      projectProductLineId,
      optionId
    );

    const isEngaged = newUserCreatedLists[projectProductLineId][optionId]
      ? newUserCreatedLists[projectProductLineId][optionId].includes(hcpId)
      : false;

    try {
      await dispatch(
        updateUserCreatedListPreferences({ userId, newUserCreatedLists })
      );

      updateMaptualListUserCreatedProperties(newUserCreatedLists);
      const titledCustomList =
        userCreatedListOptions[selectedUserCreatedList]?.label;
      const titledListName = userCreatedListOptions[optionId].label;

      trackAddToCustomListSelection(
        maptualListName,
        entityName,
        titledCustomList || segment,
        titledListName,
        isEngaged ? 'Engage' : 'Disengage',
        source
      );

      await queryClient.invalidateQueries(['user-created-lists']);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[ERROR] updateUserCreatedListPreferences action failed', {
        error,
        userId,
        hcpId,
        projectProductLineId,
      });
    }
  };
};
