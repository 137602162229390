export const ProjectModalMode = {
  none: '',
  templateCreate: 'templateCreate',
  templateEdit: 'templateEdit',
  runProjectFromTemplate: 'runProjectFromTemplate',
  viewProject: 'view',
} as const;

export type ProjectModalMode =
  (typeof ProjectModalMode)[keyof typeof ProjectModalMode];
