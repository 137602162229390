import { Typography, Skeleton, styled } from '@mui/material';

const Container = styled('div')(({ big }) => ({
  display: 'flex',
  width: big ? 40 : 70,
  minWidth: big ? 40 : 70,
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const StyledTypography = styled(Typography)(({ big }) => ({
  fontSize: big ? 18 : 16,
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

export const NumberContainer = ({ value, isLoading, big = false }) => (
  <Container big={big ? 1 : 0}>
    {isLoading && <StyledSkeleton height={35} width={70} />}
    {!isLoading && (
      <StyledTypography big={big ? 1 : 0}>{value}</StyledTypography>
    )}
  </Container>
);
