import { useQuery } from 'react-query';
import { getShare } from '../../request/projectRequests';

export const useShare = ({ entityId, maptualListId, projectId }) => {
  const {
    data: share,
    isLoading: isShareBusy,
    refetch: refetchShare,
  } = useQuery([projectId, maptualListId, entityId, 'share'], async () => {
    try {
      const response = await getShareFromApi({
        entityId,
        maptualListId,
        projectId,
      });
      return response?.data?.complete;
    } catch {
      return null;
    }
  });

  const getShareFromApi = ({ entityId, maptualListId, projectId }) => {
    if (!entityId || !maptualListId || !projectId) {
      return Promise.reject();
    }
    return getShare({ entityId, projectId, maptualListId });
  };

  return {
    share,
    isShareBusy,
    refetchShare,
  };
};
