import { useState, useEffect } from 'react';
import { styled, Box, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from '@mui/lab';
import { SegmentViewContainer } from './segmentViewContainer';

const StyledSegmentsContainer = styled(Box)({
  display: 'flex',
  height: '100%',
});

const StyledLoaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const StyledTreeView = styled(TreeView)(({ theme: { themeColors } }) => ({
  width: 260,
  overflowY: 'scroll',
  borderRight: `1px solid ${themeColors.borderLowContrast}`,
  '& .top-level-tree-item': {
    borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  },
}));

const StyledTreeItem = styled(TreeItem)(({ theme: { themeColors } }) => ({
  '& .MuiTreeItem-group': {
    marginLeft: 16,
  },
  '& .MuiTreeItem-content': {
    textAlign: 'left',
    padding: '16px',
  },
  '& .MuiTreeItem-content:hover, & .MuiTreeItem-content.Mui-focused:hover': {
    backgroundColor: themeColors.tableHoverColor,
  },
  '& .MuiTreeItem-content.Mui-selected, & .MuiTreeItem-content.Mui-selected:hover, & .MuiTreeItem-content.Mui-selected.Mui-focused':
    {
      backgroundColor: themeColors.entitySelectionHoverBackgroundColor,
    },
  '& .MuiTreeItem-content.Mui-focused': {
    backgroundColor: 'transparent',
  },
  '& .MuiTreeItem-content .MuiTreeItem-iconContainer': {
    width: '20px',
    marginRight: 0,
  },
  '& .MuiTreeItem-content .MuiTreeItem-iconContainer svg': {
    fontSize: 20,
  },
  '& .MuiTreeItem-content .MuiTreeItem-label': {
    fontSize: 15,
    paddingLeft: 0,
    wordBreak: 'break-word',
    marginLeft: 8,
    color: themeColors.primaryTextColor,
  },
}));

const StyledNotSelectedContainer = styled(Box)(
  ({ theme: { themeColors } }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: themeColors.neutral60,
  })
);

export const SegmentsView = ({ segments, rawSegments, setSegments }) => {
  const [segmentsTree, setSegmentsTree] = useState([]);
  const [segmentOrders, setSegmentOrders] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [editView, setEditView] = useState(false);

  const getSegmentOrders = (segmentsList) => {
    const flatList = getFlatListOfSegments(segmentsList);
    return flatList.reduce((acc, segment) => {
      if (segment.maptualList) {
        acc[segment.maptualList.label] = segment.maptualList.order;
      }
      return acc;
    }, {});
  };
  const getFlatListOfSegments = (segmentTree, result = []) => {
    segmentTree.forEach((segment) => {
      result.push(segment);
      if (segment.nodes.length) {
        getFlatListOfSegments(segment.nodes, result);
      }
    });
    return result;
  };

  useEffect(() => {
    const segmentsWithTopLevelKeys = segments.map((segment) => ({
      ...segment,
      topLevelTreeItem: true,
    }));
    const tree = renderTree(segmentsWithTopLevelKeys);
    setSegmentsTree(tree);
    setSegmentOrders(getSegmentOrders(segments));
    if (selectedSegment) {
      setSelectedSegment(findSegmentById(segments, selectedSegment.segmentId));
    }
  }, [segments]);

  const renderTree = (nodes) =>
    nodes.map((node) => (
      <StyledTreeItem
        key={node.primaryText}
        nodeId={node.primaryText}
        label={node.primaryText}
        className={node.topLevelTreeItem && 'top-level-tree-item'}
      >
        {node.nodes && renderTree(node.nodes)}
      </StyledTreeItem>
    ));

  const handleToggle = (event, nodeId) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeId);
    }
  };

  const handleSelect = (event, nodeId) => {
    if (!event.target.closest('.MuiTreeItem-iconContainer')) {
      const segment = findSegment(segments, nodeId);
      setEditView(false);
      setSelectedSegment(segment);
    }
  };

  const findSegment = (nodes, primaryText) => {
    let foundSegment = null;

    nodes.forEach((node) => {
      if (node.primaryText === primaryText) {
        foundSegment = node;
      } else {
        const foundInChildren = findSegment(node.nodes, primaryText);
        if (foundInChildren) {
          foundSegment = foundInChildren;
        }
      }
    });

    return foundSegment;
  };

  const findSegmentById = (nodes, segmentId) => {
    let foundSegment = null;

    nodes.forEach((node) => {
      if (node.segmentId === segmentId) {
        foundSegment = node;
      } else {
        const foundInChildren = findSegmentById(node.nodes, segmentId);
        if (foundInChildren) {
          foundSegment = foundInChildren;
        }
      }
    });

    return foundSegment;
  };

  if (!segmentsTree.length)
    return (
      <StyledLoaderContainer>
        <CircularProgress />
      </StyledLoaderContainer>
    );

  return (
    <StyledSegmentsContainer>
      <StyledTreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selectedSegment?.primaryText}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {segmentsTree}
      </StyledTreeView>
      {selectedSegment ? (
        <SegmentViewContainer
          selectedSegment={selectedSegment}
          isParentSegment={Boolean(selectedSegment.nodes.length > 0)}
          editView={editView}
          setEditView={setEditView}
          rawSegments={rawSegments}
          setSegments={setSegments}
          segmentOrders={segmentOrders}
        />
      ) : (
        <StyledNotSelectedContainer>
          Select a segment to view information
        </StyledNotSelectedContainer>
      )}
    </StyledSegmentsContainer>
  );
};
