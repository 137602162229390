import axios from 'axios';
import { maptualApiInstance } from './config';
import { Distribution } from '../containers/territoryOverview/constants';

export const getTerritoryOverviewData = ({
  projectId,
  regionId,
  distribution,
  categoryId,
  metric,
}) => {
  let params = {};

  if (distribution === Distribution.DEMAND) {
    params = { objective_id: categoryId };
  } else {
    params = { product_name: categoryId };
  }
  return maptualApiInstance
    .get(`field/territory-overview/${distribution}`, {
      params: {
        project_id: projectId,
        region_id: regionId,
        metric_name: metric,
        ...params,
      },
      timeout: 60000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 60000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
};

export const getTerritoryOverviewMetadata = ({ projectId, regionId }) =>
  maptualApiInstance
    .get(`field/territory-overview/metadata`, {
      params: {
        project_id: projectId,
        region_id: regionId,
      },
      timeout: 60000,
    })
    .then((res) =>
      res.status === 200 ? { ...res, data: JSON.parse(res.data) } : res
    );
