import {
  styled,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import ModeIcon from '@mui/icons-material/Mode';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { StyledButton, StyledModalBody } from './styledComponents';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { ProductTree } from './productTree';
import { ConfigViewContext } from './configViewContext';
import { TotalMetricsModalProvider } from './totalMetricsModalProvider';
import { MarketScoringWeightsTable } from './marketScoringWeightsTable';
import MarketsEditProvider from './marketEditProvider';
import { CONFIG_VIEW_MODE, NEW_MARKET } from './userGroupConfigConstants';
import { ModalSectionHeader as SectionHeaderWithSubtitle } from '../generic/modalSectionHeader';
import { RBAC_PERMISSIONS } from '../../constants';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const GridContainerBase = styled(Box)(({ maxWidth }) => ({
  maxWidth,
}));

const gridContainerColumnGap = 24;
const GridContainer = styled(FlexColumnBase)(({ gridEntryWidth }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, ${gridEntryWidth}px)`,
  justifyContent: 'start',
  alignContent: 'start',
  rowGap: 8,
  columnGap: gridContainerColumnGap,
  overflow: 'auto',
}));

const GridEntry = styled(Typography)(
  ({ gridEntryWidth, theme: { themeColors } }) => ({
    color: themeColors.neutral60,
    display: 'block',
    flexBasis: '33%',
    width: gridEntryWidth,
    margin: 0,
    textTransform: 'uppercase',
  })
);

const PrimaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.offWhiteFontColor,
}));

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const ProductTreeWrapper = styled('div')(({ theme: { themeColors } }) => ({
  '& .MuiTreeItem-iconContainer': {
    color: themeColors.primaryTextColor,
  },
  '& .MuiTreeItem-label': {
    color: themeColors.primaryTextColor,
    paddingLeft: 8,
  },
}));

const GridDisplay = ({ items = [] }) => {
  const hiddenRef = useRef(null);
  const displayNone = items.every((item) => typeof item !== 'string');
  const [gridEntryWidth, setGridEntryWidth] = useState(75);

  useEffect(() => {
    updateMaxGridEntryWidth();
  }, []);

  const updateMaxGridEntryWidth = () => {
    items.forEach((item) => {
      hiddenRef.current.textContent = item;
      const { width } = hiddenRef.current.getBoundingClientRect();

      if (width > gridEntryWidth) {
        setGridEntryWidth(width);
      }
    });
  };

  return (
    <>
      <GridContainerBase
        maxWidth={
          !displayNone
            ? Math.ceil(items.length / 3) *
                (gridEntryWidth + gridContainerColumnGap) -
              gridContainerColumnGap
            : gridEntryWidth
        }
      >
        <GridContainer gridEntryWidth={gridEntryWidth}>
          {displayNone ? (
            <GridEntry
              variant="body2"
              component="span"
              gridEntryWidth={gridEntryWidth}
            >
              None
            </GridEntry>
          ) : (
            items.map((item, index) => (
              <GridEntry
                key={index}
                variant="body2"
                component="span"
                gridEntryWidth={gridEntryWidth}
              >
                {typeof item === 'string' ? item : ''}
              </GridEntry>
            ))
          )}
        </GridContainer>
      </GridContainerBase>

      {/* This GridEntry is needed for measuring items length */}
      <GridEntry
        variant="body2"
        component="span"
        style={{ position: 'absolute', top: '-1000px', left: '-1000px' }}
        ref={hiddenRef}
      />
    </>
  );
};

const TextWrapper = styled(FlexColumnBase)({
  rowGap: 4,
});

const SectionContainer = styled(FlexColumnBase)({
  rowGap: 8,
});

const ScoringWeightContainer = styled(FlexColumnBase)({
  gap: 16,
});

const MarketsViewContainer = styled(FlexColumnBase)(
  ({ islast, isBeingEdited, hasLowOpacity, theme: { themeColors } }) => ({
    border: isBeingEdited ? `2px solid #5C8CD6` : 'none',
    borderBottom:
      islast || isBeingEdited
        ? ''
        : `1px solid ${themeColors.borderLowContrast}`,
    opacity: isBeingEdited || !hasLowOpacity ? 1 : 0.5,
    padding: '22px 25px',
    rowGap: 24,
  })
);

const ProgressWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ButtonWrapper = styled('div')({});

const StyledEditButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:hover': {
    color: themeColors.objectiveTypeEditButtonColor,
    cursor: 'pointer',
  },
}));

const StyledEditIcon = styled(ModeIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '16px',
  marginLeft: '4px',
}));

const SectionHeader = ({ header, isSmallHeader = false }) => (
  <PrimaryText variant={isSmallHeader ? 'body2' : 'h5'}>{header}</PrimaryText>
);

const Section = ({ header, items }) => (
  <SectionContainer>
    <PrimaryText variant="h5">{header}</PrimaryText>
    <GridDisplay items={items} />
  </SectionContainer>
);

const ShowModalSection = ({ header, onClick }) => (
  <SectionContainer>
    <SectionHeader header={header} />
    <StyledButton onClick={onClick}>Show {header}</StyledButton>
  </SectionContainer>
);

const MarketsViewEntry = ({
  market,
  setShowProductTreeModal,
  setShowMetricsModal,
  setMarketBeingEdited,
}) => {
  const { setProductLineId, setRegionalTeamId, setConfigViewMode } =
    useContext(ConfigViewContext);

  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);

  const {
    market: marketName,
    productLines,
    regionalTeamId,
    specialty: specialties,
    therapeuticArea: therapeuticAreas,
    indications,
    basketsScoringWeight,
    metricsScoringWeight,
  } = market;
  const productLineId = productLines[0]?.productLineId;

  const handleEditClick = () => {
    setMarketBeingEdited(market);
    setConfigViewMode(CONFIG_VIEW_MODE.EDIT);
  };

  return (
    <>
      <TitleWrapper>
        <TextWrapper>
          <PrimaryText variant="h5">{marketName}</PrimaryText>
          <SecondaryText variant="body2">
            Product Line ID:{' '}
            {productLineId ?? 'add a product tree to generate product line ID'}
          </SecondaryText>
          <SecondaryText variant="body2">
            Regional Team ID: {regionalTeamId}
          </SecondaryText>
        </TextWrapper>
        {hasConfigAdminPermissions && (
          <ButtonWrapper>
            <StyledEditButton onClick={handleEditClick}>
              <SecondaryText variant="body2">Edit</SecondaryText>
              <StyledEditIcon />
            </StyledEditButton>
          </ButtonWrapper>
        )}
      </TitleWrapper>
      <Section header="Total Specialties" items={specialties} />
      <Section
        header="Total Therapeutic Areas"
        items={[therapeuticAreas] || []}
      />
      <Section header="Indications" items={indications} />
      <ScoringWeightContainer>
        <SectionHeader header="Default Basket and Metric Scoring Weights" />
        <MarketScoringWeightsTable
          basketsScoringWeight={basketsScoringWeight}
          metricsScoringWeight={metricsScoringWeight}
        />
      </ScoringWeightContainer>
      <ShowModalSection
        header="Total Metrics List"
        onClick={() => {
          setRegionalTeamId(regionalTeamId);
          setShowMetricsModal(true);
        }}
      />
      {productLineId ? (
        <ShowModalSection
          header="Product Tree"
          onClick={() => {
            setProductLineId(productLineId);
            setShowProductTreeModal(true);
          }}
        />
      ) : (
        <SectionHeaderWithSubtitle
          title={
            <Stack direction="row" gap={1} alignItems="center">
              Product Tree <ErrorOutlineIcon fontSize="small" />
            </Stack>
          }
          subtitle="There is currently no product tree attached to this market. To be able to use this market, please create or attach an existing product tree."
        />
      )}
    </>
  );
};

const MarketsViewWrapper = styled(Box)({
  width: '100%',
  textAlign: 'left',
  overflowY: 'scroll',
  height: '100%',
});

export const MarketsView = ({ markets }) => {
  const [showProductTree, setShowProductTree] = useState(false);
  const [showMetrics, setShowMetrics] = useState(false);
  const [marketsToView, setMarketsToView] = useState([]);
  const [marketBeingEdited, setMarketBeingEdited] = useState(null);

  const { regionalTeamId, configViewMode } = useContext(ConfigViewContext);
  const marketName = markets
    ? markets.find((market) => market.regionalTeamId === regionalTeamId)?.market
    : '';

  useEffect(() => {
    setMarketsToView(markets);
  }, [markets]);

  useEffect(() => {
    if (configViewMode === CONFIG_VIEW_MODE.CREATE) {
      setMarketsToView([NEW_MARKET, ...markets]);
      setMarketBeingEdited(NEW_MARKET);
    }
  }, [configViewMode]);

  return (
    <MarketsViewWrapper>
      {marketsToView.map((market, index) =>
        marketBeingEdited?.marketId === market.marketId ? (
          <MarketsViewContainer
            islast={index === marketsToView.length - 1}
            isBeingEdited
            key={index}
          >
            <MarketsEditProvider
              marketBeingEdited={marketBeingEdited}
              setMarketBeingEdited={setMarketBeingEdited}
            />
          </MarketsViewContainer>
        ) : (
          <MarketsViewContainer
            key={index}
            islast={index === marketsToView.length - 1}
            hasLowOpacity={!!marketBeingEdited}
          >
            <MarketsViewEntry
              setMarketBeingEdited={setMarketBeingEdited}
              market={market}
              isLast={index === marketsToView.length - 1}
              setShowProductTreeModal={setShowProductTree}
              setShowMetricsModal={setShowMetrics}
            />
          </MarketsViewContainer>
        )
      )}
      <ConfigModal
        isOpen={showProductTree}
        onClose={() => setShowProductTree(false)}
        marketName={marketName}
      />
      <TotalMetricsModalProvider
        isOpen={showMetrics}
        marketName={marketName}
        onClose={() => setShowMetrics(false)}
      />
    </MarketsViewWrapper>
  );
};

function ConfigModal({ isOpen, onClose, marketName }) {
  const { productLineId, productLinesQuery } = useContext(ConfigViewContext);

  useEffect(() => {
    if (productLineId) productLinesQuery.refetch();
  }, [productLineId]);

  return (
    <ModalDisplay isOpen={isOpen}>
      <ModalHeader onClose={onClose}>
        {productLinesQuery.isLoading && 'Searching For Products'}
        {productLinesQuery.isSuccess && `${marketName} Product Tree`}
      </ModalHeader>
      <StyledModalBody>
        {productLinesQuery.isLoading && (
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        )}
        {productLinesQuery.isSuccess && (
          <ProductTreeWrapper>
            <ProductTree productLineTree={productLinesQuery.data} />
          </ProductTreeWrapper>
        )}
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
