import { useContext } from 'react';
import { Box, styled } from '@mui/material';
import { History, TimelineOutlined } from '@mui/icons-material';
import { SegmentChip } from '../../../shared/SegmentChip';
import { EM_DASH, SEGMENT_COLUMN_WIDTH } from '../../constants';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

const Wrapper = styled(Box)({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  width: SEGMENT_COLUMN_WIDTH,
  maxHeight: 40,
  overflow: 'hidden',
});

const formatTooltip = (timelines: string[], name: string[]): string | null => {
  if (!timelines.length) {
    return null;
  }

  return `${timelines.join(' and ')} ${name}`;
};

export const EntityTableBehaviouralSegmentCell = ({ data }) => {
  const { filters } = useContext(EntityOverviewContext);

  const showIcons = Boolean(
    Object.keys(filters?.behaviouralSegments ?? {}).length ||
      Object.keys(filters?.behaviouralSegmentTypes ?? {}).length
  );

  return (
    <Wrapper>
      {data.map((segment, index) => {
        const icons = [];
        const timelines = [];

        if (segment.isHistorical) {
          icons.push(<History data-testid="behavioural-segment-historical" />);
          timelines.push('Recent');
        }

        if (segment.isPredicted) {
          icons.push(
            <TimelineOutlined data-testid="behavioural-segment-predicted" />
          );
          timelines.push('Predicted');
        }

        return (
          <SegmentChip
            segmentLabel={segment.displayName}
            iconList={icons}
            tooltip={formatTooltip(timelines, [segment.displayName])}
            key={`segment-chip-${index}`}
            showIcon={showIcons}
          />
        );
      })}
    </Wrapper>
  );
};

export const EntityTableCustomerSegmentCell = ({ data }) => (
  <Wrapper>
    {data && data.length > 0
      ? data.map((segment, index) => (
          <SegmentChip segmentLabel={segment} key={`segment-chip-${index}`} />
        ))
      : EM_DASH}
  </Wrapper>
);
