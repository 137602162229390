import { TREND_TYPES } from '../components/fieldView/entitycontents/trendValues';

export const PREDICTION_PRECISION = Object.freeze({
  INCREASING_WITHIN_RANGE: 2,
  NO_CHANGE: 1,
  DECREASING_WITHIN_RANGE: 0,
  CANNOT_PREDICT: -1,
  INCREASING_OUTSIDE_RANGE: -2,
  DECREASING_OUTSIDE_RANGE: -3,
});

export const trendColor = ({ type, status, value }, themeColors) => {
  if (type === TREND_TYPES.VALUE) return themeColors.primaryTextColor;
  // if no value, but instead bad prediction status, use that instead
  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.CANNOT_PREDICT:
        return themeColors.primaryTextColor;
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return themeColors.increaseColorMain;
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
        return themeColors.increaseColorMain;
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return themeColors.decreaseColorMain;
      default:
        return undefined;
    }
  }

  if (value === 0) return themeColors.primaryTextColor;
  if (value > 0) return themeColors.increaseColorMain;
  if (value < 0) return themeColors.decreaseColorMain;
  return undefined;
};

export function trendDescription({ status }) {
  switch (status) {
    case PREDICTION_PRECISION.CANNOT_PREDICT:
      return 'Not enough data to generate a prediction';
    case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
      return 'MAT predicted to increase';
    case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
      return 'MAT predicted to decrease';
    default:
      return '';
  }
}
