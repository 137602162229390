/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import { useEffect, useRef, useState, JSX } from 'react';
import { useQuery } from 'react-query';
import { EntitiesTable } from '@odaia/shared-components/src/entitiesTable';
import { maptualApiInstance } from '../../../request/config';
import { getObjectiveProducts } from '../../application/appViews/pulse/shared/utils';
import { useRegions } from '../../application/appViews/pulse/views/salesReps/regionSelector/useRegions';

const _noEmptyParams = (...params) => {
  for (const param of params) {
    if (param === null || param === undefined) return false;
  }
  return true;
};

type InfiniteEntitiesTableProps = {
  regionId: string;
  hcpView: boolean;
  pageSize: number;
  curatedType: string[];
  regionName?: string;
  objectiveId: string;
  cadence: string;
  metric?: string;
  metricType?: string;
  timestamp: string | null | undefined;
  activitySubtitle: string;
  activityCadence: string;
  projectTargetProducts: unknown[];
  showAverageRow?: boolean;
  stickyColumns: unknown[];
  nonStickyColumns: unknown[];
  metricColumns: unknown[];
  activityColumns: unknown[];
  fullHeight: boolean;
  productLineId: string;
  projectId: string;
  showProduct: boolean;
  onRowClick: (entityId: string) => void;
  RowContentComponent: (props: {
    objectiveId: string;
    cadence: string;
    row: unknown;
    rank: unknown;
    isLoading: boolean;
    onRowClick: () => void;
  }) => JSX.Element;
};

export const InfiniteEntitiesTable = ({
  objectiveId,
  cadence,
  metric,
  metricType,
  timestamp,
  regionId,
  hcpView = false,
  pageSize = 25,
  curatedType,
  RowContentComponent,
  stickyColumns,
  nonStickyColumns,
  metricColumns,
  activityColumns,
  showAverageRow,
  activitySubtitle,
  activityCadence,
  regionName,
  productLineId,
  projectTargetProducts,
  projectId,
  showProduct = false,
  fullHeight = false,
  onRowClick = () => null,
}: InfiniteEntitiesTableProps) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [maxScrollTop, setMaxScrollTop] = useState(0);
  const [keepData, setKeepData] = useState(true);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [rows, setRows] = useState({
    [regionId]: [],
  });
  const [curatedString, setCuratedString] = useState(
    !curatedType || curatedType?.length > 1 ? 'all' : curatedType
  );
  const { data: regions } = useRegions({
    productLineId,
    territories: [],
    isHookEnabled: !!productLineId,
  });

  const prevRegionRef = useRef(regionId);
  const SCROLL_THRESHOLD = 800;

  const isHookEnabled =
    _noEmptyParams(productLineId, regionId, timestamp) &&
    (!rows[regionId] || rows[regionId]?.length === 0) &&
    (!hcpView ||
      (!!projectTargetProducts && projectTargetProducts.length >= 1));

  const fetchEntities = (_productLineId, _regionId, skip = 0) => {
    const productsString = (
      getObjectiveProducts(projectTargetProducts, objectiveId) || []
    )
      .map((i) => `target_products=${i}`)
      .join('&');

    return maptualApiInstance.get(
      `core/product-lines/${_productLineId}/projects/${projectId}/regions/${_regionId}/entities?skip=${skip}&limit=${pageSize}&${productsString}&timestamp=${timestamp}&score=${hcpView}&called=false&curatedType=${curatedString}&cadence=${cadence}&product=${showProduct}`,
      {
        timeout: 40000,
      }
    );
  };
  const { isLoading, isError, data, isFetching, refetch } = useQuery({
    queryKey: [
      'product-line',
      productLineId,
      'region',
      regionId,
      'entities',
      rows[regionId]?.length || 0,
      'timestamp',
      timestamp,
      'curatedType',
      curatedString,
      'cadence',
      cadence,
    ],
    queryFn: () =>
      fetchEntities(productLineId, regionId, rows[regionId]?.length || 0),
    keepPreviousData: keepData,
    enabled: isHookEnabled,
  });

  useEffect(() => {
    if (data) {
      setAllPagesLoaded(false);
      setKeepData(false);
    }
    setCuratedString(
      !curatedType || curatedType?.length > 1 ? 'all' : curatedType
    );
    setRows({
      ...rows,
      [regionId]: [],
    });
  }, [cadence, curatedType?.length, timestamp]);

  useEffect(() => {
    if (!isFetching) {
      prevRegionRef.current = regionId;
    }
  }, [isFetching]);

  useEffect(() => {
    if (!data?.data) return;
    setKeepData(true);
    if (data.data.length < pageSize) setAllPagesLoaded(true);
    const newRows = [];
    for (const row of data.data) {
      newRows.push({
        id: row.entity.id,
        entity: row.entity,
        score: row?.score,
        product: row?.product,
        objectiveId: row?.objective_id,
        rep: row?.rep,
      });
    }
    setRows((_prevRows) => {
      const prev = { ..._prevRows };
      const prevRegionRows = _prevRows[regionId]
        ? [..._prevRows[regionId]]
        : [];
      prev[regionId] = [...prevRegionRows, ...newRows];
      return prev;
    });
  }, [data]);

  useEffect(() => {
    const scrollEl = tableRef?.current;
    if (!scrollEl) return;
    const handleScroll = () => {
      if (scrollEl.scrollTop > maxScrollTop) {
        setMaxScrollTop(scrollEl.scrollTop);
      }
    };
    scrollEl.addEventListener('scroll', handleScroll);

    // unmount
    // eslint-disable-next-line consistent-return
    return () => {
      scrollEl.removeEventListener('scroll', handleScroll);
    };
  }, [tableRef.current]);

  useEffect(() => {
    const scrollEl = tableRef?.current;
    if (isLoading) return;
    if (!scrollEl) return;
    if (allPagesLoaded) return;
    const scrollDistanceFromBottom = Math.abs(
      scrollEl.scrollTop + scrollEl.clientHeight - scrollEl.scrollHeight
    );

    if (scrollDistanceFromBottom < SCROLL_THRESHOLD && rows[regionId]?.length) {
      refetch();
    }
  }, [tableRef?.current, maxScrollTop, allPagesLoaded]);

  const shouldShowError =
    (isFetching &&
      regionId !== prevRegionRef.current &&
      !!rows[prevRegionRef.current]) ||
    (!isLoading && isHookEnabled && (!data || isError));

  return (
    <EntitiesTable
      tableRef={tableRef}
      rows={rows}
      regions={regions}
      regionId={regionId}
      regionName={regionName}
      metric={metric}
      metricType={metricType}
      isLoading={isLoading}
      shouldShowError={shouldShowError}
      objectiveId={objectiveId}
      cadence={cadence}
      timestamp={timestamp}
      activitySubtitle={activitySubtitle}
      activityCadence={activityCadence}
      showAverageRow={showAverageRow}
      projectTargetProducts={projectTargetProducts}
      stickyColumns={stickyColumns}
      nonStickyColumns={nonStickyColumns}
      metricColumns={metricColumns}
      activityColumns={activityColumns}
      isFetching={isFetching}
      fullHeight={fullHeight}
      refetch={refetch}
      onRowClick={onRowClick}
      RowContentComponent={RowContentComponent}
    />
  );
};
