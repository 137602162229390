import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import EntityListItem, { CHIP_STYLES_OVERRIDES } from '../entityListItem';
import useConnectionsData from './useConnectionsData';
import * as ViewerAction from '../../../actions/projectViewActions';

const useStyles = makeStyles(({ themeColors }) => ({
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingMessage: {
    fontSize: 12,
    color: themeColors.secondaryTextColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  loadingIcon: {
    width: 30,
    height: 30,
    color: themeColors.secondaryTextColor,
  },
}));

const Connections = ({ onConnectionsLoaded }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { themeColors } = useTheme();
  const { isLoading, data: connections, error } = useConnectionsData();

  let formattedConnections = [];
  if (!isLoading && !error && connections) {
    onConnectionsLoaded(connections);
    const newconnections = connections.map((c) => {
      let newChips = [];
      if (c.chips && Array.isArray(c.chips)) {
        newChips = c.chips.map((chip) => ({
          style: CHIP_STYLES_OVERRIDES.LIGHT,
          label: chip,
        }));
      }
      return {
        ...c,
        chips: newChips,
      };
    });
    formattedConnections = {
      entityItems: newconnections,
      onSelect: (hcpId) => {
        dispatch(ViewerAction.selectEntity({ entityId: hcpId }));
      },
    };
  }

  return (
    <section>
      <Typography
        variant="overline"
        display="block"
        align="center"
        gutterBottom
        style={{
          color: themeColors.secondaryTextColor,
        }}
      >
        HCP Connections
      </Typography>
      {isLoading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && error && (
        <Typography className={classes.loadingMessage} color="textSecondary">
          Unable to load connections
        </Typography>
      )}
      {!isLoading &&
        !error &&
        connections?.map((item, i) => (
          <EntityListItem
            data={formattedConnections}
            index={i}
            key={item.entityId}
            style={{ minWidth: 375 }}
            data-testid="connection-row"
          />
        ))}
    </section>
  );
};

export default Connections;
