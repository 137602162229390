import { isEqual } from 'lodash';

import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatYAxisValue } from './drawerHelpers';
import {
  UNITS_ENUM,
  SHORT_FORM_CADENCE_ENUM,
  TREND_STATUSES_ENUM,
} from '../../constants';
import TrendValue from '../../../../components/fieldView/entitycontents/trendValues';

const TOOLTIP_MAX_WIDTH = 240;
const TOOLTIP_X_OFFSET = 8;

const Wrapper = styled(Box)(({ tooltip, theme: { themeColors } }) => ({
  display: tooltip.display,
  flexDirection: 'column',
  gap: 14,
  top: tooltip.top,
  left: tooltip.left,
  right: tooltip.right,
  opacity: tooltip.opacity,
  position: 'absolute',
  pointerEvents: 'none',
  padding: 12,
  zIndex: 1,
  backgroundColor: themeColors.tooltipBackgroundColor,
  borderRadius: 4,
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  textAlign: 'left',
  maxWidth: TOOLTIP_MAX_WIDTH,
}));

const Title = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

const Text = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  whiteSpace: 'nowrap',
}));

const Body = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
}));

const DataContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'fit-content',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: 4,
}));

const DataPoint = styled(Box)(({ color }) => ({
  width: 10,
  height: 10,
  backgroundColor: color,
  borderRadius: '50%',
}));

const buildTooltipData = (tooltipModel, chartPosition, unit) => ({
  display: 'flex',
  opacity: 1,
  left:
    tooltipModel.caretX + TOOLTIP_MAX_WIDTH < chartPosition.width
      ? tooltipModel.caretX + TOOLTIP_X_OFFSET
      : 'unset',
  right:
    tooltipModel.caretX + TOOLTIP_MAX_WIDTH >= chartPosition.width
      ? chartPosition.width - tooltipModel.caretX + TOOLTIP_X_OFFSET
      : 'unset',
  top: tooltipModel.caretY,
  lineValues: tooltipModel.dataPoints.map((dataPoint) => {
    const formattedValue = formatYAxisValue(dataPoint.raw, unit);
    return {
      label: dataPoint.dataset.timestamps[dataPoint.dataIndex],
      value:
        unit === UNITS_ENUM.dollar
          ? formattedValue
          : `${formattedValue} ${unit}`,
      color:
        dataPoint.dataset.pointBackgroundColor ||
        dataPoint.dataset.backgroundColor,
      trend: dataPoint.dataset.trends[dataPoint.dataIndex],
    };
  }),
});

export const INITIAL_TOOLTIP_STATE = {
  opacity: 0,
  display: 'none',
  top: 0,
  left: 0,
  right: 0,
  lineValues: [],
};

export const createTooltip = ({
  context,
  currentTooltip,
  updateTooltip,
  unit,
}) => {
  const { chart, tooltip: tooltipModel } = context;
  if (!chart) return;
  if (tooltipModel.opacity === 0) {
    if (currentTooltip.opacity !== 0) {
      updateTooltip({ ...currentTooltip, opacity: 0, display: 'none' });
    }
    return;
  }

  const chartPosition = context.chart.canvas.getBoundingClientRect();
  const newTooltipData = buildTooltipData(tooltipModel, chartPosition, unit);

  if (!isEqual(currentTooltip, newTooltipData)) {
    updateTooltip(newTooltipData);
  }
};

export const Tooltip = ({ tooltip, title, cadence }) => (
  <Wrapper tooltip={tooltip}>
    <Title variant="title3">{title}</Title>
    {tooltip?.lineValues?.map((lineValue, index) => (
      <Body key={index}>
        <DataPoint color={lineValue.color} />
        <DataContainer>
          <TrendValue
            type={lineValue.trend?.type}
            status={lineValue.trend?.status}
            value={lineValue.trend?.value}
            noMargin
            filled
          />
          <Text variant="label">
            {cadence === SHORT_FORM_CADENCE_ENUM.MAT &&
            lineValue.trend?.status <
              TREND_STATUSES_ENUM.VALID_METRIC_DATA_STATUS
              ? lineValue.label
              : `${lineValue.label}: ${lineValue.value}`}
          </Text>
        </DataContainer>
      </Body>
    ))}
  </Wrapper>
);
