import { useContext, useMemo, JSX } from 'react';
import { useTheme, styled, Skeleton, Typography } from '@mui/material';
import { SHORT_FORM_CADENCE, TREND_STATUS } from '../constants';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';
import { ProductPerformanceChartContext } from './data/productPerformanceChartContext';
import { ErrorContainer } from '../../../generic/errorContainer';
import { toCapitalize } from '../../../../utils/toCapitalize';
import { ExtendedChartLineData } from './data/useProductPerformanceChartContext';
import { BarChart, BarChartData, BarChartDataset } from '../shared/BarChart';

const PERCENTAGE_MULTIPLIER: number = 100;
const MAX_CATEGORY_SIZING = 0.8;
const MAX_BAR_SIZING = 0.8;

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

export const formatChartLabels = (
  chartData: ExtendedChartLineData[],
  cadence: string
): string[] => {
  if (!chartData?.length) return [];

  return chartData[0].chartValues.map((item) => {
    if (
      cadence === SHORT_FORM_CADENCE.YR ||
      cadence === SHORT_FORM_CADENCE.MAT
    ) {
      return item.timestamp;
    }

    return item.timestamp.replace(/(,* \d{4})/, '');
  });
};

export const formatChartDatasets = (
  chartData: ExtendedChartLineData[],
  cadence: SHORT_FORM_CADENCE
): BarChartDataset[] => {
  const formattedBarDatasets: BarChartDataset[] = [];

  chartData.forEach((productData) => {
    // skip product if not displayed
    if (!productData.chartLineColor) {
      return;
    }

    const flattenedChartValuesToArray = productData.chartValues.reduce(
      (acc, item) => {
        let dataValue = 0;

        switch (item.trend.status) {
          case TREND_STATUS.CANNOT_PREDICT:
            break;
          case TREND_STATUS.INCREASING_OUT_OF_RANGE:
          case TREND_STATUS.DECREASING_OUT_OF_RANGE:
            dataValue = acc.data[acc.data.length - 1];
            break;
          default:
            dataValue = item.data * PERCENTAGE_MULTIPLIER;
        }

        return {
          timestamps: [...acc.timestamps, item.timestamp],
          data: [...acc.data, dataValue],
          trends: [...acc.trends, item.trend],
        };
      },
      { timestamps: [], data: [], trends: [] }
    );
    let generatedStylingsPerBar = {
      backgroundColor: productData.chartLineColor,
    };

    if (cadence === SHORT_FORM_CADENCE.YR) {
      const lastIndex = flattenedChartValuesToArray.data.length - 1;
      generatedStylingsPerBar = flattenedChartValuesToArray.trends.reduce(
        (acc, trend, index) => {
          const isPredictionBar =
            trend.status !== TREND_STATUS.CANNOT_PREDICT && index === lastIndex;

          return {
            backgroundColor: [
              ...acc.backgroundColor,
              isPredictionBar
                ? `${productData.chartLineColor}80`
                : productData.chartLineColor,
            ],
            borderWidth: [...acc.borderWidth, isPredictionBar ? 1 : 0],
          };
        },
        {
          backgroundColor: [],
          borderWidth: [],
        }
      );
    }

    const formattedDataset: BarChartDataset = {
      label: productData.label,
      borderColor: productData.chartLineColor,
      ...generatedStylingsPerBar,
      ...flattenedChartValuesToArray,
      categoryPercentage: MAX_CATEGORY_SIZING,
      barPercentage: MAX_BAR_SIZING,
    };

    formattedBarDatasets.push(formattedDataset);
  });

  return formattedBarDatasets;
};

export const ProductPerformanceBarChart = (): JSX.Element => {
  const theme = useTheme();
  const { themeColors } = theme;

  const {
    isMetadataLoading,
    selectedCadence,
    selectedCardTab,
    selectedMetric,
    currentTabMetadata,
  } = useContext(ProductPerformanceMetadataContext);

  const { isDataLoading, data, title, isDataError, isDataIdle, refetchData } =
    useContext(ProductPerformanceChartContext);

  const metricName: string = currentTabMetadata?.metrics?.find(
    (metric) => metric.rxType === selectedMetric
  )?.displayName;

  const formattedChartData: BarChartData = useMemo(
    () => ({
      labels: formatChartLabels(data, selectedCadence?.id),
      datasets: formatChartDatasets(data, selectedCadence?.id),
    }),
    [data]
  );

  if (isMetadataLoading || isDataIdle || isDataLoading || !formattedChartData) {
    return <SkeletonBar animation="wave" height={225} />;
  }

  if (isDataError) {
    return <ErrorContainer handleRetry={refetchData} />;
  }

  if (!data?.length) {
    return (
      <ErrorContainer
        title="Graph and table not available"
        description="We do not have enough data to generate a graph and table for these products."
      />
    );
  }

  return (
    <>
      <Typography
        variant="h6"
        textAlign="left"
        color={themeColors.primaryTextColor}
      >
        {title}, {selectedCadence.label}
      </Typography>
      <BarChart
        selectedCadence={selectedCadence}
        selectedCardTab={selectedCardTab}
        data={formattedChartData}
        metric={metricName}
        yAxisTitle={`${metricName} ${toCapitalize(selectedCardTab)}`}
        legendPlugin={{ display: false }}
        dataTestId="product-performance-bar-chart"
      />
    </>
  );
};
