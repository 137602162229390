import { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import EmojiObjectsOutlined from '@mui/icons-material/EmojiObjectsOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Sell from '@mui/icons-material/Sell';
import Warning from '@mui/icons-material/Warning';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { InsightsCard } from './insightsCard/insightsCard';
import { useEntityActivityInsights } from '../../../hooks/useEntityActivityInsights';

const WarningIconView = styled((props) => <Warning {...props} />)(() => ({
  height: 16,
  width: 16,
  color: '#d3a42c',
  marginTop: '2px',
}));

const ErrorIcon = styled((props) => <Warning {...props} />)(() => ({
  height: 16,
  width: 16,
  color: '#a3afc2',
  marginTop: '2px',
}));

const DefaultIconView = styled((props) => <Sell {...props} />)(() => ({
  height: 16,
  width: 16,
  color: '#8595ad',
  marginTop: '2px',
}));

const LightbulbIconView = styled((props) => <TipsAndUpdatesIcon {...props} />)(
  () => ({
    height: 16,
    width: 16,
    color: '#336fcc',
    marginTop: '2px',
  })
);

const CheckmarkIconView = styled((props) => <CheckCircleIcon {...props} />)(
  () => ({
    height: 16,
    width: 16,
    color: '#399368',
    marginTop: '2px',
  })
);

const InsightsCardWithIconContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  margin: 0,
  width: 'calc(50% - 24px)',
  flexDirection: 'row',
  marginRight: spacing(3),
}));

const InsightsCardContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  marginTop: spacing(2.25),
  flexWrap: 'wrap',
}));

const ActivityInsightsCard = styled(Box)(({ theme: { spacing } }) => ({
  textAlign: 'left',
  marginLeft: spacing(1.25),
}));

const InsightsCardTitle = styled(Typography)(
  ({ theme: { themeColors, spacing } }) => ({
    color: themeColors.insightsCardTitle,
    marginBottom: spacing(1),
  })
);

const InsightsCardContent = styled(Typography)(
  ({ theme: { themeColors, spacing } }) => ({
    fontSize: 14,
    color: themeColors.primaryMaptualListFilteringColor,
    fontWeight: 400,
    marginTop: spacing(0.75),
    marginBottom: spacing(2),
    overflow: 'hidden',
  })
);

const ErrorInsightsCardWithIconContainer = styled(
  InsightsCardWithIconContainer
)(() => ({
  width: 'calc(100% - 24px)',
}));

const typeToIconMap = {
  'CRM OOR': <WarningIconView />,
  'CRM IR': <CheckmarkIconView />,
  SAMPLE: <CheckmarkIconView />,
  WEBSITE: <CheckmarkIconView />,
  PREDICTED_PATIENT: <CheckmarkIconView />,
  BENEFIT_VERIFICATION: <CheckmarkIconView />,
  EVENT: <LightbulbIconView />,
  EMAIL: <LightbulbIconView />,
  DDD_NON_RETAIL: <LightbulbIconView />,
  'DIGITAL DATA': <LightbulbIconView />,
};

const CardIcon = ({ type }) => {
  if (typeToIconMap[type]) {
    return typeToIconMap[type];
  }

  return <DefaultIconView />;
};

export const InsightsActivity = ({ entityId, projectId, maptualListId }) => {
  const { data, isLoading, isError } = useEntityActivityInsights(
    entityId,
    projectId,
    maptualListId
  );

  const [insightsActivityData, setInsightsActivityData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      if (data?.length > 0) {
        setInsightsActivityData(data);
      }

      if (data?.length === 0) {
        setInsightsActivityData([]);
      }

      if (!data && isError) {
        setInsightsActivityData(null);
      }
    }

    return () => {
      setInsightsActivityData([]);
    };
  }, [data, isLoading, isError]);

  if (!insightsActivityData?.length && !isLoading) {
    return (
      <InsightsCard
        title="HCP Insights"
        subtitle="Contextual information to better understand behavioral changes in HCPs outside of their Rx data."
        icon={EmojiObjectsOutlined}
        isLoading={false}
      >
        <InsightsCardContainer>
          <ErrorInsightsCardWithIconContainer>
            <ErrorIcon />
            <ActivityInsightsCard>
              <InsightsCardTitle variant="title3">
                No HCP Insights Available
              </InsightsCardTitle>
              <InsightsCardContent>
                Please refer to Script Volume and Market Share insights for more
                information about this HCP.
              </InsightsCardContent>
            </ActivityInsightsCard>
          </ErrorInsightsCardWithIconContainer>
        </InsightsCardContainer>
      </InsightsCard>
    );
  }

  return (
    <InsightsCard
      title="HCP Insights"
      subtitle="Contextual information to better understand behavioral changes in HCPs outside of their Rx data."
      icon={EmojiObjectsOutlined}
      isLoading={isLoading}
    >
      <InsightsCardContainer>
        {insightsActivityData &&
          insightsActivityData.map((insight) => (
            <InsightsCardWithIconContainer key={insight.title}>
              <CardIcon type={insight.type} />
              <ActivityInsightsCard>
                <InsightsCardTitle variant="title3">
                  {insight.title}
                </InsightsCardTitle>
                <InsightsCardContent>{insight.description}</InsightsCardContent>
              </ActivityInsightsCard>
            </InsightsCardWithIconContainer>
          ))}
      </InsightsCardContainer>
    </InsightsCard>
  );
};
