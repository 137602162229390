/* eslint-disable react/jsx-key */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { Box, Stack, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as layoutStyles from '../../styles/layoutStyles';
import EmptyView from '../emptyview/emptyView';
import ProjectsList from './projectList';
import AdminProjectTile from './adminProjectTile';
import HeadOfficeProjectTile from './headOfficeProjectTile';
import ClearCacheDialog from './clearCacheDialog';
import ClearCacheButton from '../generic/clearCacheButton';
import { DEPLOY_CSV_STATES } from './deployCsvDialog/sharedComponents';
import { DeployCsvProvider } from './deployCsvDialog/deployCsvProvider';
import { showConfirmationDialog } from '../../slices/adminView';
import { DeployDialogContext } from './deployCsvDialog/deployDialogContext';

import { NewProjectProvider } from '../newproject/newProjectProvider';
import { APPVIEWS, PROJECT_STATUS, RBAC_PERMISSIONS } from '../../constants';
import NewProjectTemplateButton from '../generic/newProjectTemplateButton';
import { ProjectModalMode } from '../newproject/projectModalMode';
import { ProjectTemplateProvider } from './projectTemplates/projectTemplateProvider';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';

const useStyles = makeStyles(() => ({
  inlineContainer: layoutStyles.inlineContainer,
  utilityButton: {
    padding: '8px 16px',
    borderRadius: 3,
    fontWeight: 400,
  },
}));

const UtilityButtonContainer = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'space-between',
  gap: 10,
});

const ProjectsListContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0 3vw',
  marginBottom: 24,
  gridGap: '16px',
});

const ProjectsStatusTextStyle = styled(Box)({
  fontWeight: 500,
  fontSize: 16,
});

const minimizeProjects = (projects) =>
  projects.map((project) => {
    const { projectId, projectName, marketId, projectStatus } = project;
    return {
      projectId,
      projectName,
      marketId,
      deployed: projectStatus === PROJECT_STATUS.DEPLOYED,
    };
  });

export default function ProjectContent({
  deleteProject,
  updateProject,
  setProjectId,
  getProject,
  match,
  profile,
  setInjectableComponents,
  viewer,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type: roleType } = profile ? profile.role : undefined;
  const TileComponent =
    roleType === APPVIEWS.ADMIN ? AdminProjectTile : HeadOfficeProjectTile;
  const [activeProject, setActiveProject] = useState({});
  const [activeTemplate, setActiveTemplate] = useState({});
  const [deployDialogState, setDeployDialogState] = useState(
    DEPLOY_CSV_STATES.IDLE
  );
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [projectModalMode, setProjectModalMode] = useState<ProjectModalMode>(
    ProjectModalMode.none
  );

  const hasProjectAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.PROJECTS_ADMIN,
  ]);

  const hasProjectTemplatePermissions = useIsAuthorized([
    RBAC_PERMISSIONS.PROJECT_TEMPLATES_ADMIN,
  ]);

  const handleNewTemplateButtonClick = () => {
    setShowProjectDialog(true);
    setProjectModalMode(ProjectModalMode.templateCreate);
  };

  useEffect(() => {
    if (roleType === APPVIEWS.ADMIN) {
      setInjectableComponents([
        <UtilityButtonContainer>
          {hasProjectAdminPermissions ? (
            <ClearCacheButton
              className={classes.utilityButton}
              onClick={() => {
                dispatch(showConfirmationDialog());
              }}
            />
          ) : (
            <div />
          )}
          <Stack direction="row" gap={2}>
            <NewProjectTemplateButton onClick={handleNewTemplateButtonClick} />
          </Stack>
        </UtilityButtonContainer>,
      ]);
    } else {
      setInjectableComponents([]);
    }

    getProject();
  }, []);

  const dialogContextValues = useMemo(
    () => ({
      activeProject,
      setActiveProject,
      activeTemplate,
      setActiveTemplate,
      deployDialogState,
      setDeployDialogState,
      setShowProjectDialog,
      projectModalMode,
      setProjectModalMode,
      projects: minimizeProjects(viewer.projects),
      isTemplate: [
        ProjectModalMode.templateCreate,
        ProjectModalMode.templateEdit,
      ].includes(projectModalMode),
    }),
    [
      activeProject,
      deployDialogState,
      projectModalMode,
      activeTemplate,
      setActiveTemplate,
      viewer.projects,
    ]
  );

  const filterProjects = (filterDeployed) =>
    [...viewer.projects].filter(
      (project) =>
        (filterDeployed && project.projectStatus === PROJECT_STATUS.DEPLOYED) ||
        (!filterDeployed && project.projectStatus !== PROJECT_STATUS.DEPLOYED)
    );

  const renderProjectsList = (projectsListFilter) => {
    const filterDeployed = projectsListFilter.deployedStatus;
    const filteredProjects = filterProjects(filterDeployed);

    return (
      <ProjectsListContainer>
        <Typography>
          <ProjectsStatusTextStyle>
            {filterDeployed ? 'Deployed' : 'Undeployed'}
          </ProjectsStatusTextStyle>
        </Typography>
        <ProjectsList
          projects={filteredProjects}
          match={match}
          deleteProject={deleteProject}
          setProjectId={setProjectId}
          updateProject={updateProject}
          userGroup={profile.userGroup}
          renderTile={(props) => <TileComponent {...props} />}
        />
      </ProjectsListContainer>
    );
  };

  return (
    <DeployDialogContext.Provider value={dialogContextValues}>
      <div className={classes.inlineContainer}>
        <ClearCacheDialog />
        <DeployCsvProvider />
        {hasProjectTemplatePermissions && <ProjectTemplateProvider />}
        {Array.isArray(viewer.projects) && viewer.projects.length > 0 ? (
          <div>
            {renderProjectsList({ deployedStatus: true })}
            {renderProjectsList({ deployedStatus: false })}
          </div>
        ) : (
          <EmptyView isLoading={viewer.isBusy} refreshAction={getProject} />
        )}
        <NewProjectProvider isOpen={showProjectDialog} />
      </div>
    </DeployDialogContext.Provider>
  );
}

ProjectContent.propTypes = {
  deleteProject: PropTypes.func,
  setProjectId: PropTypes.func,
  getProject: PropTypes.func,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  profile: PropTypes.shape({
    role: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  setInjectableComponents: PropTypes.func,
  viewer: PropTypes.shape({
    isBusy: PropTypes.bool,
    projects: PropTypes.array,
  }),
};
