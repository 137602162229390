import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@mui/material';
import Scrollbar from 'react-scrollbars-custom';
import Fade from '@mui/material/Fade';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import SubCharts from './subCharts';
import EmptyView from '../../emptyview/emptyView';
import { CHART_CONTAINER_WIDTH_OPTIONS } from '../constants';

const CustomScrollbar = styled(Scrollbar)`
  width: 100%;
  height: 100%;
  padding: 0px;
  padding-top: 15px;
`;

const heightOptions = { Compact: '220px', Tall: '350px' };

const DashboardView = ({
  projectId,
  segmentId,
  maptualListId,
  getDashboardStructure,
  getDashboardSubUrl,
}) => {
  const [dashboardStruct, setDashboardStruct] = useState([]);
  const [widthPref, setWidthPref] = useState('Narrow');
  const [heightPref, setHeightPref] = useState('Compact');
  const [fetchingDashboardStruct, setFetchingDashboardStruct] = useState(true);
  const handleWidthToggle = (event, value) => {
    setWidthPref(value);
  };

  const handleHeightToggle = (event, value) => {
    setHeightPref(value);
  };

  const fetchDashboardStruct = async () => {
    setFetchingDashboardStruct(true);
    const dashboardStructure = await getDashboardStructure({
      projectId,
      segmentId,
      maptualListId,
    });
    if (
      dashboardStructure.action.type ===
      'PROJECT_GET_DASHBOARD_STRUCTURE_FULFILLED'
    ) {
      setDashboardStruct([
        ...dashboardStructure.action.payload.metricsSections,
      ]);
    }
    setFetchingDashboardStruct(false);
  };

  useEffect(() => {
    fetchDashboardStruct();
  }, [segmentId, maptualListId]);

  return fetchingDashboardStruct ? (
    <Fade in timeout={600} key="loading">
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <EmptyView isLoading />
      </div>
    </Fade>
  ) : (
    <CustomScrollbar disableTracksWidthCompensation>
      <div
        style={{
          width: '100%',
          height: 50,
          display: 'flex',
          marginTop: 15,
          paddingLeft: 15,
        }}
      >
        <ToggleButtonGroup
          value={widthPref}
          exclusive
          onChange={handleWidthToggle}
          aria-label="Dashboard Width"
          size="small"
          color="info"
        >
          <ToggleButton value="Narrow" aria-label="Narrow">
            <ViewComfyIcon />
          </ToggleButton>
          <ToggleButton value="Wide" aria-label="Wide">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="Full Width" aria-label="Full Width">
            <TableRowsIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          value={heightPref}
          exclusive
          onChange={handleHeightToggle}
          aria-label="Dashboard Height"
          size="small"
          color="info"
          style={{ marginLeft: 30 }}
        >
          <ToggleButton value="Compact" aria-label="Narrow">
            <ViewComfyIcon />
          </ToggleButton>
          <ToggleButton value="Tall" aria-label="Wide">
            <ViewColumnIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          paddingTop: 15,
        }}
      >
        {dashboardStruct.map((section) => (
          <SubCharts
            key={`${section.componentType}-${section.subUrl}`}
            getDashboardSubUrl={getDashboardSubUrl}
            subUrl={section.subUrl}
            projectId={projectId}
            segmentId={segmentId}
            maptualListId={maptualListId}
            primaryText={section.primaryText}
            tileWidth={CHART_CONTAINER_WIDTH_OPTIONS[widthPref]}
            tileGraphHeight={heightOptions[heightPref]}
            componentType={section.componentType}
          />
        ))}
      </div>
    </CustomScrollbar>
  );
};

const mapStateToProps = (state) => ({
  segmentId: state.projectViewer.selectedSegmentId,
  maptualListId: state.projectViewer.selectedRegion?.maptualListId,
});

DashboardView.propTypes = {
  projectId: PropTypes.string,
  segmentId: PropTypes.string,
  maptualListId: PropTypes.string,
  getDashboardStructure: PropTypes.func,
  getDashboardSubUrl: PropTypes.func,
};

export default connect(mapStateToProps)(DashboardView);
