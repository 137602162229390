import { useQuery } from 'react-query';
import { getObjectiveBaskets } from '../../../../../request/projectRequests';
import { EntityType } from '../../../../../clients/maptualAPI';

export const useObjectiveBaskets = ({
  projectId,
  entityType,
}: {
  projectId?: string;
  entityType: EntityType;
}) => {
  const { data, error, isFetching, isLoading } = useQuery(
    [projectId, entityType],
    async () => {
      if (projectId) {
        const response = await getObjectiveBaskets({ projectId, entityType });
        return response?.data;
      }
      return null;
    },
    { enabled: Boolean(projectId && entityType) }
  );

  return {
    data,
    error,
    isFetching,
    isLoading,
  };
};
