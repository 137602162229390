/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import * as ViewerAction from '../../actions/projectViewActions';
import HCPEntityPage from '../../components/fieldView/hcpEntityPage';
import { useMetricScriptsMetadata } from '../../hooks/useMetricScriptsMetadata';
import { useInsights } from '../fieldview/useInsights';
import { useShare } from '../fieldview/useShare';
import { useHcpMetadata } from './useHcpMetadata';
import { AppContext } from '../application/appContext';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    height: '100%',
    'min-height': '100%',
    display: 'flex',
    backgroundColor: themeColors.mainBackground,
  },
}));

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  selectEntity: (entityMetadata) =>
    dispatch(ViewerAction.selectEntity(entityMetadata)),
});

const eagerLoadMetricScriptsData = () => useMetricScriptsMetadata();

function HcpInfoContainer({ viewer, match, className = '' }) {
  const classes = useStyles();

  const { projectId } = match.params;

  const { projectList } = useContext(AppContext);
  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;

  const { isMaptualListBusy } = viewer;

  const { insights, refetchInsights } = useInsights({
    entityId: match.params.entityId,
    projectId,
    maptualListId: match.params.maptualListId,
  });

  const { share, refetchShare } = useShare({
    entityId: match.params.entityId,
    projectId,
    maptualListId: match.params.maptualListId,
  });

  // TODO: Remove once Snowflake IDs are being received in Sphere
  // and IDs can be once again fetched from the params
  const { metadata } = useHcpMetadata({
    productLineId,
    rdsRegionId: match.params.maptualListId,
    entityId: match.params.entityId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.snowflakeEntityId) {
      dispatch(
        ViewerAction.initializeStandaloneEntityPage({
          entityId: match.params.entityId,
          snowflakeEntityId: match.params.snowflakeEntityId,
          maptualListId: match.params.maptualListId,
          snowflakeMaptualListId: '',
          projectId,
          isStandalonePage: true,
        })
      );
    }
  }, []);

  // TODO: Remove once Snowflake IDs are being received in Sphere
  // and IDs can be once again fetched from the params
  useEffect(() => {
    if (!match.params.snowflakeEntityId && metadata) {
      dispatch(
        ViewerAction.initializeStandaloneEntityPage({
          entityId: match.params.entityId,
          snowflakeEntityId: metadata.snowflakeEntityId,
          maptualListId: match.params.maptualListId,
          snowflakeMaptualListId: metadata?.snowflakeRegionId,
          projectId,
          isStandalonePage: true,
        })
      );
    }
  }, [metadata]);

  eagerLoadMetricScriptsData();

  return (
    <div className={`${classes.root} ${className}`}>
      <HCPEntityPage
        entityId={match?.params?.entityId}
        snowflakeEntityId={
          match?.params?.snowflakeEntityId ?? metadata?.snowflakeEntityId
        }
        insights={insights}
        share={share}
        getShare={() => refetchShare()}
        getInsights={() => refetchInsights()}
        isMaptualListBusy={isMaptualListBusy}
        standaloneParams={{
          maptualListId: match?.params?.maptualListId,
          snowflakeMaptualListId: metadata?.snowflakeRegionId,
          projectId,
        }}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HcpInfoContainer);
