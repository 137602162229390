import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Skeleton, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Scrollbar from 'react-scrollbars-custom';
import isEmpty from 'lodash/isEmpty';
import RawDataDisplay from './entitycontents/rawDataDisplay';
import SkeletonChart from '../generic/SkeletonChart';
import { useMetricScriptsMetadata } from '../../hooks/useMetricScriptsMetadata';
import MetricScriptsChart from './entitycontents/metricScriptsChart';
import { MultiObjectivesRow } from '../../containers/shared/MultiObjectivesRow';
import InsightsCardRow from './entityInsights/insightsCardRow';
import { EntityTitleBarContainer } from './entityTitleBar';
import { trackObjectiveSelected } from '../../trackers/mixpanel';
import InsightsSegments from './entityInsights/insightsSegments';
import { InsightsActivity } from './entityInsights/insightsActivity';
import { InsightsChannels } from './entityInsights/insightsChannels';
import { InsightsPrecall } from './entityInsights/insightsPrecall';
import { InsightsTopics } from './entityInsights/insightsTopics';
import { InsightsWarningCard } from './entityInsights/insightsWarningCard';
import { InsightsLeaveBehinds } from './entityInsights/insightsLeaveBehinds';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { AppContext } from '../../containers/application/appContext';
import { CategoricalDataWrapper } from './entityCategoricalData/categoricalDataWrapper';
import { EntityMessages } from './entityMessages/entityMessages';
import { useEntity } from '../../containers/fieldview/useEntity';
import { FieldContext } from '../../containers/application/appViews/field/fieldContext';
import { ProductPerformanceCard } from './entityCharts/productPerformance';
import { MarketPerformanceCard } from './entityCharts/marketPerformance';
import { useModuleConfigurations } from '../../hooks/useModuleConfigurations';
import { getProjectList } from '../../request/projectRequests';
import { getInternalAccountStatus } from '../../request/config';
import { OpportunityCard } from './entityCharts/opportunity';
import { ParamType } from '../../clients/maptualAPI';

interface EntityChartsConfig {
  data: {
    showProductPerformanceChart: boolean;
  };
}

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 640,
  },
  content: {
    width: '100%',
    maxWidth: 810,
    minWidth: 560,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  briefContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionText: {
    fontSize: 22,
    fontWeight: 800,
    color: themeColors.primaryTextColor,
    width: 'auto',
  },
}));

const SkeletonLoadingBar = styled(Skeleton)(
  ({ theme: { themeColors, spacing } }) => ({
    backgroundColor: themeColors.surfaceEmpty,
    transform: 'scale(1)',
    marginBottom: spacing(2),
    height: 80,
    width: '100%',
  })
);

const hasValidMarketBasket = (objectiveBasket): boolean => {
  const competitorBasket = objectiveBasket[ParamType.ProductFrom];

  if (!competitorBasket || competitorBasket.products.length === 0) return false;

  const hasContinuousMetric: boolean = competitorBasket.metrics.some(
    (metric) => metric.dataType === 'continuous'
  );

  return hasContinuousMetric;
};

const hasValidOpportunityBasket = (objectiveBasket): boolean => {
  const validOpportunityBaskets = [
    ParamType.AnalogueProduct,
    ParamType.PrecursorProduct,
    ParamType.UnderlyingProduct,
  ];

  const hasAtLeastOneBasketWithProducts = validOpportunityBaskets.some(
    (basketType) => {
      const basket = objectiveBasket[basketType];
      return (
        basket &&
        basket.products.length > 0 &&
        basket.metrics.some(
          (metric) => metric.dataType === 'continuous' && metric.displayPriority
        )
      );
    }
  );

  return hasAtLeastOneBasketWithProducts;
};

export const EntityMaptualInsightsView = ({
  entityId,
  snowflakeEntityId,
  insights,
  shareDataComplete,
  displayUserCreatedListsButton,
  standaloneParams,
  projectId,
  isNewProjectId,
  setIsNewProjectId,
  currentObjectiveId,
  setCurrentObjectiveId,
}) => {
  const { profile } = useContext(AppContext);
  const fieldContext = useContext(FieldContext);
  const isInternalAccount = getInternalAccountStatus();

  const maptualListId =
    standaloneParams?.maptualListId ||
    fieldContext?.maptualListMetadata?.maptualListId;

  const { entity, isEntityBusy } = useEntity({
    entityId,
    maptualListId,
    projectId,
  });

  const metadata = entity?.metadata || {};
  const maptualInsights = entity?.maptualInsights || null;

  // Visual component elements
  const classes = useStyles();
  const featureToggles = useFeatureToggles();
  const {
    isSuccess: isMetricScriptsMetadataLoaded,
    error: metricScriptsMetadataError,
  } = useMetricScriptsMetadata();

  const getScriptsErrorMessage = (message) => {
    let messageUpdated = message;
    if (message === 'Network Error') {
      messageUpdated =
        'We currently do not have enough data to generate a graph and table for this HCP';
    }
    return messageUpdated;
  };

  const [activeObjective, setActiveObjective] = useState({});
  const [objectivesRowActiveObjective, setObjectivesRowActiveObjective] =
    useState({});
  const [viewMultiObjectives, setViewMultiObjectives] = useState(false);
  const [objectiveLoaded, setObjectiveLoaded] = useState(false);
  const [marketId, setMarketId] = useState(
    fieldContext?.project?.metadata?.marketId
  );

  const { data: projectList, isLoading: isProjectListLoading } = useQuery(
    ['project-list'],
    getProjectList,
    { enabled: !!standaloneParams }
  );

  useEffect(() => {
    if (!isProjectListLoading && projectList?.data) {
      const matchingProject = projectList.data.find(
        (project) => project.projectId === projectId
      );
      setMarketId(matchingProject?.marketId);
    }
  }, [projectList, isProjectListLoading]);

  useEffect(() => {
    if (insights?.objectives && insights.objectives.length > 0) {
      let selectedObjectiveId = currentObjectiveId;

      if (!selectedObjectiveId || isNewProjectId) {
        selectedObjectiveId = insights.objectives[0].objectiveId;
        setCurrentObjectiveId(selectedObjectiveId);
        setIsNewProjectId(false);
      }

      const selectedObjective = insights?.objectives?.find(
        (obj) => obj.objectiveId === selectedObjectiveId
      );

      const hasMoreThanOneObjective = insights.objectives.length > 1;
      const hasOneObjective = insights.objectives.length === 1;

      if (selectedObjective) {
        setActiveObjective(selectedObjective);
        setObjectivesRowActiveObjective({
          ...selectedObjective,
          id: selectedObjective.objectiveId,
          label: selectedObjective.objectiveDescription,
          maptualDisplayScore: selectedObjective.objectiveScore,
        });
        if (hasMoreThanOneObjective) {
          trackObjectiveSelection(selectedObjective, true);
        }
      }

      setViewMultiObjectives(hasMoreThanOneObjective);

      if (hasOneObjective || hasMoreThanOneObjective) {
        setObjectiveLoaded(true);
      }
    } else {
      setViewMultiObjectives(false);
    }

    return () => {
      setActiveObjective({});
      setObjectivesRowActiveObjective({});
      setViewMultiObjectives(false);
      setObjectiveLoaded(false);
    };
  }, [insights, currentObjectiveId, isNewProjectId]);

  const [objectiveScores, setObjectiveScores] = useState([]);

  useEffect(() => {
    if (insights?.powerscore !== undefined) {
      setObjectiveScores(
        insights?.objectives?.map((obj) => Math.round(obj.objectiveScore))
      );
    } else {
      setObjectiveScores([]);
    }
  }, [insights]);

  const [insightsObjectives, setInsightsObjectives] = useState([]);
  useEffect(() => {
    if (insights?.objectives?.length > 0) {
      const objectives = [];

      insights.objectives.forEach((objective) => {
        objectives.push({
          ...objective,
          id: objective.objectiveId,
          label: objective.objectiveDescription,
          maptualDisplayScore: objective.objectiveScore,
        });
      });

      setInsightsObjectives(objectives);
    }
  }, [insights?.objectives]);

  const handleObjectiveChange = (obj) => {
    trackObjectiveSelection(obj, false);
    setActiveObjective(obj);
    setObjectivesRowActiveObjective(obj);
    setCurrentObjectiveId(obj.objectiveId);
  };

  const isNoSee = entity?.metadata?.listMemberships?.noSee || false;
  const isLongTermLeave =
    entity?.metadata?.listMemberships?.longTermLeave || false;

  const trackObjectiveSelection = (objective, defaultLoad) => {
    trackObjectiveSelected(
      `Objective ${objective.objectiveNumber}`,
      defaultLoad,
      'HCP Page Objective Selector Bar'
    );
  };

  const { data: entityChartsConfig } =
    useModuleConfigurations<EntityChartsConfig>({
      schemaName: 'entityCharts',
      marketId,
      userGroup: profile.userGroup,
      useDefaults: true,
    });

  const objectiveBasket =
    fieldContext?.basketsByObjective?.[activeObjective?.objectiveId] ?? {};

  const showMarketOpportunityPerformanceGraphs = featureToggles(
    profile.userGroup,
    'showMarketOpportunityPerformanceGraphs'
  );

  const showMarketPerformanceGraph =
    isInternalAccount &&
    showMarketOpportunityPerformanceGraphs &&
    hasValidMarketBasket(objectiveBasket);

  const showOpportunityGraph =
    isInternalAccount &&
    showMarketOpportunityPerformanceGraphs &&
    hasValidOpportunityBasket(objectiveBasket);

  return (
    <Scrollbar
      disableTracksWidthCompensation
      className={classes.root}
      data-testid="insightsView"
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <EntityTitleBarContainer
            entity={entity}
            isEntityBusy={isEntityBusy}
            maptualScoreNormalized={
              insights?.powerscore !== undefined
                ? Math.round(insights.powerscore)
                : undefined
            }
            displayUserCreatedListsButton={displayUserCreatedListsButton}
            objectiveScores={objectiveScores}
            maptualListId={maptualListId}
          />

          {viewMultiObjectives && (
            <MultiObjectivesRow
              objectives={insightsObjectives}
              handleClick={handleObjectiveChange}
              selectedObjective={objectivesRowActiveObjective}
              applyMargins={{ top: false, bottom: true }}
            />
          )}

          {!objectiveLoaded && <SkeletonLoadingBar />}

          {isNoSee && (
            <InsightsWarningCard
              name={metadata.entityName}
              userCreatedListType="No-See"
            />
          )}
          {isLongTermLeave && (
            <InsightsWarningCard
              name={metadata.entityName}
              userCreatedListType="Long-term Leave"
            />
          )}

          <InsightsChannels
            maptualTopChannels={maptualInsights?.maptualTopChannels || []}
          />

          {featureToggles(profile.userGroup, 'showPrecallInsights') && (
            <InsightsPrecall
              entityId={entity?.metadata?.entityId}
              objective={activeObjective}
              projectId={projectId}
              regionId={maptualListId}
            />
          )}

          {featureToggles(profile.userGroup, 'showTopicsInsights') && (
            <InsightsTopics
              maptualTopTopics={maptualInsights?.maptualTopMessages || []}
            />
          )}

          {featureToggles(profile.userGroup, 'showDisbursementsInsights') && (
            <InsightsLeaveBehinds
              maptualTopLeaveBehinds={
                maptualInsights?.maptualTopDisbursements || []
              }
            />
          )}

          {featureToggles(profile.userGroup, 'showEntityActivityInsights') && (
            <InsightsActivity
              entityId={entityId}
              projectId={projectId}
              maptualListId={maptualListId}
            />
          )}

          <EntityMessages
            selectedObjective={activeObjective}
            entityId={snowflakeEntityId}
            projectId={projectId}
          />

          <CategoricalDataWrapper
            entityId={snowflakeEntityId}
            projectId={projectId}
            objectiveId={activeObjective?.objectiveId}
          />

          <InsightsCardRow objectiveCards={activeObjective?.cards || []} />

          <InsightsSegments objectiveCards={activeObjective?.cards || []} />

          {entityChartsConfig?.showProductPerformanceChart && (
            <ProductPerformanceCard
              projectId={projectId}
              regionId={maptualListId}
              objectiveId={activeObjective?.objectiveId}
              entityId={entityId}
            />
          )}

          {showMarketPerformanceGraph && (
            <MarketPerformanceCard
              projectId={projectId}
              regionId={maptualListId}
              objectiveId={activeObjective?.objectiveId}
              entityId={entityId}
            />
          )}

          {showOpportunityGraph && (
            <OpportunityCard
              projectId={projectId}
              regionId={maptualListId}
              objectiveId={activeObjective?.objectiveId}
              entityId={snowflakeEntityId}
              rdsEntityId={entityId}
            />
          )}

          {(isInternalAccount || !showMarketOpportunityPerformanceGraphs) && (
            <>
              {!isMetricScriptsMetadataLoaded || metricScriptsMetadataError ? (
                <SkeletonChart
                  title="Scripts Volume"
                  error={getScriptsErrorMessage(
                    metricScriptsMetadataError?.message
                  )}
                />
              ) : (
                <MetricScriptsChart
                  key="scripts-data"
                  metricComponentType="scriptsData"
                  activeObjective={activeObjective}
                />
              )}

              {!shareDataComplete && <SkeletonChart title="Market Share" />}
              {shareDataComplete &&
                !isEmpty(shareDataComplete) &&
                !shareDataComplete.error && (
                  <RawDataDisplay
                    key="share-data"
                    dataKey="shareData"
                    data={shareDataComplete}
                    activeObjective={activeObjective}
                  />
                )}
              {!isEmpty(shareDataComplete) && shareDataComplete.error && (
                <SkeletonChart
                  title="Market Share"
                  error={shareDataComplete.message}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Scrollbar>
  );
};
