import { useQuery } from 'react-query';
import { getEntityProductPerformanceMetadata } from '../../../../../request/projectRequests';
import { MetricData } from '../../types';

export interface Metadata {
  metricDataTypes: MetricData[];
}

export const useProductPerformanceMetadata = (
  objectiveId: string
): {
  data: Metadata;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: ['product-performance-metadata', objectiveId],
    queryFn: async () => {
      const response = await getEntityProductPerformanceMetadata(objectiveId);
      return response.data;
    },
    enabled: !!objectiveId,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
