import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  styled,
} from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import { useQuery } from 'react-query';
import { getUserGroupTestSuiteDetailedStatuses } from '../../request/projectRequests';
import { Wrapper } from '../projectviewer/projectInformation/generic';
import EmptyView from '../emptyview/emptyView';

const TestDetailAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderWidth: '1px 0px',
  '&:before': {
    display: 'none',
  },
  background: 'none',
  padding: '0 15',
}));

const TestDetailAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '5px 30px 5px 20px',
  flexDirection: 'row-reverse',
  justifyContent: 'left',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    gridGap: 10,
    maxWidth: '100%',
    overflow: 'auto',
  },
}));

const TestDetailAccordionDetails = styled(AccordionDetails)(
  ({ theme: { themeColors } }) => ({
    paddingTop: '15px',
    backgroundColor: themeColors.codeBlockBackground,
    border: `1px solid ${themeColors.contentCardBorderColor}`,
    fontSize: 14,
  })
);

const StatusIcon = ({ status }) => {
  const { colorToneMapping } = useTheme();
  const EventStatusColors = Object.freeze({
    NONE: colorToneMapping.NEUTRAL,
    OK: colorToneMapping.POSITIVE,
    ERR: colorToneMapping.NEGATIVE,
  });

  if (status === 'fail') {
    return <ErrorIcon style={{ color: EventStatusColors.ERR }} />;
  }

  if (status === 'pass') {
    return <CheckCircleIcon style={{ color: EventStatusColors.OK }} />;
  }

  return <HelpIcon style={{ color: EventStatusColors.NONE }} />;
};

const PlaceholderBox = styled(Box)(({ theme: { themeColors } }) => ({
  padding: 20,
  textAlign: 'center',
  color: themeColors.textButtonColor,
  '&.noEvents': {
    background: 'rgba(47, 48, 53, 0.4)',
    margin: 15,
    borderRadius: 5,
  },
}));

const TestDetailHeader = styled(Typography)(() => ({
  display: 'block',
  alignContent: 'center',
  flexWrap: 'wrap',
  overflow: 'auto',
  textOverflow: 'ellipsis',
  '&.url': {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CodeBlock = styled('pre')(({ theme: { themeColors } }) => ({
  fontFamily: 'Roboto, sans-serif',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  margin: 0,
  padding: 15,
  paddingTop: 0,
  color: themeColors.selectedSegmentColor,
  maxHeight: 500,
  overflowY: 'scroll',
  fontSize: 12,
}));

const getUserGroupTestSuiteStatuses = async () => {
  const userGroupStatuses = await getUserGroupTestSuiteDetailedStatuses();
  return userGroupStatuses;
};

export const TestDetailsList = () => {
  const {
    isLoading: isUserGroupTestSuiteStatusLoading,
    data: userGroupTestSuiteStatusResponse,
  } = useQuery([`user-group-quality-checks`], () =>
    getUserGroupTestSuiteStatuses()
  );

  if (isUserGroupTestSuiteStatusLoading) {
    return (
      <Wrapper data-testid="projectOverviewLoading">
        <EmptyView isLoading />
      </Wrapper>
    );
  }

  if (
    !userGroupTestSuiteStatusResponse?.data?.errorMessage &&
    userGroupTestSuiteStatusResponse?.data?.passedTestCases?.length === 0 &&
    userGroupTestSuiteStatusResponse?.data?.failedTestCases?.length === 0
  ) {
    return (
      <PlaceholderBox>
        <Typography variant="h5">No test status available.</Typography>
      </PlaceholderBox>
    );
  }

  return (
    <Box>
      {userGroupTestSuiteStatusResponse?.data?.errorMessage ? (
        <TestDetailAccordion key="errorMessage">
          <TestDetailAccordionSummary>
            <TestDetailHeader>
              {userGroupTestSuiteStatusResponse?.data?.errorMessage}
            </TestDetailHeader>
            <StatusIcon status="failure" />
          </TestDetailAccordionSummary>
        </TestDetailAccordion>
      ) : (
        <>
          {userGroupTestSuiteStatusResponse?.data?.failedTestCases?.map(
            (testCase) => (
              <TestDetailAccordion key={testCase.name}>
                <TestDetailAccordionSummary
                  expandIcon={<KeyboardArrowRightIcon />}
                >
                  <TestDetailHeader>{testCase.name}</TestDetailHeader>
                  <StatusIcon status="fail" />
                </TestDetailAccordionSummary>
                <TestDetailAccordionDetails>
                  <CodeBlock>
                    <code>{JSON.stringify(testCase.stackTrace, null, 2)}</code>
                  </CodeBlock>
                </TestDetailAccordionDetails>
              </TestDetailAccordion>
            )
          )}
          {userGroupTestSuiteStatusResponse?.data?.passedTestCases?.map(
            (name) => (
              <TestDetailAccordion key={name}>
                <TestDetailAccordionSummary>
                  <TestDetailHeader>{name}</TestDetailHeader>
                  <StatusIcon status="pass" />
                </TestDetailAccordionSummary>
              </TestDetailAccordion>
            )
          )}
        </>
      )}
    </Box>
  );
};
