
import {
    Box,
    Typography,
    Tooltip,
    styled,
  } from '@mui/material';
import { withStyles } from '@mui/styles';
  
  
  const StyledTooltip = withStyles((theme) => ({
    tooltip: {
      position: 'relative',
      top: '-7px',
      fontSize: '14px',
      fontWeight: 400,
      color: theme.themeColors.tooltipContentColor,
      backgroundColor: theme.themeColors.tooltipSurfaceColor,
    },
    arrow: {
      color: theme.themeColors.tooltipSurfaceColor,
    },
  }))(Tooltip);
  
  export const GenericTooltip = ({ title, children }) => (
    <StyledTooltip title={title} placement="bottom" enterTouchDelay={400} arrow>
      {children}
    </StyledTooltip>
  );
  
  const TooltipContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: '2px',
    padding: '4px 8px',
  }));
  
  const StyledListItem = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    padding: '4px 0px',
    textTransform: 'capitalize',
  }));
  
  export const TooltipContent = (content) => (
    <TooltipContainer>
      {content.map((value) => (
        <StyledListItem key={value.id}>{value.title}</StyledListItem>
      ))}
    </TooltipContainer>
  );
  