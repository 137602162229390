import { useState } from 'react';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import Card from '@mui/material/Card';
import { styled } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import { trackMaptualListSelection } from '../../trackers/appEventTracker';

const CustomCard = styled(Card)`
  height: auto;
  padding: 0;
  background-color: ${(props) => props.theme.themeColors.primaryColorDark};
`;

const DropDownContent = styled('div')`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
`;

const ChosenOption = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
`;

const Dropdown = ({ selectedItem, options = [], onSelectionChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const selectedOption = options?.find(
    (option) => option.maptualListId === selectedItem?.maptualListId
  );

  const handleClose = () => setAnchorEl(null);

  const handleOptionSelect = (e, option) => {
    handleClose();
    onSelectionChange(option);
    trackMaptualListSelection(option.label);
  };

  return Array.isArray(options) && options.length > 1 ? (
    <>
      <CustomCard className="intercom-sphere-region-dropdown">
        <CardActionArea onClick={(event) => setAnchorEl(event.currentTarget)}>
          <DropDownContent>
            <ChosenOption noWrap color="textPrimary" align="left">
              {selectedOption?.label}
            </ChosenOption>
            <DropDownIcon />
          </DropDownContent>
        </CardActionArea>
      </CustomCard>
      <Popover
        data-testid="segment-selection-dropdown"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div
          style={{ width: 270 }}
          className="intercom-sphere-region-dropdown-popover"
        >
          {options.map((option) => (
            <ListItemButton
              onClick={(e) => handleOptionSelect(e, option)}
              key={option.maptualListId}
              selected={option.maptualListId === selectedItem?.maptualListId}
            >
              {option.label}
            </ListItemButton>
          ))}
        </div>
      </Popover>
    </>
  ) : null;
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      maptualListId: PropTypes.string,
    })
  ),
};

export default Dropdown;
