import { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import TimelineIcon from '@mui/icons-material/Timeline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  Skeleton,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { getEntityPrecallInsights } from '../../../request/projectRequests';
import { FeedbackBox } from '../entityMessages/feedbackBox';
import toKebabCase from '../../../utils/toKebabCase';
import { trackPreCallInsightAccordionOpened } from '../../../trackers/mixpanel';
import { AppContext } from '../../../containers/application/appContext';

const MAX_INSIGHTS_COUNT = 4;

const LoadingItem = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  margin: '0 0 8px',
  backgroundColor: themeColors.mainBackground,
}));

const LoadingTitle = styled(LoadingItem)(() => ({
  height: 16,
}));

const LoadingIcon = styled(LoadingItem)(() => ({
  height: 40,
  width: 40,
  borderRadius: 40,
}));

const LoadingSubTitle = styled(LoadingItem)(() => ({
  height: 60,
}));

const InsightContainer = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  backgroundColor: themeColors.contentCardBackgroundColor,
  border: '1px solid',
  borderColor: themeColors.contentCardBorderColor,
  borderRadius: 7,
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  width: '100%',
  gap: spacing(1),
}));

const PrecallInsightsTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.menuTextColor,
  })
);

const PrecallInsightSubTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.secondaryTextColor,
  })
);

const StyledPrecallInsightList = styled(List)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  listStylePosition: 'outside',
  listStyle: 'unset',
  marginLeft: '1em',
  fontSize: 14,
}));

const MessageIconBackground = styled(Box)(({ theme: { themeColors } }) => ({
  height: 24,
  width: 24,
  borderRadius: 20,
  backgroundColor: themeColors.dataGeneralColor,
  opacity: 0.2,
}));

const InsightIcon = styled(TimelineIcon)(({ theme: { themeColors } }) => ({
  height: 16,
  width: 16,
  marginTop: 4,
  color: themeColors.dataGeneralColor,
  left: 4,
  position: 'absolute',
}));

const SparklesIconBackground = styled(Box)(({ theme: { themeColors } }) => ({
  height: 40,
  width: 40,
  borderRadius: 30,
  backgroundColor: themeColors.dataGeneralColor,
  opacity: 0.2,
}));

const SparklesIcon = styled(AutoAwesomeOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    height: 24,
    width: 24,
    marginTop: 8,
    marginLeft: 8,
    color: themeColors.anchorIconsGeneralSecondary,
    position: 'absolute',
  })
);

const StyledAccordion = styled(Accordion)(() => ({
  padding: 0,
  background: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionTitle = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1),
  alignItems: 'flex-start',
  marginBottom: spacing(1),
}));

const AccordionContent = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  textAlign: 'left',
  backgroundColor: themeColors.secondaryColor,
  gap: 11.5,
  padding: spacing(2),
  paddingTop: 0,
  width: '100%',
}));

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme: { themeColors, spacing } }) => ({
    display: 'flex',
    backgroundColor: themeColors.secondaryColor,
    border: '1px solid',
    borderColor: themeColors.borderPrimaryColor,
    borderRadius: 7,
    width: '100%',
    gap: spacing(1.5),

    '&[aria-expanded="true"]': {
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    '&:hover .accordion-title .MuiTypography-title3': {
      color: themeColors.buttonHoverContentColor,
    },
  })
);

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    border: `1px solid ${themeColors.borderPrimaryColor}`,
    borderTop: 'none',
    borderRadius: '0 0 7px 7px',
    padding: 0,
    gap: 11.5,
    width: '100%',
  })
);

const ExpandIcon = styled(ExpandMoreIcon)(() => ({
  height: 16,
  width: 16,
}));

const markdownComponents = {
  h3: (props) => (
    <PrecallInsightsTitle variant="title3">
      {props.children}
    </PrecallInsightsTitle>
  ),
  p: (props) => (
    <PrecallInsightSubTitle variant="body2">
      {props.children}
    </PrecallInsightSubTitle>
  ),
  ul: (props) => (
    <StyledPrecallInsightList variant="body2">
      {props.children}
    </StyledPrecallInsightList>
  ),
};

const InsightsPrecallAccordionItem = ({
  insight,
  objective,
  entityId,
  projectId,
  children,
}) => {
  const { projectList } = useContext(AppContext);
  const project = projectList?.find((proj) => proj.projectId === projectId);

  const onAccordionChange = (event, expanded) => {
    if (expanded) {
      trackPreCallInsightAccordionOpened({
        entityId,
        project,
        objective,
        insight,
      });
    }
  };

  return (
    <StyledAccordion disableGutters elevation={0} onChange={onAccordionChange}>
      <StyledAccordionSummary expandIcon={<ExpandIcon />}>
        <Box display="flex" position="relative" flexDirection="row" mr={1.5}>
          <MessageIconBackground />
          <InsightIcon />
        </Box>
        <Box display="flex" textAlign="left" alignItems="center">
          <ReactMarkdown
            className="accordion-title"
            components={markdownComponents}
          >
            {insight.title}
          </ReactMarkdown>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export const InsightsPrecall = ({
  entityId,
  objective,
  projectId,
  regionId,
}) => {
  const { themeColors, spacing } = useTheme();
  const { data, isLoading } = useQuery({
    queryKey: [
      'entityPrecallInsights',
      entityId,
      objective?.objectiveId,
      projectId,
      regionId,
    ],
    queryFn: async () => {
      try {
        const response = await getEntityPrecallInsights({
          entityId,
          regionId,
          projectId,
          objectiveId: objective?.objectiveId,
          entityType: 'hcp',
        });
        return response.data[0]?.insights;
      } catch {
        return null;
      }
    },
    enabled: !!entityId && !!objective?.objectiveId,
  });

  if (isLoading) {
    return (
      <InsightContainer>
        <LoadingIcon animation="wave" />
        <LoadingTitle animation="wave" />
        <LoadingSubTitle animation="wave" />
      </InsightContainer>
    );
  }

  const insightsToReturn = data?.slice(0, MAX_INSIGHTS_COUNT);

  if (!insightsToReturn) {
    return null;
  }

  return (
    <InsightContainer>
      <AccordionTitle>
        <Box display="flex" position="relative" flexDirection="row">
          <SparklesIconBackground />
          <SparklesIcon />
        </Box>
        <Typography variant="title3" color={themeColors.generalSecondaryColor}>
          Pre-Call Insights
        </Typography>
        <Typography variant="body2" color={themeColors.tertiaryColor}>
          Insights to help you prepare for your calls, powered by LLM.
        </Typography>
      </AccordionTitle>

      {insightsToReturn.map((insight, index) => (
        <InsightsPrecallAccordionItem
          key={`${entityId}-${index}`}
          insight={insight}
          objective={objective}
          entityId={entityId}
          projectId={projectId}
        >
          <AccordionContent
            className={`intercom-field-${toKebabCase(
              insight.title
            )}-insights-card`}
          >
            <ReactMarkdown components={markdownComponents}>
              {insight.description}
            </ReactMarkdown>

            <Box display="flex" width="100%" justifyContent="flex-end">
              <FeedbackBox
                insight={insight}
                objective={objective}
                entityId={entityId}
                projectId={projectId}
              />
            </Box>
          </AccordionContent>
        </InsightsPrecallAccordionItem>
      ))}

      <Box display="flex" textAlign="left" mt={spacing(1)}>
        <Typography variant="subtitle2" color={themeColors.tertiaryColor}>
          These insights are generated by a large language model (LLM). While
          the data is accurate, interpretations of the data provided by the LLM
          may not always be perfect. Your feedback helps us improve.
        </Typography>
      </Box>
    </InsightContainer>
  );
};
