import AnnualShareTable from './annualShareTable';
import HistoricalTable from '../tabbedChart/historicalTable';
import { METRIC_CHART_TAB_CADENCES } from '../tabbedChart/constants';

export default function ShareTableController({
  selectedData,
  selectedTab,
  rowSelected,
  handleRowSelect,
  metricComponentType,
  timeframeSelection,
}) {
  switch (selectedTab.value) {
    case METRIC_CHART_TAB_CADENCES.ANNUAL:
      return (
        <AnnualShareTable
          selectedData={selectedData?.dataList}
          rowSelected={rowSelected}
          onRowSelect={handleRowSelect}
          metricComponentType={metricComponentType}
          timeframeSelection={timeframeSelection}
        />
      );
    case METRIC_CHART_TAB_CADENCES.WEEKLY:
    case METRIC_CHART_TAB_CADENCES.MONTHLY:
    case METRIC_CHART_TAB_CADENCES.QUARTERLY:
      return (
        <HistoricalTable
          data={selectedData?.dataList?.listItems}
          rowSelected={rowSelected}
          onRowSelect={handleRowSelect}
          metricComponentType={metricComponentType}
          timeframeSelection={timeframeSelection}
        />
      );
    default:
      return <div>no matching table found for selected cadence</div>;
  }
}
