/* eslint-disable no-nested-ternary */

import makeStyles from '@mui/styles/makeStyles';
import { ListItemButton, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import TrendValue from '../trendValues';
import formatPercent from '../../../../utils/formatPercent';
import { trackTimeframeTableRowSelection } from '../../../../trackers/mixpanel';
import { selectMetricComponentData } from '../../../../selectors/project';

const lighten = (color) => `${color}88`;

const LegendMarker = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  aspect-ratio: 1/1;
  background-color: ${(props) => props.backgroundColor};
`;

const LegendTitle = styled(Typography)`
  font-weight: 500;
  line-height: 1.1;
  color: ${(props) => props.color};
`;

const LegendContentCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 12px 10px;
`;

const dataCellWidth = 115;
const cornerRadius = 8;
const rowHeight = 45;
const cellLeftPadding = 5;
const cellPadding = 10;
const prevDataCellWidth = 90;
const useStyles = makeStyles(({ themeColors }) => ({
  headerRow: {
    width: '100%',
    height: rowHeight,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: `1fr ${prevDataCellWidth}px repeat(2, ${dataCellWidth}px)`,
  },
  headerPrimaryText: {
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.primaryMaptualListFilteringColor,
    lineHeight: 1.2,
    wrapBreak: 'break-word',
    whiteSpace: 'normal',
  },
  headerSecondaryText: {
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.primaryMaptualListFilteringColor,
    lineHeight: 1.3,
  },
  headerCellVertical: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 5,
  },
  listContainer: {
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listInnerRow: {
    width: '100%',
    display: 'grid',
    flexGrow: 1,
    gridTemplateColumns: `1fr ${prevDataCellWidth}px repeat(2, ${dataCellWidth}px)`,
  },
  mainValueStyle: {
    fontSize: 16,
    fontWeight: 800,
    color: themeColors.primaryTextColor,
    lineHeight: 1.2,
  },
  subValueStyle: {
    fontSize: 16,
    fontWeight: 800,
    color: `${lighten(themeColors.primaryTextColor)}`,
    lineHeight: 1.1,
  },
  dataCell: {
    display: 'flex',
    padding: cellPadding,
    paddingLeft: cellLeftPadding,
  },
  innerDataWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function AnnualShareTable({
  selectedData,
  rowSelected,
  onRowSelect,
  metricComponentType,
  timeframeSelection,
}) {
  const { entityName, maptualListSegmentName, maptualListLabel } = useSelector(
    selectMetricComponentData
  );
  const { themeColors } = useTheme();
  const classes = useStyles();

  const listHeaderAugment = Array.isArray(selectedData?.listHeader)
    ? [...selectedData.listHeader]
    : null;

  const listHeader = listHeaderAugment || null;
  const listItems = selectedData?.listItems || null;

  const colourStartIndex = 0;

  if (!selectedData) return null;
  return (
    <div className={classes.listContainer}>
      <div className={classes.headerRow}>
        {Array.isArray(listHeader)
          ? listHeader.map((item, i) => (
              <div
                key={`${i}header`}
                className={classes.headerCellVertical}
                style={{
                  paddingLeft: i !== 0 ? cellLeftPadding : 0,
                  borderTopLeftRadius:
                    item.key?.toLowerCase() === 'now' ? cornerRadius : 0,
                  borderTopRightRadius:
                    item.key?.toLowerCase() === 'now' ? cornerRadius : 0,
                  justifyContent: 'flex-end',
                }}
              >
                {item.primaryText ? (
                  <Typography
                    noWrap
                    className={classes.headerPrimaryText}
                    align={item.key === 'Show' ? 'center' : 'left'}
                  >
                    {item.primaryText.toUpperCase()}
                  </Typography>
                ) : null}
                {item.secondaryText && i !== 0 ? (
                  <Typography
                    noWrap
                    className={classes.headerSecondaryText}
                    align={item.key === 'Show' ? 'center' : 'left'}
                  >
                    {item.secondaryText.toUpperCase()}
                  </Typography>
                ) : null}
              </div>
            ))
          : null}
      </div>

      {Array.isArray(listItems)
        ? listItems.map((item, i) => (
            <ListItemButton
              className={`test-share-data-list ${classes.listContainer}`}
              key={i}
              selected={rowSelected === i}
              data-testid={`test-${
                listHeader[0].primaryText.toLowerCase().split(' ')[0]
              }-data-list-item`}
              onClick={(e) => {
                onRowSelect(e, i);
                trackTimeframeTableRowSelection({
                  metricComponentType,
                  primaryLegendTitle: item.primaryData.dataSource.toUpperCase(),
                  timeframeSelectionName: timeframeSelection,
                  entityName,
                  segmentName: maptualListSegmentName,
                  maptualListSelectionLabel: maptualListLabel,
                });
              }}
              style={{
                backgroundColor:
                  rowSelected === i ? themeColors.selectedTableCellColor : '',
                borderRadius: cornerRadius,
              }}
            >
              <div className={classes.listInnerRow}>
                {typeof item.primaryData === 'object'
                  ? Object.keys(item.primaryData).map((cell, j) => {
                      const localData = item.primaryData[cell];
                      return j === 0 ? (
                        <LegendContentCell key={j}>
                          <LegendMarker
                            backgroundColor={
                              themeColors.graphLegendColors[
                                i + colourStartIndex
                              ]
                            }
                          />
                          <LegendTitle color={themeColors.primaryTextColor}>
                            {item.primaryData[cell] || null}
                          </LegendTitle>
                        </LegendContentCell>
                      ) : (
                        <div
                          className={classes.dataCell}
                          key={j}
                          style={{
                            borderBottomLeftRadius:
                              cell?.toLowerCase() === 'now' &&
                              (!item.secondaryData || rowSelected !== i) &&
                              i === listItems.length - 1
                                ? cornerRadius
                                : 0,
                            borderBottomRightRadius:
                              cell?.toLowerCase() === 'now' &&
                              (!item.secondaryData || rowSelected !== i) &&
                              i === listItems.length - 1
                                ? cornerRadius
                                : 0,
                            maxWidth:
                              item.primaryData[cell] &&
                              typeof item.primaryData[cell] === 'object'
                                ? dataCellWidth
                                : '',
                          }}
                        >
                          {typeof localData === 'object' ? (
                            <span className={classes.innerDataWrapper}>
                              {localData.value !== null &&
                              localData.value !== undefined ? (
                                <Typography
                                  data-testid="test-script-volume-data"
                                  noWrap
                                  className={classes.mainValueStyle}
                                  style={{ lineHeight: 1.2 }}
                                >
                                  {formatPercent(item.primaryData[cell].value)}
                                </Typography>
                              ) : null}
                              {localData.secondaryValue ? (
                                <TrendValue
                                  type={localData.secondaryValue.type}
                                  status={localData.secondaryValue.status}
                                  value={localData.secondaryValue.value}
                                  collapsed={rowSelected !== i}
                                />
                              ) : null}
                            </span>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>

              {rowSelected === i ? (
                <div className={classes.listInnerRow}>
                  {typeof item.secondaryData === 'object'
                    ? Object.keys(item.secondaryData).map((cell, j) => {
                        const localData = item.secondaryData[cell];
                        return j === 0 ? (
                          <LegendContentCell key={j}>
                            <LegendMarker
                              backgroundColor={lighten(
                                themeColors.graphLegendColors[
                                  i + colourStartIndex
                                ]
                              )}
                            />
                            <LegendTitle
                              color={lighten(themeColors.primaryTextColor)}
                            >
                              {localData || null}
                            </LegendTitle>
                          </LegendContentCell>
                        ) : (
                          <div
                            className={classes.dataCell}
                            key={j}
                            style={{
                              borderBottomLeftRadius:
                                cell?.toLowerCase() === 'now' &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              borderBottomRightRadius:
                                cell?.toLowerCase() === 'now' &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              maxWidth:
                                item.primaryData[cell] &&
                                typeof item.primaryData[cell] === 'object'
                                  ? dataCellWidth
                                  : '',
                            }}
                          >
                            {typeof localData === 'object' ? (
                              <span className={classes.innerDataWrapper}>
                                {localData.value !== null &&
                                localData.value !== undefined ? (
                                  <Typography
                                    data-testid="test-national-avg"
                                    noWrap
                                    className={classes.subValueStyle}
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    {formatPercent(
                                      item.secondaryData[cell].value
                                    )}
                                  </Typography>
                                ) : null}
                                {localData.secondaryValue ? (
                                  <TrendValue
                                    type={localData.secondaryValue.type}
                                    status={localData.secondaryValue.status}
                                    value={localData.secondaryValue.value}
                                    condensed={rowSelected !== i}
                                  />
                                ) : null}
                              </span>
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </div>
              ) : null}
              {rowSelected === i && item.tertiaryData ? (
                <div className={classes.listInnerRow}>
                  {typeof item.tertiaryData === 'object'
                    ? Object.keys(item.tertiaryData).map((cell, j) => {
                        const localData = item.tertiaryData[cell];
                        return j === 0 ? (
                          <LegendContentCell key={j}>
                            <LegendMarker
                              backgroundColor={lighten(
                                themeColors.quartileRowColor
                              )}
                            />
                            <LegendTitle
                              color={lighten(themeColors.primaryTextColor)}
                            >
                              {localData || null}
                            </LegendTitle>
                          </LegendContentCell>
                        ) : (
                          <div
                            className={classes.dataCell}
                            key={j}
                            style={{
                              borderBottomLeftRadius:
                                cell?.toLowerCase() === 'now' &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              borderBottomRightRadius:
                                cell?.toLowerCase() === 'now' &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              maxWidth:
                                item.primaryData[cell] &&
                                typeof item.primaryData[cell] === 'object'
                                  ? dataCellWidth
                                  : '',
                            }}
                          >
                            {typeof localData === 'object' ? (
                              <span className={classes.innerDataWrapper}>
                                {localData.value !== null &&
                                localData.value !== undefined ? (
                                  <Typography
                                    data-testid="test-national-avg"
                                    noWrap
                                    className={classes.subValueStyle}
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    {formatPercent(
                                      item.tertiaryData[cell].value
                                    )}
                                  </Typography>
                                ) : null}
                                {localData.secondaryValue ? (
                                  <TrendValue
                                    type={localData.secondaryValue.type}
                                    status={localData.secondaryValue.status}
                                    value={localData.secondaryValue.value}
                                    condensed={rowSelected !== i}
                                  />
                                ) : null}
                              </span>
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </div>
              ) : null}
            </ListItemButton>
          ))
        : null}
    </div>
  );
}
