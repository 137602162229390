import { useQuery } from 'react-query';
import { getEntityProductPerformanceData } from '../../../../../request/projectRequests';
import {
  BASKET_TYPE,
  METRIC_DATA_TYPE,
  SHORT_FORM_CADENCE,
} from '../../constants';

interface ProductPerformanceDataParams {
  productLineId: string;
  entityId: string;
  projectId: string;
  regionId: string;
  objectiveId: string;
  cadence: SHORT_FORM_CADENCE;
  metric: string;
  metricDataType: METRIC_DATA_TYPE;
}

export interface Trend {
  value: number;
  type: string;
  status: number;
}

export interface ChartValue {
  data: number;
  trend: Trend;
  timestamp: string;
}

export interface ChartLineData {
  chartValues: ChartValue[];
  label: string;
  basketType: BASKET_TYPE;
}

export interface Data {
  data: ChartLineData[];
  title: string;
}

export const useProductPerformanceData = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  metricDataType,
  metric,
  cadence,
}: ProductPerformanceDataParams): {
  data: Data;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: [
      'product-performance-data',
      projectId,
      regionId,
      objectiveId,
      entityId,
      metricDataType,
      metric,
      cadence,
    ],
    queryFn: async () => {
      const response = await getEntityProductPerformanceData(
        projectId,
        regionId,
        objectiveId,
        entityId,
        metricDataType,
        metric,
        cadence
      );

      return response.data;
    },
    enabled: !!(
      projectId &&
      regionId &&
      objectiveId &&
      entityId &&
      metricDataType &&
      metric &&
      cadence
    ),
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
