import {
  styled,
  Box,
  Button,
  Switch as MuiSwitch,
  Typography,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import { SegmentEditProvider } from './segmentEditProvider';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../constants';

const StyledSegmentContainer = styled(Box)({
  flex: 1,
  padding: 24,
  textAlign: 'left',
  overflowY: 'scroll',
});

const StyledSegmentName = styled(Typography)({
  fontWeight: 500,
});

const StyledSection = styled(Box)({
  margin: '16px 0',
});

const StyledPrimaryText = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  margin: '24px 0 4px',
});

const StyledSecondaryText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    color: themeColors.neutral60,
    margin: '4px 0',
  })
);

const StyledGeneratorPrimaryText = styled(StyledPrimaryText)({
  marginBottom: 8,
});

const StyledToggleSection = styled(StyledSection)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '24px 0',
  gap: 10,
  '.MuiTypography-root:first-child': {
    marginTop: 0,
  },
  '.MuiTypography-root:last-child': {
    marginBottom: 0,
  },
});

const StyledMaptualListFields = styled(Box)({
  marginTop: '-16px',
});

const HeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledEditButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:hover': {
    color: themeColors.adminCardHeader,
    cursor: 'pointer',
  },
}));

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const StyledEditIcon = styled(ModeIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '16px',
  marginLeft: '4px',
}));

const ButtonWrapper = styled('div')({});
const GeneratorSection = ({ title, generator }) =>
  generator ? (
    <StyledSection>
      <StyledGeneratorPrimaryText>{title}</StyledGeneratorPrimaryText>
      {Object.entries(generator)
        .filter(
          // eslint-disable-next-line no-unused-vars
          ([_, value]) =>
            value !== null &&
            value !== '' &&
            !(Array.isArray(value) && value.length === 0) &&
            !(typeof value === 'object' && Object.keys(value).length === 0)
        )
        .map(([key, value]) => (
          <StyledSecondaryText key={key}>
            {key}: {JSON.stringify(value)}
          </StyledSecondaryText>
        ))}
    </StyledSection>
  ) : null;

const ToggleSection = ({ title, description, value }) => (
  <StyledToggleSection>
    <Box>
      <StyledPrimaryText>{title}</StyledPrimaryText>
      <StyledSecondaryText>{description}</StyledSecondaryText>
    </Box>
    <Box>
      <MuiSwitch checked={value} disabled />
    </Box>
  </StyledToggleSection>
);

export function SegmentViewContainer({
  selectedSegment,
  isParentSegment,
  segmentOrders,
  rawSegments,
  setSegments,
  editView,
  setEditView,
}) {
  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);

  if (editView)
    return (
      <SegmentEditProvider
        selectedSegment={selectedSegment}
        segmentOrders={segmentOrders}
        rawSegments={rawSegments}
        setSegments={setSegments}
        setEditView={setEditView}
      />
    );
  return (
    <StyledSegmentContainer>
      <HeaderWrapper>
        <StyledSegmentName>{selectedSegment.primaryText}</StyledSegmentName>
        {!isParentSegment && hasConfigAdminPermissions && (
          <ButtonWrapper>
            <StyledEditButton
              onClick={() => {
                setEditView(true);
              }}
            >
              <SecondaryText variant="body2">Edit</SecondaryText>
              <StyledEditIcon />
            </StyledEditButton>
          </ButtonWrapper>
        )}
      </HeaderWrapper>

      {isParentSegment ? (
        <>
          <StyledSection>
            <StyledSecondaryText>
              Primary Text: {selectedSegment.primaryText}
            </StyledSecondaryText>
            <StyledSecondaryText>
              Secondary Text: {selectedSegment.secondaryText}
            </StyledSecondaryText>
            <StyledSecondaryText>
              Icon: {selectedSegment.icon}
            </StyledSecondaryText>
          </StyledSection>

          <ToggleSection
            title="isHiddenInSphere"
            description="When selected, segment will only be shown in Field"
            value={selectedSegment.isHiddenInSphere}
          />
        </>
      ) : (
        <>
          <StyledSection>
            <StyledSecondaryText>
              Segment ID: {selectedSegment.segmentId}
            </StyledSecondaryText>
            <StyledSecondaryText>
              Date Created: {selectedSegment.segmentCreatedDate}
            </StyledSecondaryText>
            <StyledSecondaryText>
              Last Updated: {selectedSegment.segmentLastUpdated}
            </StyledSecondaryText>
          </StyledSection>

          <StyledSection>
            <StyledSecondaryText>
              ProductLineID: {selectedSegment.productLineId}
            </StyledSecondaryText>
            <StyledSecondaryText>
              IntercomID: {selectedSegment.intercomId}
            </StyledSecondaryText>
          </StyledSection>

          <StyledSection>
            <StyledSecondaryText>
              Primary Text: {selectedSegment.primaryText}
            </StyledSecondaryText>
            <StyledSecondaryText>
              Secondary Text: {selectedSegment.secondaryText}
            </StyledSecondaryText>
            <StyledSecondaryText>
              Description: {selectedSegment.description}
            </StyledSecondaryText>
          </StyledSection>

          <>
            <ToggleSection
              title="Maptual List"
              description="Show segment chip on HCP cards on the Maptual List"
              value={selectedSegment.maptualList}
            />
            {selectedSegment.maptualList && (
              <StyledMaptualListFields>
                <StyledSecondaryText>
                  Label: {selectedSegment.maptualList.label}
                </StyledSecondaryText>
                <StyledSecondaryText>
                  Order: {selectedSegment.maptualList.order}
                </StyledSecondaryText>
              </StyledMaptualListFields>
            )}
          </>
          <ToggleSection
            title="isHiddenInSphere"
            description="When selected, segment will only be shown in Field"
            value={selectedSegment.isHiddenInSphere}
          />
          <ToggleSection
            title="isRescoringInclusive"
            description="Generate PowerScore for an HCP that belongs to this segment"
            value={selectedSegment.isRescoringInclusive}
          />

          <GeneratorSection
            title="Segment Activity Generator"
            generator={selectedSegment.segmentActivityGenerator}
          />
          <GeneratorSection
            title="Segment Label Generator"
            generator={selectedSegment.segmentLabelGenerator}
          />
          <GeneratorSection
            title="Project Segment Label Generator"
            generator={selectedSegment.projectSegmentLabelGenerator}
          />
        </>
      )}
    </StyledSegmentContainer>
  );
}
