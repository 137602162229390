import {
  EntityTableCustomerSegmentCell,
  EntityTableBehaviouralSegmentCell,
} from './tableCell/EntityTableSegmentCell';
import { EntityTableNameCell } from './tableCell/EntityTableNameCell';
import { EntityTableTableDefaultCell } from './tableCell/EntityTableDefaultCell';
import { EntityTableScoreCell } from './tableCell/EntityTableScoreCell';
import { TABLE_HEADER_TYPES_ENUM } from '../constants';
import { EntityTableDrawerCell } from './tableCell/EntityTableDrawerCell';

export const EntityTableCell = ({
  row,
  type,
  graph,
  chartLabels,
  label,
  subLabel,
  chartTitlePrefix,
}) => {
  const { data, trend } = row;

  const props = {
    data,
    trend,
    graph,
    chartLabels,
    label,
    subLabel,
    chartTitlePrefix,
  };

  switch (type) {
    case TABLE_HEADER_TYPES_ENUM.LABEL:
      return (
        <EntityTableNameCell entityName={data?.name} address={data?.address} />
      );

    case TABLE_HEADER_TYPES_ENUM.SCORE:
      return (
        <EntityTableScoreCell
          maptualDisplayScore={data?.maptualDisplayScore}
          maptualScoreChange={data?.maptualScoreChange}
        />
      );

    case TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE:
    case TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT:
      return <EntityTableCustomerSegmentCell data={data} />;

    case TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT:
      return <EntityTableBehaviouralSegmentCell data={data} />;

    case TABLE_HEADER_TYPES_ENUM.DRAWER:
      return <EntityTableDrawerCell />;

    default:
      return <EntityTableTableDefaultCell type={type} {...props} />;
  }
};
