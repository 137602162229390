import { useQuery } from 'react-query';
import { getCustomerProjectTemplates, getMarkets } from './newProjectRequests';
import { getCustomerProductTrees, getProducts } from './configViewRequests';
import { getUserPermissions } from './userManagementRequests';
import { getCurrentUser } from './requests';
import { trackUser } from '../trackers/appEventTracker';

export const queryKeys = {
  markets: 'markets',
  products: 'products',
  productTrees: 'productTrees',
  projectTemplates: 'projectTemplates',
  userPermissions: 'userPermissions',
  userProfile: 'user-profile',
  moduleConfigSchemas: 'moduleConfigSchemas',
  moduleConfigSchema: 'moduleConfigSchema',
  moduleConfigValues: 'moduleConfigValues',
  moduleConfigSchemaValues: 'moduleConfigSchemaValues',
  customers: 'customers',
  configMarkets: 'configMarkets',
};

export const useMarketsQuery = (options = {}) =>
  useQuery(
    queryKeys.markets,
    async () => {
      const response = await getMarkets();
      return JSON.parse(response.data);
    },
    options
  );

export const useProductsQuery = (options = {}) =>
  useQuery(
    queryKeys.products,
    async () => {
      const response = await getProducts();
      return response.data;
    },
    options
  );

export const useProductTreesQuery = (options = {}) =>
  useQuery(
    queryKeys.productTrees,
    async () => {
      const response = await getCustomerProductTrees();
      return response.data;
    },
    options
  );

export const useProductTemplatesQuery = (options = {}) =>
  useQuery(
    queryKeys.projectTemplates,
    async () => {
      const response = await getCustomerProjectTemplates();
      return response.data;
    },
    options
  );

export const usePermissionsQuery = (options = {}) =>
  useQuery(
    queryKeys.userPermissions,
    async () => {
      const response = await getUserPermissions();
      return response.data;
    },
    options
  );

const getUserProfile = async () => {
  const response = await getCurrentUser();
  trackUser(response.data.userId);
  return response.data;
};

export const useCurrentUserQuery = (options = {}) =>
  useQuery(queryKeys.userProfile, getUserProfile, options);
