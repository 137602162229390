import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../request/config';

export const useEntityRuleInsights = ({ regionId, project, objectiveId }) =>
  useQuery({
    queryKey: ['entity-rule-insights', regionId, objectiveId],
    queryFn: () => getEntityRuleInsights(project, regionId, objectiveId),
    enabled: !!regionId,
  });

export const getEntityRuleInsights = (project, regionId, objectiveId) =>
  maptualApiInstance.get<Record<string, unknown>>(
    `core/product-lines/${project?.metadata?.productLineId}/projects/${project?.metadata?.projectId}/regions/${regionId}/objectives/${objectiveId}/entities/insights`,
    {
      timeout: 10000,
    }
  );
