import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const CloseButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  width: 'fit-content',
  justifySelf: 'right',
  alignSelf: 'flex-start',
  color: themeColors.projectModalTextColor,
}));

const ModalOuter = styled(Modal, {
  shouldForwardProp: (prop) => prop !== 'positionTop',
})(({ positionTop }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: positionTop ? 'flex-start' : 'center',
  marginTop: positionTop ? 30 : 0,
  justifyContent: 'center',
  colorScheme: 'dark',
}));

const ModalContent = styled('div')(({ theme: { themeColors } }) => ({
  width: '50%',
  maxWidth: 720,
  maxHeight: '80%',
  height: 'fit-content',
  backgroundColor: themeColors.modalBackgroundColor,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: themeColors.borderLowContrast,
  boxShadow: '0px 16px 70px rgba(0, 0, 0, 0.5)',
  borderRadius: 3,
}));

const ModalErrorMsg = styled('p')(({ theme: { colorToneMapping } }) => ({
  display: 'flex',
  color: colorToneMapping.NEGATIVE,
}));

export const ModalBody = styled('div')(({ theme, notScrollable }) => ({
  flexGrow: 1,
  overflowY: notScrollable ? 'none' : 'auto',
  padding: 16,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gridGap: 32,
  borderBottom: '1px solid',
  borderColor: theme.themeColors.borderLowContrast,
  colorScheme: theme.themeColors.colorScheme,
}));

export const ModalFooter = styled('div')(() => ({
  padding: 20,
  textAlign: 'right',
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  gridGap: 10,
  '.MuiButton-outlined': {
    lineHeight: 1.75,
    padding: '7px 16px',
  },
}));

const Header = styled('div')((props) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  position: 'sticky',
  borderBottom: props.shouldShowBottomBorder ? '1px solid' : 'none',
  borderColor: props.theme.themeColors.borderLowContrast,
}));

const StyledHeaderText = styled(Typography)(({ theme: { themeColors } }) => ({
  flexGrow: 1,
  color: themeColors.offWhiteFontColor,
  fontSize: 20,
}));

export const StyledCloseIcon = styled(CloseIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
  })
);

export const ModalHeader = ({
  onClose,
  variant = 'h3',
  children,
  shouldShowBottomBorder = true,
  shouldShowXIcon = true,
}) => (
  <Header shouldShowBottomBorder={shouldShowBottomBorder}>
    <StyledHeaderText variant={variant}>{children}</StyledHeaderText>
    {shouldShowXIcon && (
      <CloseButton
        edge="start"
        color="inherit"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
        aria-label="Close"
        size="small"
      >
        <StyledCloseIcon />
      </CloseButton>
    )}
  </Header>
);
export const ModalError = ({ children }) => (
  <ModalErrorMsg>
    <ErrorOutlineIcon sx={{ marginRight: 2 }} />
    {children}
  </ModalErrorMsg>
);

export const ModalDisplay = ({
  onClose,
  isOpen,
  children,
  style = null,
  positionTop = false,
}) => (
  <ModalOuter
    open={isOpen}
    onClose={onClose}
    positionTop={positionTop ? 1 : 0}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    aria-label="modal display"
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <ModalContent style={style}>{children}</ModalContent>
  </ModalOuter>
);
