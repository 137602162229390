import { useQuery } from 'react-query';
import * as requests from '../../../request/projectRequests';
import useMaptualContext from '../../../hooks/useMaptualContext';
import { getDemoAccountStatus } from '../../../request/config';

const getCacheKey = (object) => Object.values(object);

export const censorConnectionsResponse = (connections) => {
  if (!getDemoAccountStatus()) return connections;

  return connections.map((connectionEntity) => ({
    ...connectionEntity,
    primaryText: `Entity Name ${connectionEntity.entityId}`,
  }));
};

export default function useConnectionsData(extraParams = {}) {
  const { entityId, maptualListId, projectId } = useMaptualContext();

  const params = {
    key: 'connections',
    entityId,
    maptualListId,
    projectId,
  };

  return useQuery(
    getCacheKey(params),
    async () => {
      const response = await requests.getConnections(params);

      return censorConnectionsResponse(response.data?.connections);
    },
    { ...extraParams }
  );
}
