import { useEffect, useCallback, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ErrorIcon from '@mui/icons-material/Error';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material';
import EntityListItem from '../entityListItem';
import { searchOptions } from '../constants';
import CsvDownloadButton from '../csvDownloadButton/csvDownloadButton';
import { trackSegmentFilterSelection } from '../../../trackers/mixpanel';
import { deleteFilterFromFilters } from '../entitySelection/segmentPopover';
import { useRelevantUserSpecialties } from '../../../hooks/useRelevantUserSpecialties';
import { parseDateStringWithResolution } from '../../../utils/dateParser';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';

const rowHeight = 125;

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    flexGrow: 2,
    width: '100%',
    colorScheme: themeColors.colorScheme,
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingMessage: {
    fontSize: 12,
    color: themeColors.secondaryTextColor,
    maxWidth: 100,
    marginTop: 5,
  },
  loadingIcon: {
    width: 30,
    height: 30,
    color: themeColors.secondaryTextColor,
  },
  noMatchingFiltersMessage: {
    color: themeColors.primaryMaptualListFilteringColor,
    paddingLeft: 34,
    paddingRight: 26,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  numberResults: {
    marginLeft: 16,
    marginTop: 12,
    marginBottom: 9,
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 16,
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));

const ChipsGroup = styled('div')(() => ({
  marginLeft: 16,
  marginTop: 3,
  marginBottom: 15,
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  maxHeight: 152,
  overflow: 'auto',
}));

const StyledChip = styled(Chip)(({ theme: { themeColors } }) => ({
  background:
    'linear-gradient(0deg, rgba(68, 83, 106, 0.22), rgba(68, 83, 106, 0.22))',
  backgroundColor: themeColors.mainBackground,
  border: '1px solid ',
  borderColor: themeColors.borderLowContrast,
  borderRadius: '20px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
}));

const ResultsItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
}));

const NoResultsTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
}));

const noResultsTypography = (selectedUserCreatedList) => {
  if (selectedUserCreatedList) {
    return (
      <NoResultsTextContainer>
        <Typography variant="body1">
          Sorry, no HCPs found. Your list may be empty or we couldn't find any
          that matched your search or filters.
        </Typography>
        <Typography display="block" variant="body1">
          Try adding HCPs to your list, removing a filter, or a different search
          term.
        </Typography>
      </NoResultsTextContainer>
    );
  }
  return (
    <NoResultsTextContainer>
      <Typography variant="body1">
        Sorry, we couldn't find any HCPs that matched your search or filters.
      </Typography>
      <Typography display="block" variant="body1">
        Try removing a filter, changing segments, or a different search term.
      </Typography>
    </NoResultsTextContainer>
  );
};

export default function EntityList({
  entityList,
  listScope,
  isMaptualListBusy,
  selectedId,
  selectedSegment,
  onSelect,
  formatMixpanelFilters,
  selectSortingOption,
  maptualListFilters = {},
  setMaptualListFilters,
  projectId = '',
  maptualListId = '',
  subSegments = [],
  profileRole = {},
  searchTerm = '',
  segment = '',
  selectedUserCreatedList = null,
}) {
  const classes = useStyles();
  const relevantUserSpecialties = useRelevantUserSpecialties();

  const { maptualList } = useContext(FieldContext);

  const territoriesIdLabelMap = maptualList?.metadata?.childEntities;
  const maptualListName = maptualList?.metadata?.listName;
  const entityItems = entityList ? entityList.items : [];
  const entityHash = `${entityList?.entityType || 0}${
    Array.isArray(entityList?.items) ? entityList.items.length : 0
  }`;
  const selectedIndex = entityItems.findIndex(
    (item) => String(item.entityId) === String(selectedId)
  );

  const filterSettings = {
    segment: {
      isMultiselect: true,
      maxSelectionCount: 2,
    },
    specialties: {
      isMultiselect: true,
      maxSelectionCount: relevantUserSpecialties.length,
    },
    territories: {
      isMultiselect: true,
      maxSelectionCount: territoriesIdLabelMap?.length || -1,
    },
  };

  const handleChipDelete = ({ type, value }) => {
    const newFilters = { ...maptualListFilters };
    const newFilterConfig = { ...maptualListFilters[type] };

    if (filterSettings[type]?.isMultiselect) {
      delete newFilterConfig[value];

      if (!Object.keys(newFilterConfig).length > 0) {
        delete newFilters[type];
      } else {
        newFilters[type] = newFilterConfig;
      }
    } else {
      delete newFilters[type];
    }

    trackSegmentFilterSelection(
      maptualListName,
      selectedUserCreatedList || segment,
      'Remove Chip',
      formatMixpanelFilters(newFilters)
    );

    setMaptualListFilters(newFilters);
  };

  const [listComponent, setListComponent] = useState(0);
  const listComponentRef = useCallback((component) => {
    if (component !== null) {
      setListComponent(component);
    }
  }, []);

  useEffect(() => {
    if (selectedIndex && listComponent) {
      listComponent.scrollToItem(selectedIndex);
    }
  }, [entityItems, selectedIndex, listComponent]);

  const segmentHasRecent = !!subSegments?.find(
    (item) => item.type === 'recent'
  );
  const segmentHasPredicted = !!subSegments?.find(
    (item) => item.type === 'predicted'
  );

  const isSegmentFilterSelected =
    Object.keys(maptualListFilters?.segment || []).length === 1;

  useEffect(() => {
    selectSortingOption(searchOptions.byPowerScore);

    if (!segmentHasRecent && !segmentHasPredicted && isSegmentFilterSelected) {
      setMaptualListFilters(
        deleteFilterFromFilters(maptualListFilters, 'segment')
      );
    }
  }, [selectedSegment]);

  // expects index number from list, aka 1 to highlight second item
  // currently gets entityId, aka '5564'
  const itemData = {
    entityItems,
    selected: selectedIndex,
    onSelect,
    rowHeight,
    selectedSegment,
    isSegmentIconVisible:
      segmentHasRecent && segmentHasPredicted && !isSegmentFilterSelected,
  };

  const hasPermissionToDownload =
    projectId && maptualListId && profileRole?.permission.ALLOW_CSV_DOWNLOAD;

  const noMatchingFilters = !isMaptualListBusy && entityItems.length === 0;

  const displayMaptualList = !isMaptualListBusy && entityItems.length > 0;

  const entityListClassName = 'intercom-field-power-list';

  //= =============== Using Virtualizing Components to support large quantity of list items ==================
  return (
    <>
      <div className={classes.numberResults}>
        {listScope ? (
          <>
            <ResultsItem
              data-testid="test-number-results"
              className="intercom-field-power-list-results"
            >
              <Typography variant="subtitle1">
                {(Array.isArray(entityItems)
                  ? entityItems.length
                  : 0
                ).toString()}{' '}
                {entityItems.length === 1 ? 'result' : 'results'}{' '}
                {searchTerm ? `for "${searchTerm}"` : null}
              </Typography>
              {hasPermissionToDownload ? (
                <CsvDownloadButton
                  projectId={projectId}
                  maptualListId={maptualListId}
                />
              ) : null}
            </ResultsItem>
            {entityList?.dateCreated && (
              <ResultsItem data-testid="test-date-of-results">
                <Typography variant="subtitle1">
                  Last updated:{' '}
                  {parseDateStringWithResolution(
                    Date.parse(entityList?.dateCreated) / 1000,
                    'f'
                  )}
                </Typography>
              </ResultsItem>
            )}
          </>
        ) : null}
      </div>

      {Object.keys(maptualListFilters).length !== 0 ? (
        <ChipsGroup data-testid="maptual-list-filter-chips">
          {Object.entries(maptualListFilters).map(([filter, filterConfig]) => {
            if (
              filterSettings[filter]?.isMultiselect &&
              Object.keys(filterConfig).length !==
                filterSettings[filter].maxSelectionCount
            ) {
              return Object.values(filterConfig).map((val) => (
                <StyledChip
                  key={`${filter}-${val.id}`}
                  label={<Typography variant="body2">{val.label}</Typography>}
                  variant="outlined"
                  onDelete={() =>
                    handleChipDelete({ type: filter, value: val.id })
                  }
                />
              ));
            }
            if (filterConfig.label) {
              return (
                <StyledChip
                  key={filter}
                  label={
                    <Typography variant="body2">
                      {filterConfig.label}
                    </Typography>
                  }
                  variant="outlined"
                  onDelete={() =>
                    handleChipDelete({
                      type: filter,
                      value: filterConfig.label,
                    })
                  }
                />
              );
            }
            return null;
          })}
        </ChipsGroup>
      ) : null}

      {isMaptualListBusy && (
        <Fade in timeout={400} key="loading">
          <div className={classes.loading}>
            <CircularProgress />
            <Typography
              className={classes.loadingMessage}
              color="textSecondary"
            >
              {`Loading ${listScope || ''} List...`}
            </Typography>
          </div>
        </Fade>
      )}

      {noMatchingFilters && (
        <Fade in timeout={400} key="emptyList">
          <div className={classes.noMatchingFiltersMessage}>
            <div>{noResultsTypography(selectedUserCreatedList)}</div>
            <div />
          </div>
        </Fade>
      )}

      {displayMaptualList && (
        <Fade in timeout={600} key={entityHash}>
          <div className={classes.root}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className={`test-maptual-list ${entityListClassName}`}
                  ref={listComponentRef}
                  height={height}
                  width={width}
                  itemCount={entityItems.length}
                  itemSize={rowHeight}
                  itemData={itemData}
                >
                  {EntityListItem}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </Fade>
      )}

      {!isMaptualListBusy && !Array.isArray(entityItems) && (
        <Fade in timeout={400} key="notAvailable">
          <div className={classes.loading}>
            <ErrorIcon className={classes.loadingIcon} />
            <Typography
              className={classes.loadingMessage}
              color="textSecondary"
            >
              Sorry, No Content Available.
            </Typography>
          </div>
        </Fade>
      )}
    </>
  );
}
