import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Fade, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { isEmpty, noop } from 'lodash';
import DropdownSelections from './dropdownSelections';
import FullDataListTable from './fullDataListTable';
import DataMultilineSeries from './dataMultilineSeries';
import { trackSphereAttributionChannelSelection } from '../../../trackers/mixpanel';
import useSphereContext from '../../headoffice/useSphereContext';
import {
  getHiddenRowTitles,
  getShowingRowTitles,
} from '../../headoffice/helpers';
import MetricScriptsChart from './metricScriptsChart';
import TabbedShareChart from './shareTabbedChart/tabbedShareChart';
import toKebabCase from '../../../utils/toKebabCase';
import { filterMetricData } from './tabbedChart/utils';
import useMaptualContext from '../../../hooks/useMaptualContext';
import { APPVIEWS } from '../../../constants';

const Root = styled(Box)(({ theme: { insightContentCardStyle, palette } }) => ({
  ...insightContentCardStyle(`${palette.mode}Theme`),
}));

const DashBoardChart = styled(Box)({
  width: '100%',
  marginBottom: 16,
});

const TitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 24,
  fontWeight: 600,
  color: themeColors.primaryTextColor,
  marginTop: 20,
  marginLeft: 15,
  whiteSpace: 'normal',
}));

const SubTitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: themeColors.secondaryTextColor,
  marginTop: 5,
  marginLeft: 15,
}));

const componentTypeMap = {
  SHARE_DATA: 'shareData',
  SCRIPTS_DATA: 'scriptsData',
  TOTAL_SCRIPTS_DATA: 'scriptsData',
  AVERAGE_SCRIPTS_DATA: 'scriptsData',
  ATTRIBUTION_DATA: 'attributionData',
  shareData: 'shareData',
  scriptsData: 'scriptsData',
  attributionData: 'attributionData',
};

const sortMetadataByNonscoring = (a, b) => {
  if (a.nonScoringObjective && !b.nonScoringObjective) {
    return 1;
  }
  if (!a.nonScoringObjective && b.nonScoringObjective) {
    return -1;
  }
  return 0;
};

export default function RawDataDisplay({
  dataKey,
  data,
  entityName = '',
  style = {},
  onDatasetSelected = noop,
  activeObjective = {},
}) {
  const sphereContext = useSphereContext();
  const { appView, isStandalonePage } = useMaptualContext();
  const { themeColors } = useTheme();
  const componentType = componentTypeMap[dataKey];
  const [selected, setSelected] = useState(0);
  const [rowSelected, setRowSelected] = useState(0);
  const [rowsHidden, setRowHidden] = useState(
    componentType === 'attributionData' ? {} : null
  );

  if (isEmpty(data)) return null;

  if (componentType === 'scriptsData') {
    return (
      <MetricScriptsChart
        data={data}
        metricComponentType={componentType}
        style={style}
      />
    );
  }

  if (componentType === 'shareData') {
    const filteredObjectiveData =
      appView === APPVIEWS.FIELD || isStandalonePage
        ? filterMetricData(data, activeObjective).sort(sortMetadataByNonscoring)
        : data;
    return filteredObjectiveData.length > 0 ? (
      <TabbedShareChart
        data={filteredObjectiveData}
        style={style}
        metricComponentType={componentType}
      />
    ) : null;
  }

  const handleSelect = (event, index) => {
    if (index !== selected && index < data.length && index >= 0) {
      onDatasetSelected(data[index]);
      setSelected(index);
      setRowSelected(0);
    }
  };

  const handleRowSelect = (event, index) => {
    if (index !== rowSelected) {
      setRowSelected(index);
    }
  };

  const handleRowsHidden = (index) => {
    if (index) {
      const { listItems } = data[selected].dataList;

      let trackRowsHidden = {};

      if (rowsHidden[index]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [index]: remove, ...newRowsHidden } = rowsHidden;
        setRowHidden(newRowsHidden);
        trackRowsHidden = { ...newRowsHidden };
      } else {
        const newRowsHidden = { ...rowsHidden, [index]: true };
        setRowHidden(newRowsHidden);
        trackRowsHidden = { ...newRowsHidden };
      }

      const disengagedChannels = getHiddenRowTitles(listItems, trackRowsHidden);
      const engagedChannels = getShowingRowTitles(listItems, trackRowsHidden);

      trackSphereAttributionChannelSelection({
        segmentName: sphereContext.segmentName,
        maptualListSelectionLabel: sphereContext.maptualListSelectionLabel,
        dropdownSelection: data[selected]?.title,
        engagedChannels,
        disengagedChannels,
      });
    }
  };

  function shouldRender(selectedData) {
    return !!selectedData;
  }

  return (
    <Fade in timeout={1200} key={dataKey}>
      <DashBoardChart style={style}>
        <Root
          className={`intercom-${toKebabCase(componentType)}-graph`}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          {Array.isArray(data) ? (
            <div
              className={`intercom-${toKebabCase(
                componentType
              )}-objective-selector`}
            >
              <DropdownSelections
                selectionName="Attribution Type"
                options={data}
                selected={selected}
                onSelect={handleSelect}
                entityName={entityName}
              />
            </div>
          ) : null}

          {Array.isArray(data) && data[selected] ? (
            <>
              <TitleText noWrap align="left">
                {data[selected].title}
              </TitleText>
              {data[selected].subTitle ? (
                <SubTitleText noWrap align="left">
                  {data[selected].subTitle}
                </SubTitleText>
              ) : null}
            </>
          ) : null}

          {data?.[selected]?.dataList?.multiTimeSeries ? (
            <DataMultilineSeries
              data={data[selected]}
              color={themeColors.graphLegendColors[rowSelected]}
              rowSelected={rowSelected}
              dropdownSelection={selected}
              rowsHidden={rowsHidden}
              customHeight={style?.customHeight}
            />
          ) : null}
          {shouldRender(data?.[selected]) ? (
            <FullDataListTable
              dropdownSelection={selected}
              selectedData={data[selected]}
              rowSelected={rowSelected}
              onRowSelect={handleRowSelect}
              rowsHidden={rowsHidden}
              onRowsHidden={handleRowsHidden}
            />
          ) : null}
        </Root>
      </DashBoardChart>
    </Fade>
  );
}

RawDataDisplay.propTypes = {
  dataKey: PropTypes.string,
  data: PropTypes.array,
  entityName: PropTypes.string,
};
