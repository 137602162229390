import { useContext } from 'react';
import { styled, Button } from '@mui/material';
import { isEqual } from 'lodash';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import ProjectModalForm from './projectModalForm';
import { ModalDisplayCustomStyle } from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';
import { DeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { PROJECT_CREATION_STEPS } from './common/constants';
import { ProjectModalMode } from './projectModalMode';
import { getProjectName } from './common/utils';
import {
  transformTemplateEntityToPayload,
  transformToTemplatePayload,
} from './newProjectTransformation';

const StyledModalBody = styled(ModalBody)({
  paddingBottom: '10%',
  gridGap: 24,
});
export default function NewProjectModal({ isOpen, productLines, closeForm }) {
  const { projectModalMode, isTemplate, activeProject, activeTemplate } =
    useContext(DeployDialogContext);
  const { setProjectCreationStep, objectives, projectConfig } =
    useContext(NewProjectContext);
  const getHeader = () => {
    switch (projectModalMode) {
      case ProjectModalMode.templateCreate:
        return 'New Project Template';
      case ProjectModalMode.templateEdit:
        return `Edit ${activeProject.projectName} Template`;
      default:
        return 'New Project';
    }
  };
  const handleClick = () => {
    setProjectCreationStep(
      isTemplate
        ? PROJECT_CREATION_STEPS.CONFIRMATION
        : PROJECT_CREATION_STEPS.SUMMARY
    );
  };

  const handleClose = () => {
    if (
      projectConfig.projectName !== getProjectName(isTemplate) ||
      projectConfig.productLineId
    ) {
      setProjectCreationStep(PROJECT_CREATION_STEPS.CLOSE_PROJECT);
    } else {
      closeForm();
    }
  };

  const getSaveButtonText = () => {
    switch (projectModalMode) {
      case ProjectModalMode.templateCreate:
        return 'Save Template';
      case ProjectModalMode.templateEdit:
        return 'Save Changes';
      default:
        return 'Next: Summary';
    }
  };

  const isNextActionDisabled = () => {
    const areRequiredFieldsMissing =
      projectConfig.projectName === '' || objectives.length === 0;

    switch (projectModalMode) {
      case ProjectModalMode.templateEdit:
        return (
          areRequiredFieldsMissing ||
          isEqual(
            transformTemplateEntityToPayload(activeTemplate),
            transformToTemplatePayload({ projectConfig, objectives })
          )
        );
      default:
        return areRequiredFieldsMissing;
    }
  };

  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader onClose={handleClose}>{getHeader()}</ModalHeader>
      <StyledModalBody>
        <ProjectModalForm productLines={productLines} />
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={isNextActionDisabled()}
          onClick={handleClick}
          variant="contained"
        >
          {getSaveButtonText()}
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
