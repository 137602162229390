/* eslint-disable no-restricted-syntax */

import { styled, Box, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { CustomTooltip } from '@odaia/ui/src/customTooltip';

export const Column = styled(Box)(
  ({
    width,
    marginBottom,
    theme: { themeColors },
    flexDirection = 'column',
  }) => ({
    display: 'flex',
    flexDirection,
    width,
    marginBottom: marginBottom ? 16 : 0,
    textAlign: 'left',
    color: themeColors.tableHeaderContentColor,
    fontSize: '14px',
    alignItems: 'start',
    gap: '4px',
  })
);

export const ColumnTitle = styled('div')((props) => ({
  color: props.theme.themeColors.tableHeaderContentColorHighlight,
  textTransform: 'capitalize',
}));

export const ColumnSubTitle = styled('div')((props) => ({
  color: props.theme.themeColors.tableHeaderContentColor,
}));

export const ColumnContainer = styled(Box)(({ paddingBottom }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 32,
  paddingBottom,
}));

export const StyledMessage = styled(Typography)(() => ({
  marginTop: '8px',
  ':first-child': {
    marginTop: 0,
  },
}));

const StickyColumnContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 48,
  position: 'sticky',
  zIndex: 1,
  backgroundColor: themeColors.cardBackgroundColor,
  height: 164,
  paddingLeft: 16,
  left: 0,
  paddingBottom: 12,
}));

export const StyledIcon = styled(ErrorOutlineOutlinedIcon)(() => ({
  width: '14px',
  height: '14px',
}));

export const MetricColumns = ({ metricColumns, objectiveBasketName }) => {
  const res = [];
  for (const column of metricColumns) {
    // eslint-disable-next-line no-continue
    if (!column.visible) continue;
    res.push(
      <Column width={column.width} key={`${column.id}-${objectiveBasketName}`}>
        <ColumnTitle>{objectiveBasketName}</ColumnTitle>
        <ColumnSubTitle>{column.sublabel}</ColumnSubTitle>
      </Column>
    );
  }
  return <ColumnContainer>{res}</ColumnContainer>;
};

export const StickyColumns = ({ cols }) => {
  const res = [];
  for (const column of cols) {
    // eslint-disable-next-line no-continue
    if (!column.visible) continue;
    res.push(
      <Column width={column.width} key={`${column.id}`} flexDirection="row">
        <ColumnSubTitle>{column.label}</ColumnSubTitle>
        {column.message && (
          <CustomTooltip
            title={column.label}
            message={column.message}
            placement="bottom-start"
          >
            <ColumnSubTitle>
              {column.subLabel} <StyledIcon />
            </ColumnSubTitle>
          </CustomTooltip>
        )}
      </Column>
    );
  }
  return <StickyColumnContainer>{res}</StickyColumnContainer>;
};

export const GenericColumns = ({ columns, paddingBottom }) => (
  <ColumnContainer paddingBottom={paddingBottom}>
    {columns.map((column) => (
      <Column width={column.width} key={column.id}>
        <ColumnTitle>{column.label}</ColumnTitle>
        {column.message && (
          <CustomTooltip
            title={column.label || column.subLabel}
            message={column.message}
            placement="bottom-start"
          >
            <ColumnSubTitle>
              <Typography>
                {column.subLabel} <StyledIcon />
              </Typography>
            </ColumnSubTitle>
          </CustomTooltip>
        )}
        {!column.message && <ColumnSubTitle>{column.subLabel}</ColumnSubTitle>}
      </Column>
    ))}
  </ColumnContainer>
);
