import { Cached } from '@mui/icons-material';
import { Box, Button, Typography, styled } from '@mui/material';

const ErrorBox = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  padding: '48px 56px',
  flexDirection: 'column',
  gap: 16,
  borderRadius: 4,
  background: themeColors.surfaceEmpty,
  alignItems: 'center',
}));

const ErrorTextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 4,
});

const ErrorText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  whiteSpace: 'pre-line',
}));

const RetryButton = styled(Button)({
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  padding: '8px 12px',
});

const RetryIcon = styled(Cached)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  '&.MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
  },
}));

interface ErrorContainerProps {
  title?: string;
  description?: string;
  handleRetry?: () => void;
}

export const ErrorContainer = ({
  title,
  description,
  handleRetry,
}: ErrorContainerProps) => (
  <ErrorBox>
    <ErrorTextContainer>
      <ErrorText variant="title3">{title ?? 'No content loaded'}</ErrorText>
      <ErrorText variant="subtitle1">
        {description ??
          'Sorry, we are having trouble loading the requested data. Please try again later.'}
      </ErrorText>
    </ErrorTextContainer>

    {handleRetry && (
      <RetryButton variant="outlined" onClick={() => handleRetry()}>
        <RetryIcon />
        Retry
      </RetryButton>
    )}
  </ErrorBox>
);
