import { useQuery } from 'react-query';
import { getEntityMarketPerformanceMetadata } from '../../../../../request/projectRequests';
import {
  CADENCE_LABEL,
  METRIC_DATA_TYPE,
  METRIC_UNIT,
  SHORT_FORM_CADENCE,
} from '../../constants';

export interface Cadence {
  id: SHORT_FORM_CADENCE;
  label: CADENCE_LABEL;
}

export interface Metric {
  cadences: Cadence[];
  rxType: string;
  unit: METRIC_UNIT;
  displayName: string;
}

export interface MetricData {
  metrics: Metric[];
  chartType: string;
  metricDataType: METRIC_DATA_TYPE;
}

export interface Metadata {
  metricDataTypes: MetricData[];
}

export const useMarketPerformanceMetadata = (
  objectiveId: string
): {
  data: Metadata;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: ['market-performance-metadata', objectiveId],
    queryFn: async () => {
      const response = await getEntityMarketPerformanceMetadata(objectiveId);
      return response.data;
    },
    enabled: !!objectiveId,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
