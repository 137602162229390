import { useContext, useMemo, useState } from 'react';
import { MapView } from '@odaia/shared-components/src/map/mapView';
import { MapViewContext } from '@odaia/shared-components/src/contexts/mapViewContext';
import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import { FieldContext } from '../fieldContext';
import { useEntityCoordinates } from './hooks/useEntityCoordinates';
import { useEntityPrecallInsights } from './hooks/useEntityPrecallInsights';
import { useEntityRuleInsights } from './hooks/useEntityRuleInsights';
import { useObjectives } from '../../shared/objectiveSelector/useObjectives';
import { MapToggle } from './mapToggle';
import { RegionSelection } from '../navigation/regionSelection/RegionSelection';
import { EntityPopover } from './entityPopover';
import { EntityFullModal } from './entityFullModal';
import { AppContext } from '../../../appContext';
import useFeatureToggles from '../../../../../hooks/useFeatureToggles';
import { useIsAuthorized } from '../../../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../../../constants';
import useRelevantUserSpecialties from '../../../../../hooks/useRelevantUserSpecialties';

export const MapViewProvider = () => {
  const [objective, setObjective] = useState<Record<string, unknown> | null>(
    null
  );
  const [objectiveIndex, setObjectiveIndex] = useState<number | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { profile } = useContext(AppContext);
  const {
    project,
    maptualListMetadata,
    projectMaptualList: maptualList,
    isMaptualListLoading,
    isMaptualListsLoading,
    maptualListObjectives,
  } = useContext(FieldContext);

  const { data: objectivesMetadata, isLoading: isObjectivesMetadataLoading } =
    useObjectives(project?.metadata?.projectId);

  const {
    data: entityCoordinatesData,
    isLoading: isLoadingEntityCoords,
    isError: isErrorEntityCoords,
  } = useEntityCoordinates({
    maptualListMetadata,
    project,
  });

  const {
    data: entityPrecallInsights,
    isLoading: isEntityPrecallInsightsLoading,
  } = useEntityPrecallInsights({
    maptualListMetadata,
    project,
    objectiveId: objective?.id,
  });

  const { data: entityRuleInsights, isLoading: isEntityRuleInsightsLoading } =
    useEntityRuleInsights({
      regionId: maptualListMetadata.maptualListId,
      project,
      objectiveId: objective?.id,
    });
  const featureToggles = useFeatureToggles();
  const canUserFilterMapView =
    featureToggles(profile.userGroup, 'mapViewFiltering') ||
    useIsAuthorized([RBAC_PERMISSIONS.MAP_VIEW_FILTERING]);
  const relevantUserSpecialties = useRelevantUserSpecialties();

  const mapContextValues = useMemo(
    () => ({
      project,
      maptualListMetadata,
      maptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      entityCoordinatesData,
      isLoadingEntityCoords,
      isErrorEntityCoords,
      objective,
      setObjective,
      objectiveIndex,
      setObjectiveIndex,
      entityPrecallInsights,
      isEntityPrecallInsightsLoading,
      entityRuleInsights: entityRuleInsights?.data,
      isEntityRuleInsightsLoading,
      objectivesMetadata,
      isObjectivesMetadataLoading,
      enqueueSnackbar,
      canUserFilterMapView,
      relevantUserSpecialties,
    }),
    [
      project,
      maptualListMetadata,
      maptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      entityCoordinatesData,
      isLoadingEntityCoords,
      isErrorEntityCoords,
      objective,
      objectiveIndex,
      entityPrecallInsights,
      isEntityPrecallInsightsLoading,
      entityRuleInsights,
      isEntityRuleInsightsLoading,
      objectivesMetadata,
      isObjectivesMetadataLoading,
      enqueueSnackbar,
      canUserFilterMapView,
      relevantUserSpecialties,
    ]
  );

  return (
    <MapViewContext.Provider value={mapContextValues}>
      <MapView
        listMapToggle={<MapToggle />}
        regionSelection={
          maptualListMetadata?.listName && (
            <RegionSelection regionName={maptualListMetadata?.listName} />
          )
        }
        EntityPopover={EntityPopover}
        EntityFullModal={EntityFullModal}
      />
    </MapViewContext.Provider>
  );
};
