import { styled, Box, Typography } from '@mui/material';

const NoTerritoryOverviewText = styled(Box)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  marginTop: 24,
  display: 'flex',
  padding: '48px 56px',
  flexDirection: 'column',
  gap: 4,
  borderRadius: 4,
  background: themeColors.surfaceEmpty,
  alignItems: 'center',
}));

const SubText = styled(Typography)(() => ({
  fontSize: 12,
}));

const disclaimerComponentMap = {
  INVALID_REGION_TITLE: (
    <Typography variant="h5"> Report not available </Typography>
  ),
  INVALID_REGION_BODY: (
    <>
      <SubText variant="body1">
        We currently only provide regional overview reports at the territory
        level.
      </SubText>
      <SubText variant="body1">
        Use the menu to navigate to a territory to view overview reports.
      </SubText>
    </>
  ),
  NO_CHILD_REGIONS_TITLE: (
    <Typography variant="h5"> Not enough data to show report </Typography>
  ),
  NO_CHILD_REGIONS_BODY: (
    <>
      <SubText variant="caption">
        We currently do not have enough data on this territory to show the
        overview report.
      </SubText>
      <SubText variant="caption">
        Use the menu to navigate to another territory to view overview reports.
      </SubText>
    </>
  ),
  NO_PRODUCT_DATA_TITLE: (
    <Typography variant="h5"> Graph and table not available </Typography>
  ),
  NO_PRODUCT_DATA_BODY: (
    <SubText variant="caption">
      We currently do not have enough data to generate a graph and table for
      this product.
    </SubText>
  ),
};

export const Disclaimer = ({ disclaimerText }) => {
  if (!disclaimerText) return null;

  return (
    <NoTerritoryOverviewText>
      <Box>{disclaimerComponentMap[`${disclaimerText}_TITLE`]}</Box>
      <Box data-testid="disclaimer-text">
        {disclaimerComponentMap[`${disclaimerText}_BODY`]}
      </Box>
    </NoTerritoryOverviewText>
  );
};
