import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { ROUTES } from '../../../../../constants';

const LIST_TYPES = { LIST: 'List', MAP: 'Map' };

const StyledToggleButton = styled(ToggleButton)(() => ({
  fontSize: 14,
  height: 34,
}));

export const MapToggle = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const selectedListType = match.url.includes(`${ROUTES.HCPS}${ROUTES.MAP}`)
    ? LIST_TYPES.MAP
    : LIST_TYPES.LIST;

  const handleListTypeChange = () => {
    if (selectedListType === LIST_TYPES.LIST) {
      history.push(`${match.url}${ROUTES.MAP}`);
    } else {
      history.push(
        `${match.url.split(`${ROUTES.HCPS}${ROUTES.MAP}`)[0]}${ROUTES.HCPS}`
      );
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedListType}
      onChange={() => handleListTypeChange()}
      aria-label="List Type"
    >
      {Object.values(LIST_TYPES).map((listType) => (
        <StyledToggleButton key={listType} value={listType} variant="contained">
          {listType}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
