import { useState } from 'react';
import { RegionSelectionButton } from '../../../shared/RegionSelectionButton';
import { RegionModalProvider } from './regionModalProvider';

export const RegionSelection = ({ regionName }) => {
  const [isRegionSelectionModalOpen, setisRegionSelectionModalOpen] =
    useState(false);
  return (
    <>
      <RegionSelectionButton
        regionName={regionName}
        onClick={() => setisRegionSelectionModalOpen(true)}
      />
      <RegionModalProvider
        isOpen={isRegionSelectionModalOpen}
        handleClose={() => setisRegionSelectionModalOpen(false)}
      />
    </>
  );
};
