import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ThemeProvider } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { theme } from '@odaia/ui/src/theme';
import {
  CHART_LINE_DASH_PATTERN,
  SHORT_FORM_CADENCE,
  TREND_STATUS,
  LINE_CHART_TOP_GAP,
  METRIC_DATA_TYPE,
  METRIC_UNIT,
} from '../constants';
import { TrendIcon } from '../../../../containers/application/appViews/shared/TrendIcon';
import {
  createTooltip,
  INITIAL_TOOLTIP_STATE,
  Tooltip,
  TooltipData,
} from '../Tooltip';
import { formatTimestampLabel, formatYAxisValue } from '../helpers';
import {
  ExtendedChartDataset,
  ChartDataset,
  ChartValue,
  Cadence,
} from '../types';

const MAX_Y_AXIS_LABEL_LENGTH = 25;

export const buildDatasets = (
  data: ExtendedChartDataset[],
  cadence: SHORT_FORM_CADENCE
): object[] => {
  if (!data || data.length === 0) return [];

  const displayableData = data.filter((lineData) => lineData.chartLineColor);

  return displayableData.map((lineData) => {
    const { chartValues, label, chartLineColor } = lineData;
    const trends = chartValues.map((value) => value.trend);

    return {
      label,
      data: buildChartData(chartValues, cadence),
      pointStyle: false,
      borderColor: chartLineColor,
      pointBackgroundColor: chartLineColor,
      trends,
      segment: cadence === SHORT_FORM_CADENCE.MAT && {
        borderDash: (ctx) => getLineDashPattern(ctx, trends.length),
        borderColor: (ctx) => getLineOpacity(ctx, trends),
      },
      timestamps: lineData.chartValues?.map((item) => item.timestamp),
      productName: lineData.label,
    };
  });
};

export const buildChartData = (
  chartValues: ChartValue[],
  cadence: SHORT_FORM_CADENCE
): number[] => {
  if (!chartValues) return [];

  if (cadence === SHORT_FORM_CADENCE.MAT) {
    const lastHistoricalDataPoint = chartValues[chartValues.length - 2].data;
    return chartValues.map((value, index) =>
      index === chartValues.length - 1 &&
      value.trend.status < TREND_STATUS.VALID_METRIC_DATA_STATUS
        ? lastHistoricalDataPoint
        : value.data
    );
  }

  return chartValues.map((value) => value.data);
};

export const getLineDashPattern = (ctx: object, dataLength: number): number[] =>
  ctx.p1DataIndex === dataLength - 1 ? CHART_LINE_DASH_PATTERN : undefined;

export const getLineOpacity = (ctx: object, trends: object[]): string => {
  const trendStatus = trends[ctx.p1DataIndex].status;
  return ctx.p1DataIndex === trends.length - 1 &&
    trendStatus === TREND_STATUS.CANNOT_PREDICT
    ? 'transparent'
    : undefined;
};

export const buildTooltipPlugin = (
  tooltip: TooltipData,
  setTooltip: Dispatch<SetStateAction<TooltipData>>,
  metricDisplayName: string,
  unit: METRIC_UNIT
): object => ({
  enabled: false,
  external: (context) => {
    createTooltip({
      context,
      currentTooltip: tooltip,
      updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
      metricDisplayName,
      unit,
    });
  },
});

export const buildAnnotationPlugin = (data, cadence) => {
  const labels = {};

  if (!data || cadence !== SHORT_FORM_CADENCE.MAT) {
    return labels;
  }

  data.forEach((lineData, i) => {
    const { chartValues, chartLineColor } = lineData;
    if (!chartValues.length || !chartLineColor.length) return;

    const lastTrend = chartValues[chartValues.length - 1].trend;
    const { status } = lastTrend || {};

    if (
      status === TREND_STATUS.INCREASING_OUT_OF_RANGE ||
      status === TREND_STATUS.DECREASING_OUT_OF_RANGE
    ) {
      const lineValues = chartValues.map(({ data }) => data);
      labels[i] = {
        type: 'label',
        content: buildTrendArrowImage(status),
        ...calculateTrendArrowPosition(lineValues, status),
      };
    }
  });

  return labels;
};

export const calculateTrendArrowPosition = (
  lineValues: number[],
  trendStatus: TREND_STATUS
): object => ({
  xValue: lineValues.length - 1.5,
  yValue: lineValues[lineValues.length - 2],
  yAdjust: trendStatus === TREND_STATUS.INCREASING_OUT_OF_RANGE ? -10 : 10,
});

export const buildTrendArrowImage = (
  trendStatus: TREND_STATUS
): HTMLImageElement => {
  const direction =
    trendStatus === TREND_STATUS.INCREASING_OUT_OF_RANGE ? 'up' : 'down';

  const arrowSvg = ReactDOMServer.renderToStaticMarkup(
    <ThemeProvider theme={theme}>
      <TrendIcon direction={direction} />
    </ThemeProvider>
  );

  const base64SVG = btoa(arrowSvg);
  const imgSrc = `data:image/svg+xml;base64,${base64SVG}`;
  const img = new Image();
  img.src = imgSrc;
  img.width = 20;
  img.height = 40;
  return img;
};

export const buildLineChartScales = (
  metricName: string,
  cadence: Cadence,
  maxValue: number,
  unit: METRIC_UNIT,
  themeColors: object
): object => {
  const metricDisplayName =
    !!metricName && metricName.length > MAX_Y_AXIS_LABEL_LENGTH
      ? `${metricName.substring(0, MAX_Y_AXIS_LABEL_LENGTH)}…`
      : metricName;
  return {
    y: {
      min: 0,
      suggestedMax: maxValue,
      border: {
        display: false,
      },
      grid: {
        drawTicks: false,
        color: themeColors.borderPrimaryColor,
        lineWidth: 1,
      },
      ticks: {
        callback: (value) => formatYAxisValue(value, unit),
        color: themeColors.tertiaryColor,
        padding: 10,
        maxTicksLimit: 6,
      },
      title: {
        display: !!metricDisplayName,
        text: metricDisplayName,
        font: 12,
        color: themeColors.tertiaryColor,
      },
    },
    x: {
      border: {
        display: false,
      },
      grid: {
        drawTicks: true,
        drawOnChartArea: false,
        color: themeColors.dividerPrimaryColor,
      },
      ticks: {
        color: (context) => {
          const { index } = context;
          const { ticks } = context.chart.scales.x;

          if (
            (cadence === SHORT_FORM_CADENCE.MAT &&
              index === ticks.length - 2) ||
            (cadence !== SHORT_FORM_CADENCE.MAT && index === ticks.length - 1)
          ) {
            return themeColors.primaryTextColor;
          }

          return themeColors.tertiaryColor;
        },
      },
    },
  };
};

const createHoverLine = (chart, strokeColor) => {
  const x = chart.tooltip.caretX;
  const { ctx } = chart;
  const topY = chart.scales.y.top;
  const bottomY = chart.scales.y.bottom;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, topY);
  ctx.lineTo(x, bottomY);
  ctx.lineWidth = 1;
  ctx.setLineDash([3, 3]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
  ctx.restore();
};

const getChartLabels = (
  chartData: ChartDataset[],
  cadence: SHORT_FORM_CADENCE
) => {
  if (!chartData || chartData.length === 0) return [];

  const firstLineData = chartData[0];
  return firstLineData.chartValues.map((value) =>
    formatTimestampLabel(value.timestamp, cadence)
  );
};

const getYAxisMaxValue = (
  data: ExtendedChartDataset[],
  unit: METRIC_UNIT
): number => {
  if (unit === METRIC_UNIT.RATE) {
    return 100;
  }

  const allValues = data.reduce(
    (acc, obj) =>
      obj.chartLineColor
        ? acc.concat(obj.chartValues.map((value) => value.data))
        : acc,
    []
  );
  return Math.max(...allValues) * LINE_CHART_TOP_GAP;
};

interface LineData {
  labels: string[];
  datasets: object[];
}

interface LineChartProps {
  selectedCadence: Cadence;
  metricName: string;
  data: ExtendedChartDataset[];
  legendPlugin: object;
  unit: METRIC_UNIT;
  dataTestId: string;
  selectedCardTab?: METRIC_DATA_TYPE;
  showLineValueLabel?: boolean;
}

export const LineChart = ({
  selectedCadence,
  metricName,
  data,
  legendPlugin,
  unit,
  dataTestId,
  selectedCardTab = null,
  showLineValueLabel = true,
}: LineChartProps) => {
  const { themeColors } = useTheme();

  const [lineData, setLineData] = useState<LineData>();
  const [tooltip, setTooltip] = useState(INITIAL_TOOLTIP_STATE);

  useEffect(() => {
    const lineData = {
      labels: getChartLabels(data, selectedCadence?.id),
      datasets: buildDatasets(data, selectedCadence?.id),
    };
    setLineData(lineData);
  }, [data, selectedCadence]);

  const maxValue = getYAxisMaxValue(data, unit);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: legendPlugin,
      tooltip: buildTooltipPlugin(tooltip, setTooltip, metricName, unit),
      annotation: {
        annotations: buildAnnotationPlugin(data, selectedCadence?.id),
      },
    },
    scales: buildLineChartScales(
      metricName,
      selectedCadence?.id,
      maxValue,
      unit,
      themeColors
    ),
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  const plugin = {
    afterDraw(chart) {
      // eslint-disable-next-line no-underscore-dangle
      if (chart?.tooltip?.caretX && chart.tooltip._active?.length) {
        createHoverLine(chart, themeColors.markerLine);
      }
    },
  };

  if (!lineData) {
    return null;
  }

  return (
    <Box position="relative" height="192px" data-testid={dataTestId}>
      <Line plugins={[plugin]} options={options} data={lineData} />
      <Tooltip
        tooltip={tooltip}
        metricName={metricName}
        selectedCardTab={selectedCardTab}
        selectedCadence={selectedCadence}
        showLineValueLabel={showLineValueLabel}
      />
    </Box>
  );
};
