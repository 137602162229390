import { useQuery } from 'react-query';
import {
  getEntityDrawerChartData,
  getEntityDrawerMetadata,
  getEntityDrawerProfile,
  getEntityDrawerTimeline,
} from '../../../../../request/entityOverviewRequests';
import { CHART_TYPES_ENUM } from '../../../../shared/graphs/constants';

export const useEntityDrawerMetadata = ({
  productLineId,
  marketId,
  projectId,
  regionId,
  entityType,
  entityId,
}) => {
  const fetchEntityDrawerMetadata = async () => {
    if (
      productLineId &&
      marketId &&
      projectId &&
      regionId &&
      entityType &&
      entityId
    ) {
      const params = {
        productLineId,
        marketId,
        projectId,
        regionId,
        entityType,
        entityId,
      };

      const response = await getEntityDrawerMetadata(params);
      return response?.data;
    }

    return null;
  };

  const { isFetching, data, isError, error } = useQuery({
    queryKey: [
      'entity-drawer-metadata',
      productLineId,
      projectId,
      regionId,
      entityType,
      entityId,
    ],
    queryFn: fetchEntityDrawerMetadata,
    cacheTime: 0,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchEntityDrawerMetadata', err);
    },
  });

  return {
    isFetching,
    data,
    isError,
    error,
  };
};

export const useEntityDrawerChartData = ({
  productLineId,
  regionId,
  objectiveId,
  chartType,
  metric,
  cadence,
  entityType,
  entityId,
}) => {
  const fetchEntityDrawerChartData = async () => {
    if (
      productLineId &&
      regionId &&
      objectiveId &&
      chartType &&
      metric &&
      entityType &&
      entityId
    ) {
      const params = {
        productLineId,
        regionId,
        objectiveId,
        chartType,
        metric,
        entityType,
        entityId,
      };

      if (chartType === CHART_TYPES_ENUM.BAR) {
        const response = await getEntityDrawerChartData(params);
        return response?.data;
      }

      const response = await getEntityDrawerChartData({
        ...params,
        cadence,
      });

      return response?.data;
    }

    return null;
  };

  const { isFetching, data, isError, error } = useQuery({
    queryKey: [
      'entity-drawer-chart-data',
      productLineId,
      regionId,
      objectiveId,
      chartType,
      metric,
      cadence,
      entityType,
      entityId,
    ],
    queryFn: fetchEntityDrawerChartData,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchEntityDrawerChartData', err);
    },
  });

  return {
    isFetching,
    data,
    isError,
    error,
  };
};

export const useEntityDrawerProfile = ({ marketId, entityType, entityId }) => {
  const fetchEntityDrawerProfile = async () => {
    if (marketId && entityType && entityId) {
      const params = {
        marketId,
        entityType,
        entityId,
      };

      const response = await getEntityDrawerProfile(params);
      return response?.data;
    }

    return null;
  };

  const { isFetching, data, isError, error } = useQuery({
    queryKey: ['entity-drawer-profile', marketId, entityType, entityId],
    queryFn: fetchEntityDrawerProfile,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchEntityDrawerProfile', err);
    },
  });

  return {
    isFetching,
    data,
    isError,
    error,
  };
};

export const useEntityDrawerTimeline = ({
  projectId,
  regionId,
  entityType,
  entityId,
}) => {
  const fetchEntityDrawerTimeline = async () => {
    if (projectId && regionId && entityType && entityId) {
      const params = {
        projectId,
        regionId,
        entityType,
        entityId,
      };

      const response = await getEntityDrawerTimeline(params);
      return response?.data;
    }

    return null;
  };

  const { isFetching, data, isError, error } = useQuery({
    queryKey: [
      'entity-drawer-timeline',
      projectId,
      regionId,
      entityType,
      entityId,
    ],
    queryFn: fetchEntityDrawerTimeline,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchEntityDrawerTimeline', err);
    },
  });

  return {
    isFetching,
    data,
    isError,
    error,
  };
};
