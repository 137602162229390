/* eslint-disable no-nested-ternary */

import Chart from 'react-apexcharts';
import { useTheme } from '@mui/styles';
import { DEFAULT_CHART_HEIGHT } from './constants';
import TrendUpIconDark from '../../../../assets/trend_up_icon_dark.svg';
import TrendDownIconDark from '../../../../assets/trend_down_icon_dark.svg';
import TrendUpIconLight from '../../../../assets/trend_up_icon_light.svg';
import TrendDownIconLight from '../../../../assets/trend_down_icon_light.svg';
import EmptyDash from '../../../../assets/em_dash.png';
import { getLegendColors } from './utils';
import { trendColor, trendDescription } from '../../../../utils/matTrends';
import { PREDICTION_PRECISION } from '../trendValues';
import formatPercent from '../../../../utils/formatPercent';

const OPTIONS = {
  dashArray: [0, 1, 1, 1],
};

const labelStyles = {
  fontSize: '12px',
  fontFamily: 'Helvetica, Arial, sans-serif',
  fontWeight: 400,
};

const MATChart = ({
  customHeight = DEFAULT_CHART_HEIGHT,
  data,
  numberFormatter,
  rowSelected,
}) => {
  const {
    series,
    matXaxis,
    primaryData,
    title: yAxisTitle,
  } = data.listItems[rowSelected];
  const { themeColors, palette } = useTheme();
  const predictionSecondaryData = primaryData.prediction.secondaryValue;
  const outsideRangePredictionDialog = {
    text: trendDescription(primaryData.prediction.secondaryValue),
    style: {
      color: themeColors.primaryTextColor,
      background: themeColors.contentCardBackgroundColor,
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      padding: {
        top: 7,
        bottom: 7,
        left: 7,
        right: 7,
      },
    },
    borderColor: trendColor(predictionSecondaryData, themeColors),
    offsetX:
      predictionSecondaryData.status === PREDICTION_PRECISION.CANNOT_PREDICT
        ? 140
        : 100,
    offsetY: 18,
  };

  const showCappedPercentage = primaryData.dataSource
    .toLowerCase()
    .includes('iz rate');

  const nowAnnotation = {
    x: matXaxis[1],
    y: series[0].data[1],
    marker: {
      size: 6,
      fillColor: themeColors.graphLegendColors[rowSelected],
      strokeColor: themeColors.graphLegendColors[rowSelected],
      radius: 2,
    },
    label:
      predictionSecondaryData.status < 0 ? outsideRangePredictionDialog : {},
    image: {
      path: iconFor('now'),
      offsetX: getImageOffset('now').x,
      offsetY: getImageOffset('now').y,
    },
  };

  const predictionAnnotation = {
    x: matXaxis[2],
    y: series[0].data.length >= 3 ? series[0].data[2] : series[0].data[1],
    marker: {
      size: undefined,
    },
    image: {
      path: iconFor('prediction'),
      offsetX: getImageOffset('prediction').x,
      offsetY: getImageOffset('prediction').y,
    },
  };

  function getImageOffset(key) {
    if (trendChangeFor(key)) return { x: -48, y: -11 };
    return { x: -18, y: -11 };
  }

  function trendChangeFor(key) {
    if (key === 'prediction' && predictionSecondaryData.status < 0)
      return undefined;
    const result = `${roundPercentage(
      data.listItems[rowSelected].primaryData[key].secondaryValue.value * 100
    )}%`;
    return result === '0%' ? undefined : result;
  }

  function roundPercentage(number) {
    if (Math.abs(number) > 0 && Math.abs(number) < 1) {
      return '< 1';
    }
    return Math.round(Math.abs(number));
  }

  function formatLowConfidencePrediction() {
    switch (predictionSecondaryData.status) {
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return 'INCR';
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return 'DECR';
      case PREDICTION_PRECISION.NO_CHANGE:
        return 'N/A';
      default:
        return undefined;
    }
  }

  function iconFor(key) {
    const { value, status } =
      data.listItems[rowSelected].primaryData[key].secondaryValue;
    const useZero = false;
    if (
      !useZero &&
      value === 0 &&
      (status === PREDICTION_PRECISION.CANNOT_PREDICT ||
        status === PREDICTION_PRECISION.NO_CHANGE)
    ) {
      return EmptyDash;
    }

    switch (status) {
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        if (palette.mode === 'dark') {
          return TrendUpIconDark;
        }
        return TrendUpIconLight;
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        if (palette.mode === 'dark') {
          return TrendDownIconDark;
        }
        return TrendDownIconLight;
      case PREDICTION_PRECISION.NO_CHANGE:
        return EmptyDash;
      default:
        return undefined;
    }
  }
  const chartOptions = {
    annotations: {
      // defines vertical bar on middle point
      xaxis: [
        {
          x: matXaxis[1],
          borderColor: themeColors.graphLegendColors[rowSelected],
          strokeDashArray: 0,
        },
      ],
      points: [
        series[0].data.length >= 2 ? nowAnnotation : null,
        predictionSecondaryData.status >=
        PREDICTION_PRECISION.DECREASING_WITHIN_RANGE
          ? predictionAnnotation
          : null,
      ].filter(Boolean),
    },
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    forecastDataPoints: {
      count: 1,
      fillOpacity: 0.5,
      strokeWidth: undefined,
      dashArray: 4,
    },
    colors: getLegendColors(rowSelected, themeColors),
    dataLabels: {
      enabled: true,
      formatter: function formatLabels(a, b) {
        const timestampToDiff = b.dataPointIndex === 1 ? 'now' : 'prediction';
        return b.dataPointIndex === 0
          ? undefined
          : trendChangeFor(timestampToDiff);
      },
      offsetY: -5,
      offsetX: -12,
      enabledOnSeries: [0],
      background: {
        enabled: false,
      },
      style: {
        colors: [
          function labelColor(w) {
            const index = w.dataPointIndex;
            return index === 1
              ? trendColor(primaryData.now.secondaryValue, themeColors)
              : trendColor(predictionSecondaryData, themeColors);
          },
        ],
      },
    },

    stroke: {
      curve: 'straight',
      width: 3,
      dashArray: OPTIONS.dashArray,
    },
    grid: {
      show: true,
      borderColor: themeColors.contentCardBorderColor,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },

    xaxis: {
      tickPlacement: 'on',
      labels: {
        show: true,
        hideOverlappingLabels: true,
        style: {
          ...labelStyles,
          colors: themeColors.primaryTextColor,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: matXaxis,
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: numberFormatter,
        show: true,
        hideOverlappingLabels: true,
        style: {
          ...labelStyles,
          colors: themeColors.primaryMaptualListFilteringColor,
        },
      },
      title: {
        text: `${yAxisTitle} MAT`,
        style: {
          ...labelStyles,
          color: themeColors.primaryMaptualListFilteringColor,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter(value) {
          return typeof value === 'number'
            ? showCappedPercentage
              ? formatPercent(value, 75)
              : numberFormatter(value)
            : formatLowConfidencePrediction();
        },
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
  };

  return (
    <Chart
      data-testid="test-script-data-chart"
      data-test-state="check-chart-appears"
      options={chartOptions}
      series={series}
      type="line"
      height={customHeight}
      width="100%"
    />
  );
};

export default MATChart;
