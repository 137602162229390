import Qs from 'qs';
import {
  baseAlbInstance,
  configProxyApiInstance,
  maptualApiInstance,
} from './config';

const templatesRootPath = 'customer-project-templates';

export function getObjectiveTypes() {
  return baseAlbInstance.get('objectiveTypes', { timeout: 30000 });
}

export function getProductLines(productLineId = null) {
  return baseAlbInstance.get(
    `productLine${productLineId ? `/${productLineId}` : ''}`,
    { timeout: 30000 }
  );
}

export function getMarkets() {
  return baseAlbInstance.get('markets', { timeout: 30000 });
}

export function getAvailableMetrics({
  productLine,
  productIds,
  indications,
  therapeuticAreas,
  filterCriteria = 'volume',
  signal = null,
}) {
  return maptualApiInstance.get(
    `/admin/product-lines/${productLine}/metricavailability`,
    {
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'repeat' });
      },
      params: {
        indications,
        product_ids: productIds,
        therapeutic_areas: therapeuticAreas,
        filter_criteria: filterCriteria,
      },
      signal,
      timeout: 1800000,
    }
  );
}

export function postNewProject(requestBody) {
  return baseAlbInstance.post('projects', requestBody, {
    timeout: 60000,
  });
}

export const postNewProjectTemplate = (requestBody) =>
  configProxyApiInstance.post(`/${templatesRootPath}`, requestBody);

export const getCustomerProjectTemplates = () =>
  configProxyApiInstance.get(`/${templatesRootPath}`);

export const updateProjectTemplate = ({ id, requestBody }) =>
  configProxyApiInstance.put(`/${templatesRootPath}/${id}`, requestBody);

export const deleteProjectTemplate = (id) =>
  configProxyApiInstance.delete(`/${templatesRootPath}/${id}`);
