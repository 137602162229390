import { Fragment, useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { userCreatedListOptions } from '@odaia/shared-components/src/userCreatedListsConfig';
import { useToggleHcpInList } from '../../hooks/useToggleHcpInList';
import { EntityTitleBarContext } from './entityTitleBarContext';

const Body = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 19,
  paddingRight: 13,
  paddingBottom: 36,
  gap: 4,
}));
const StyledFormGroup = styled(FormGroup)(() => ({
  marginTop: 12,
}));
const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginTop: 16,
}));
const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  marginLeft: 10,
  radius: 2,
  '&.Mui-checked': {
    color: themeColors.maptualListFilteringButtonBackgroundColor,
  },
}));
const CheckboxLabel = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  gap: '14px',
  alignItems: 'center',
  minHeight: '20px',
  marginLeft: '18px',
  marginBottom: '4px',
  '.MuiSvgIcon-root': {
    color: themeColors.listIconColor,
    width: 20,
    height: 20,
  },
}));
const CheckBoxLabelContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const ListDescription = styled(Typography)(() => ({
  marginLeft: 71,
}));

const ListMembershipSelection = ({ hcpId }) => {
  const {
    userLists: { starred, noSee, longTermLeave },
  } = useContext(EntityTitleBarContext);
  const { themeColors } = useTheme();

  const listOptions = {
    ...userCreatedListOptions,
  };

  const [starredChecked, setStarredChecked] = useState(starred);
  const [noSeeChecked, setNoSeeChecked] = useState(noSee);
  const [longTermLeaveChecked, setLongTermLeaveChecked] =
    useState(longTermLeave);

  const updateCreatedListStarred = useToggleHcpInList(hcpId, 'starred');
  const updateCreatedListNoSee = useToggleHcpInList(hcpId, 'noSee');
  const updateCreatedListLongTermLeave = useToggleHcpInList(
    hcpId,
    'longTermLeave'
  );

  listOptions.starred.onChange = () => {
    setStarredChecked(!starredChecked);
    updateCreatedListStarred();
  };

  listOptions.noSee.onChange = () => {
    setNoSeeChecked(!noSeeChecked);
    updateCreatedListNoSee();
  };

  listOptions.longTermLeave.onChange = () => {
    setLongTermLeaveChecked(!longTermLeaveChecked);
    updateCreatedListLongTermLeave();
  };

  const checkboxMap = {
    starred: starredChecked,
    noSee: noSeeChecked,
    longTermLeave: longTermLeaveChecked,
  };

  return (
    <Body className="intercom-field-user-created-lists-popover">
      <Typography
        variant="title3"
        color={themeColors.secondaryMaptualListFilteringColor}
      >
        Add to Custom List
      </Typography>
      <Typography
        variant="body2"
        color={themeColors.primaryMaptualListFilteringColor}
      >
        Add an HCP to a custom list so they can be filtered
      </Typography>

      <StyledFormGroup>
        {Object.values(listOptions).map((option) => (
          <Fragment key={option.id}>
            <StyledFormControlLabel
              control={
                <StyledCheckbox
                  onChange={option.onChange}
                  checked={checkboxMap[option.id]}
                  data-testid={`${option.id}-checkbox`}
                  size="medium"
                />
              }
              label={
                <CheckboxLabel>
                  <CheckBoxLabelContainer>{option.icon}</CheckBoxLabelContainer>
                  <CheckBoxLabelContainer>
                    <Typography
                      variant="body1"
                      color={themeColors.secondaryMaptualListFilteringColor}
                    >
                      {option.label}
                    </Typography>
                  </CheckBoxLabelContainer>
                </CheckboxLabel>
              }
            />
            <ListDescription
              variant="body2"
              color={themeColors.primaryMaptualListFilteringColor}
            >
              {option.description}
            </ListDescription>
          </Fragment>
        ))}
      </StyledFormGroup>
    </Body>
  );
};

export default ListMembershipSelection;
