import { Fade } from '@mui/material';
import Box from '@mui/material/Box';

import TabbedChartHeader from './tabbedChartHeader';
import TabbedChartContent from './tabbedChartContent';
import { SkeletonChartContent } from '../../../generic/SkeletonChartContent';

const TabbedChart = ({
  data,
  isLoading,
  error,
  metricComponentType,
  chartMetadata,
  onChipSelect,
  style = {},
}) => {
  const handleChipSelect = ({ chip, chipIndex, dropdownIndex }) => {
    onChipSelect({ chip, chipIndex, dropdownIndex });
  };

  return (
    <Fade in timeout={1200} key={metricComponentType}>
      <Box sx={{ ...style }} className="intercom-field-scripts-volume-graph">
        <TabbedChartHeader
          title="Scripts Volume"
          chartMetadata={chartMetadata}
          onChipSelect={handleChipSelect}
        />
        {isLoading && <SkeletonChartContent title="Scripts Volume" error="" />}
        {!isLoading && (error || !data) && (
          <SkeletonChartContent
            title="Scripts Volume"
            error={error?.message || 'Graph and table not available'}
          />
        )}
        {!isLoading && !error && data && (
          <TabbedChartContent
            filteredData={data}
            metricComponentType={metricComponentType}
          />
        )}
      </Box>
    </Fade>
  );
};

export default TabbedChart;
