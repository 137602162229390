import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { EntityDrawerContext } from './data/entityDrawerContext';
import { MultiObjectivesRow } from '../../../shared/MultiObjectivesRow';
import { CHART_TYPES_ENUM } from '../../../shared/graphs/constants';
import { EntityDrawerError } from './EntityDrawerError';
import { ERROR_TYPES_ENUM } from '../../constants';
import { DrawerDataSkeleton } from '../skeletons/DrawerDataSkeleton';
import { EntityDrawerCircleGraphCard } from './graphs/EntityDrawerCircleGraphCard';
import { EntityDrawerLineGraphCard } from './graphs/EntityDrawerLineGraphCard';
import { EntityDrawerBarGraphCard } from './graphs/EntityDrawerBarGraphCard';
import { trackEntityOverviewDrilldownObjectiveDropdown } from '../../../../trackers/mixpanel';

const graphComponentMap = {
  [CHART_TYPES_ENUM.LINE]: EntityDrawerLineGraphCard,
  [CHART_TYPES_ENUM.BAR]: EntityDrawerBarGraphCard,
  [CHART_TYPES_ENUM.CIRCLE]: EntityDrawerCircleGraphCard,
};

export const EntityDrawerDataTab = () => {
  const { entityType, isMetadataLoading, isMetadataError, metadata } =
    useContext(EntityDrawerContext);

  if (isMetadataLoading) {
    return <DrawerDataSkeleton />;
  }

  if (isMetadataError) {
    return (
      <Box pr={3} pl={3} pt={3} width="100%">
        <EntityDrawerError
          errorType={ERROR_TYPES_ENUM.RETRY}
          retryType="metadata"
        />
      </Box>
    );
  }

  const [objectives, setObjectives] = useState([]);
  const [selectedObjective, setSelectedObjective] = useState({});

  useEffect(() => {
    if (!isMetadataLoading && metadata?.objectives?.length > 0) {
      setObjectives(metadata.objectives);
      setSelectedObjective(metadata.objectives[0]);
    }
  }, [metadata, isMetadataLoading]);

  const onHandleClick = (objective) => {
    trackEntityOverviewDrilldownObjectiveDropdown(objective.label, entityType);
    setSelectedObjective(objective);
  };

  const mainCharts = selectedObjective?.charts?.filter(
    (chart) => chart.type !== CHART_TYPES_ENUM.CIRCLE
  );

  const circleCharts = selectedObjective?.charts?.filter(
    (chart) => chart.type === CHART_TYPES_ENUM.CIRCLE
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gap={2}
      mt={3}
      px={3}
    >
      {objectives?.length > 1 && (
        <Box width="100%">
          <MultiObjectivesRow
            objectives={objectives}
            handleClick={onHandleClick}
            selectedObjective={selectedObjective}
            applyMargins={{ top: false, bottom: false }}
          />
        </Box>
      )}

      {mainCharts?.length > 0 &&
        mainCharts.map((chart, index) => {
          const { metrics, title, type } = chart;

          if (type in graphComponentMap) {
            const DrawerGraphSection = graphComponentMap[type];

            return (
              <Box key={`${type}-${index}`} width="100%">
                <DrawerGraphSection
                  metrics={metrics}
                  sectionTitle={title}
                  objectiveId={selectedObjective?.id}
                />
              </Box>
            );
          }

          return null;
        })}

      {circleCharts?.length > 0 && (
        <Box display="flex" flexDirection="row" width="100%" gap={2}>
          {circleCharts.map((chart, index) => {
            const { metrics, title, type } = chart;

            if (type in graphComponentMap) {
              const DrawerGraphSection = graphComponentMap[type];

              return (
                <DrawerGraphSection
                  key={`${type}-${index}`}
                  metrics={metrics}
                  sectionTitle={title}
                  objectiveId={selectedObjective?.id}
                />
              );
            }

            return null;
          })}
        </Box>
      )}
    </Box>
  );
};
