import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTheme } from '@mui/material';
import {
  useProductPerformanceData,
  ChartLineData,
} from './useProductPerformanceData';
import { BASKET_TYPE } from '../../constants';
import { ProductPerformanceMetadataContextType } from './useProductPerformanceMetadataContext';

const DISPLAYABLE_TARGET_PRODUCTS_LIMIT = 3;
const DISPLAYABLE_COMPETITOR_PRODUCTS_LIMIT = 2;

interface LineColors {
  target: string[];
  competitor: string[];
}

export interface ExtendedChartLineData extends ChartLineData {
  chartLineColor: string;
}

export interface ProductPerformanceChartContextType {
  isDataLoading: boolean;
  isDataError: boolean;
  isDataIdle: boolean;
  refetchData: () => void;
  title: string;
  data: ExtendedChartLineData[];
  setData: Dispatch<SetStateAction<ExtendedChartLineData[]>>;
  availableLineColors: LineColors;
  setAvailableLineColors: Dispatch<SetStateAction<LineColors>>;
}

export const useProductPerformanceChartContext = (
  productPerformanceMetadataContext: ProductPerformanceMetadataContextType
): ProductPerformanceChartContextType => {
  const {
    projectId,
    regionId,
    objectiveId,
    entityId,
    selectedMetric,
    selectedCadence,
    selectedCardTab,
  } = productPerformanceMetadataContext;

  const {
    isLoading: isDataLoading,
    data: dataFromApi,
    isError: isDataError,
    isIdle: isDataIdle,
    refetch: refetchData,
  } = useProductPerformanceData({
    projectId,
    regionId,
    objectiveId,
    entityId,
    metricDataType: selectedCardTab,
    metric: selectedMetric,
    cadence: selectedCadence?.id,
  });

  const { themeColors } = useTheme();
  const initialLineColors = {
    target: [
      themeColors.dataGeneralColor,
      themeColors.dataGeneralColor3,
      themeColors.dataGeneralColor5,
    ],
    competitor: [
      themeColors.dataCompetition1,
      themeColors.dataCompetition3,
      themeColors.dataCompetition5,
    ],
  };

  const [data, setData] = useState<ExtendedChartLineData[]>([]);
  const [availableLineColors, setAvailableLineColors] =
    useState<LineColors>(initialLineColors);

  const getChartLineColor = (basketType: string): string => {
    const color = availableLineColors[basketType]?.shift() ?? '';
    setAvailableLineColors(availableLineColors);
    return color;
  };

  const addLineColors = (data: ChartLineData[]): ExtendedChartLineData[] => {
    let targetProductsCount = 0;
    let competitorProductsCount = 0;

    return data.map((lineData) => {
      const { basketType } = lineData;

      if (
        basketType === BASKET_TYPE.TARGET &&
        targetProductsCount < DISPLAYABLE_TARGET_PRODUCTS_LIMIT
      ) {
        targetProductsCount++;
        return { ...lineData, chartLineColor: getChartLineColor(basketType) };
      }

      if (
        basketType === BASKET_TYPE.COMPETITOR &&
        competitorProductsCount < DISPLAYABLE_COMPETITOR_PRODUCTS_LIMIT
      ) {
        competitorProductsCount++;
        return { ...lineData, chartLineColor: getChartLineColor(basketType) };
      }

      return { ...lineData, chartLineColor: '' };
    });
  };

  const title = dataFromApi?.title;
  const rawData = dataFromApi?.data;

  useEffect(() => {
    setAvailableLineColors(initialLineColors);
  }, [rawData, selectedCardTab, selectedCadence, selectedMetric]);

  useEffect(() => {
    const areColorsResetToInitialValues =
      availableLineColors.target.length === initialLineColors.target.length &&
      availableLineColors.competitor.length ===
        initialLineColors.competitor.length;

    if (!isDataLoading && rawData && areColorsResetToInitialValues) {
      const dataWithLineColors = addLineColors(rawData);
      setData(dataWithLineColors);
    }
  }, [rawData, isDataLoading, availableLineColors]);

  return {
    isDataLoading,
    isDataError,
    isDataIdle,
    refetchData,
    title,
    data,
    setData,
    availableLineColors,
    setAvailableLineColors,
  };
};
