import { useState } from "react";
import { Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  ButtonBase,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { StyledPrimaryChip } from "./primaryChip";

interface Option {
  id: string;
  label: string;
}

interface OptionSelection {
  [key: string]: Option;
}

export interface AutocompleteComboboxProps<T> {
  autocompleteProps?: AutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >;
  options: Option[];
  onOptionChange: (option: Option, checked: boolean) => void;
  type: string;
  selection?: OptionSelection;
  formatLabel?: (label: string) => string;
}
const ChipBox = styled(Box)(() => ({
  gap: 8,
  marginTop: 16,
  display: "flex",
  flexWrap: "wrap",
}));

const StyledPaper = styled(Paper)(({ theme: { themeColors } }) => ({
  backgroundImage: "none",
  backgroundColor: themeColors.modalBackgroundColor,
  border: "1px solid",
  borderRadius: 5,
  borderColor: themeColors.contentCardBorderColor,
  margin: "3px 0",
  "li.MuiAutocomplete-option": {
    padding: 0,
    margin: 16,
    borderRadius: 5,
    button: {
      fontSize: 16,
      width: "100%",
      justifyContent: "start",
      padding: 12,
      textAlign: "left",
    },
    '&[aria-selected="true"]': {
      backgroundColor: themeColors.HCPListItemBackgroundActive,
      "&.Mui-focused": {
        backgroundColor: themeColors.HCPListItemBackgroundHover,
      },
    },
    "&.Mui-focused": {
      backgroundColor: themeColors.HCPListItemBackgroundHover,
    },
    ".MuiButtonBase-root.MuiCheckbox-root": {
      padding: 0,
      marginRight: 8,
    },
  },
  "svg.MuiSvgIcon-root": {
    color: themeColors.HCPListItemContentSecondary,
  },
  ul: {
    padding: 0,
    backgroundColor: "transparent",
  },
}));

const StyledListbox = styled(Box)(({ theme: { themeColors } }) => ({
  "&::-webkit-scrollbar": {
    width: 14,
  },
  "&::-webkit-scrollbar-thumb": {
    background: themeColors.generalScrollbarButtonColor,
    width: 14,
    borderRadius: 100,
    border: `2px solid ${themeColors.generalScrollbarTrackColor}`,
  },
  "&::-webkit-scrollbar-track": {
    background: themeColors.generalScrollbarTrackColor,
  },
}));

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme: { themeColors } }) => ({
    fontSize: 16,
    ".MuiInputBase-root": {
      padding: 0,
      paddingLeft: 12,
      "&.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd": {
        paddingRight: 4,
      },
      ".MuiInputBase-input": {
        padding: "8px 0",
      },
      "input::placeholder": {
        color: themeColors.textFieldSelectionsDefaultTextColor,
        opacity: 1,
      },
    },
    "svg.MuiSvgIcon-root": {
      fontSize: 16,
    },
  })
);

export const AutocompleteCombobox = <T,>({
  options,
  onOptionChange,
  type,
  selection = {},
  formatLabel = (label: string) => label,
  autocompleteProps,
}: AutocompleteComboboxProps<T>) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    Object.keys(selection).map((key) => selection[key])
  );

  const [textInput, setTextInput] = useState<string>("");

  const handleOptionChange = (option: Option, checked: boolean) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(
        selectedOptions.filter(
          (selectedOption) => selectedOption.id !== option.id
        )
      );
    }
    onOptionChange(option, checked);
  };

  return (
    <Box>
      <StyledAutocomplete
        multiple
        id="filter-combobox"
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option: Option) => option.label}
        renderTags={() => null}
        popupIcon={null}
        isOptionEqualToValue={(option: Option, value: Option) =>
          option.id === value.id
        }
        sx={{ width: 320 }}
        renderOption={(props, option: Option, { selected }) => (
          <li {...props}>
            <ButtonBase
              disableRipple
              onClick={(_) => handleOptionChange(option, !selected)}
            >
              <Checkbox checked={selected} />
              {formatLabel(option.label)}
            </ButtonBase>
          </li>
        )}
        inputValue={textInput}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setTextInput("")}>
                    {textInput.length > 0 && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder={`Search ${type}`}
            onChange={(ev) => setTextInput(ev.target.value)}
          />
        )}
        PaperComponent={StyledPaper}
        ListboxComponent={StyledListbox}
        value={selectedOptions}
        {...autocompleteProps}
      />
      <ChipBox>
        {selectedOptions.map((option: Option) => (
          <StyledPrimaryChip
            onDelete={(_) => handleOptionChange(option, false)}
            label={formatLabel(option.label)}
            deleteIcon={<Close />}
            key={option.id}
          />
        ))}
      </ChipBox>
    </Box>
  );
};
