import { useQuery } from 'react-query';
import { getEntityMetadata } from '../../request/projectRequests';

export const useHcpMetadata = ({
  productLineId,
  rdsRegionId,
  entityId,
}: {
  productLineId: string;
  rdsRegionId: string;
  entityId: string;
}) => {
  const { data, isLoading, refetch } = useQuery(
    ['hcpMetadata', productLineId, rdsRegionId, entityId],
    async () => {
      const response = await getEntityMetadata(
        productLineId,
        rdsRegionId,
        entityId,
        'hcp'
      );

      if (response) return response.data;

      return null;
    },
    { enabled: !!(productLineId && rdsRegionId && entityId) }
  );
  return {
    metadata: data,
    isMetadataLoading: isLoading,
    refetchMetadata: refetch,
  };
};
