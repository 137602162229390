import { Dispatch, useContext, useEffect, useState } from 'react';

import { FieldContext } from '../../application/appViews/field/fieldContext';
import {
  useEntityOverviewData,
  useEntityOverviewMetadata,
} from './useEntityOverviewData';
import {
  DEFAULT_ENTITY_CADENCE,
  ENTITY_TYPES_ENUM,
  ERROR_TYPES_ENUM,
  URL_SEARCH_PARAMS_ENUM,
} from '../constants';
import { AppContext } from '../../application/appContext';
import { buildTableItems } from '../components/tableHelpers';
import { formatEntityName } from '../../../utils/formatEntityName';

export interface EntityOverviewContextType {
  entityType: string;
  isMetadataLoading: boolean;
  isDataFetching: boolean;
  metadata: any;
  data: any;
  regionName: string;
  objectiveId: string;
  setObjectiveId: Dispatch<(prevState: undefined) => undefined>;
  cadence: string;
  setCadence: Dispatch<(prevState: undefined) => undefined>;
  entitySubtype: string;
  setEntitySubtype: Dispatch<(prevState: undefined) => undefined>;
  filters: any;
  setFilters: Dispatch<(prevState: undefined) => undefined>;
  isMetadataError: boolean;
  isDataError: boolean;
  errorType: string;
  setErrorType: (errorType: ERROR_TYPES_ENUM) => void;
  searchValue: string;
  setSearchValue: Dispatch<(prevState: undefined) => undefined>;
}

interface Region {
  label: string;
  id: string;
}

interface Filters {
  regions: {
    [regionId: string]: Region;
  };
}

const getSpecializedHeaders = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES_ENUM.OUTLET:
    case ENTITY_TYPES_ENUM.DIRECT_ACCOUNT:
      return [
        {
          label: '',
          subLabel: '',
          type: 'drawer',
        },
      ];

    default:
      return [];
  }
};

const getSpecializedItem = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES_ENUM.OUTLET:
    case ENTITY_TYPES_ENUM.DIRECT_ACCOUNT:
      return {
        data: {
          drawerOpener: true,
        },
      };

    default:
      return [];
  }
};

export const getFiltersFromUrl = (): (string | null)[] => {
  const location = new URLSearchParams(window.location.search);
  let regionId = decodeURIComponent(
    location.get(URL_SEARCH_PARAMS_ENUM.REGION_ID)
  ).toUpperCase();
  const rawRegionLabel = decodeURIComponent(
    location.get(URL_SEARCH_PARAMS_ENUM.REGION_LABEL)
  );
  let regionLabel = formatEntityName(rawRegionLabel);

  if (regionId === 'NULL') {
    regionId = null;
  }

  if (regionLabel === 'Null') {
    regionLabel = null;
  }

  return [regionId, regionLabel];
};

export const useEntityOverviewContext = (
  entityType
): EntityOverviewContextType => {
  const { projectList } = useContext(AppContext);
  const { project, maptualListMetadata, isMaptualListsLoading } =
    useContext(FieldContext);

  const projectId = project?.metadata?.projectId;
  const maptualListId = maptualListMetadata?.maptualListId;
  const regionName = maptualListMetadata?.listName;

  const { productLineId, marketId } =
    projectList?.find((proj) => proj.projectId === projectId) || {};

  const [searchValue, setSearchValue] = useState();

  // ============================ METADATA ============================ //

  const [currentEntityType, setCurrentEntityType] = useState();
  const [entitySubtype, setEntitySubtype] = useState();
  const [isMetadataLoading, setIsMetadataLoading] = useState(true);

  const {
    data: metadata,
    isFetching: isMetadataFetching,
    isError: isMetadataError,
    error: metadataError,
  } = useEntityOverviewMetadata({
    marketId,
    productLineId,
    projectId,
    regionId: maptualListId,
    entityType,
  });

  useEffect(() => {
    if (isMaptualListsLoading && isMetadataFetching) {
      setIsMetadataLoading(true);
    }

    if (
      !isMaptualListsLoading &&
      isMetadataFetching !== undefined &&
      isMetadataFetching !== isMetadataLoading
    ) {
      setIsMetadataLoading(isMetadataFetching);
    }
  }, [isMetadataFetching, isMaptualListsLoading]);

  useEffect(() => {
    setEntitySubtype(null);
    if (entityType !== undefined && entityType !== currentEntityType) {
      setCurrentEntityType(entityType);
    }
  }, [entityType]);

  useEffect(() => {
    if (searchValue !== null && entityType && maptualListMetadata) {
      setSearchValue(null);
    }
  }, [entityType, maptualListMetadata]);

  // ============================ DATA ============================ //

  const [objectiveId, setObjectiveId] = useState();
  const [cadence, setCadence] = useState();
  const [data, setData] = useState();

  const [filters, setFilters] = useState<Filters>({});
  const [regionId, regionLabel] = getFiltersFromUrl();

  useEffect(() => {
    if (regionId && regionLabel) {
      const urlFilters: Filters = {
        regions: {
          [regionId]: {
            id: regionId,
            label: regionLabel,
          },
        },
      };
      setFilters(urlFilters);
    } else {
      setFilters({});
    }
  }, [maptualListId, regionId, regionLabel]);

  useEffect(() => {
    if (!isMetadataFetching && metadata) {
      if (!objectiveId) {
        const objectives = metadata?.objectives;
        const defaultObjectiveId =
          objectives && objectives.length > 0 ? objectives[0].id : null;

        setObjectiveId(defaultObjectiveId);
      }

      if (metadata.entitySubtypes?.length > 0) {
        setEntitySubtype(metadata.entitySubtypes[0]);
      }
    }
  }, [metadata, isMetadataFetching]);

  useEffect(() => {
    if (!isMetadataFetching && metadata && objectiveId) {
      const cadences = metadata?.objectives.find(
        (o) => o.id === objectiveId
      ).cadences;

      const defaultCadence =
        cadences && cadences.length > 0
          ? cadences.find((c) => c === DEFAULT_ENTITY_CADENCE)
          : null;

      if (!cadence || !cadences.includes(cadence)) {
        setCadence(defaultCadence);
      }
    }
  }, [metadata, isMetadataFetching, objectiveId]);

  const {
    data: serverData,
    isFetching: isDataFetching,
    isError: isDataError,
    error: dataError,
  } = useEntityOverviewData({
    productLineId,
    projectId,
    regionId: maptualListId,
    entityType,
    objectiveId,
    cadence,
    entitySubtype,
    filters,
  });

  useEffect(() => {
    if (!isDataFetching && serverData && serverData.headers?.length > 0) {
      const { headers: serverHeaders, items: serverItems } = serverData;
      const specializedHeaders = getSpecializedHeaders(entityType);

      const specializedItem = getSpecializedItem(entityType);
      const tableItems = buildTableItems(serverItems, specializedItem);

      setData({
        ...serverData,
        headers: [...serverHeaders, ...specializedHeaders],
        items: tableItems,
      });
    }
  }, [serverData, isDataFetching]);

  // ============================ ERRORS ============================ //

  const [errorType, setErrorType] = useState(null);

  useEffect(() => {
    setErrorType(null);

    if (isMetadataError) {
      setErrorType(
        metadataError?.response?.status === 422
          ? ERROR_TYPES_ENUM.NON_TERRITORY
          : ERROR_TYPES_ENUM.RETRY
      );
    } else if (isDataError) {
      setErrorType(ERROR_TYPES_ENUM.RETRY);
    } else {
      const noTableData = serverData?.items?.length === 0;
      if (noTableData) {
        setErrorType(
          Object.keys(filters).length > 0
            ? ERROR_TYPES_ENUM.NO_FILTER_DATA
            : ERROR_TYPES_ENUM.NO_DATA
        );
      }
    }
  }, [
    isMetadataFetching,
    isDataFetching,
    filters,
    serverData,
    metadataError,
    dataError,
  ]);

  // ============================ RETURN ============================ //

  return {
    entityType: currentEntityType,
    isMetadataLoading,
    isDataFetching,
    metadata,
    data,
    regionName,
    objectiveId,
    setObjectiveId,
    cadence,
    setCadence,
    entitySubtype,
    setEntitySubtype,
    filters,
    setFilters,
    isMetadataError,
    isDataError,
    errorType,
    setErrorType,
    searchValue,
    setSearchValue,
  };
};
