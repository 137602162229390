import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Button, styled } from '@mui/material';
import { CurationRollbackModal } from './rollback/rollbackModal';

const Wrapper = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  paddingBottom: '32px',
}));

const StyledButton = styled(Button)(() => ({
  maxHeight: '40px',
  minWidth: 'fit-content',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  header: {
    height: '22px',
    fontSize: 18,
    fontWeight: 600,
    color: '#E0E4EB',
    marginBottom: '4px',
  },
  subHeader: {
    height: '19px',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: '16px',
    color: theme.themeColors.neutral60,
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '8px',
  },
}));

const validationReportUrl =
  'https://s3.console.aws.amazon.com/s3/buckets/odaia-secure-datapackage-bucket?region=us-east-1&prefix=curation/curation-engine-validation';
const simulationReportUrl =
  'https://s3.console.aws.amazon.com/s3/buckets/odaia-secure-datapackage-bucket?region=us-east-1&prefix=curation/curation-engine-simulation';

export const UsefulLinks = ({ projectId }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const link = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Wrapper>
      <div className={classes.root}>
        <Typography className={classes.header}>Resources</Typography>
        <Typography className={classes.subHeader}>
          Links to useful resources
        </Typography>
        <div className={classes.linkContainer}>
          <Button variant="text" onClick={() => link(validationReportUrl)}>
            Validation Report
          </Button>
          <Button variant="text" onClick={() => link(simulationReportUrl)}>
            Simulation Report
          </Button>
        </div>
      </div>
      <StyledButton variant="text" onClick={() => setIsModalOpen(true)}>
        Rollback Curation
      </StyledButton>
      <CurationRollbackModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        projectId={projectId}
      />
    </Wrapper>
  );
};

export default UsefulLinks;
