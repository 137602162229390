import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { getCategoricalDataByHCP } from '../request/categoricalDataRequests';

interface CategoricalDataItem {
  title: string;
  subTitle: string;
  numberOfCategories: number;
  selectedCategoryIndex: number;
}

export interface DataItem {
  title: string;
  subTitle: string;
  tooltip: number;
  categoricalData: CategoricalDataItem[];
}

export interface CategoricalData {
  [key: string]: DataItem[];
}

interface LookupTable {
  [key: string]: boolean;
}

export const removeDuplicateCategories = (
  categoricalData: CategoricalDataItem[]
) => {
  const lookupTable: LookupTable = {};

  const uniqueCategoricalData = categoricalData.reduce(
    // @ts-ignore
    (newCategoricalData, category) => {
      const lookupKey = category.title + category.selectedCategoryIndex;

      if (!lookupTable[lookupKey]) {
        lookupTable[lookupKey] = true;
        return [...newCategoricalData, category];
      }

      return newCategoricalData;
    },
    []
  ) as unknown as CategoricalDataItem[];

  return uniqueCategoricalData;
};

export const filterCategoricalData = (data: CategoricalData) =>
  Object.entries(data).reduce((result, [objectiveId, dataPerObjective]) => {
    if (!dataPerObjective.length) {
      return result;
    }

    // @ts-ignore
    const filteredCards = dataPerObjective.reduce((nonEmptyCards, card) => {
      const uniqueCategoricalData = removeDuplicateCategories(
        card.categoricalData
      );

      return [
        ...nonEmptyCards,
        { ...card, categoricalData: uniqueCategoricalData },
      ];
    }, []);

    return { ...result, [objectiveId]: filteredCards };
  }, {});

interface ResponseData {
  isLoading: boolean;
  categoricalData: CategoricalData;
}

export const useCategoricalData = (
  entityId: string,
  projectId: string
): ResponseData => {
  const apiResponse = useQuery(
    `categoricalData-${entityId}${projectId}`,
    async () => {
      try {
        if (!projectId || !entityId) return null;

        const response = await getCategoricalDataByHCP(projectId, entityId);
        return response.data;
      } catch (err) {
        return err;
      }
    }
  );

  const { isLoading, data, status } = apiResponse;
  const [categoricalData, setCategoricalData] = useState({});

  useEffect(() => {
    if (!isLoading && status === 'success' && data) {
      setCategoricalData(filterCategoricalData(data as CategoricalData));
    }
  }, [isLoading, data, status]);

  return { isLoading, categoricalData };
};
