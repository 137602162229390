import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
} from '../../../components/generic/modalDisplay';
import { ExpandedBarGraphContainer } from './barGraph/ExpandedBarGraphContainer';
import { ExpandedLineGraphContainer } from './lineGraph/ExpandedLineGraphContainer';

export const GraphModal = ({
  isOpen,
  handleClose,
  graphType,
  valueType,
  data,
  chartLabels,
  graphTitle,
  yTitle,
  tooltipDataPointTitle,
  graphColor = null,
}) => (
  <ModalDisplay onClose={() => handleClose()} isOpen={isOpen}>
    {graphTitle && (
      <ModalHeader onClose={() => handleClose()} shouldShowBottomBorder={false}>
        {graphTitle}
      </ModalHeader>
    )}
    <ModalBody>
      {graphType === 'bar' && (
        <ExpandedBarGraphContainer
          row={data}
          chartLabels={chartLabels}
          yTitle={yTitle}
          graphColor={graphColor}
          tooltipDataPointTitle={tooltipDataPointTitle}
        />
      )}

      {graphType === 'line' && (
        <ExpandedLineGraphContainer
          row={data}
          valueType={valueType}
          chartLabels={chartLabels}
          yTitle={yTitle}
          tooltipDataPointTitle={tooltipDataPointTitle}
          graphColor={graphColor}
        />
      )}
    </ModalBody>
  </ModalDisplay>
);
