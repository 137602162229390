import { useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { DISTRIBUTION_CONFIG } from '../constants';

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'options',
})(({ theme: { themeColors }, options = [] }) => ({
  maxWidth: '40%',
  textAlign: 'left',
  '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    color: themeColors.primaryMaptualListFilteringColor,
  },
  width: getWidth(options),
}));

const StyledSelect = styled(Select)(({ theme: { themeColors } }) => ({
  '.MuiInputBase-input': {
    padding: '8px 14px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.primaryMaptualListFilteringColor,
  },
}));

export const getWidth = (options) => {
  const CHARACTER_WIDTH = 10;
  let maxWidth = 0;

  (options || []).forEach((opt) => {
    if (opt.label.length > maxWidth) maxWidth = opt.label.length;
  });
  return maxWidth * CHARACTER_WIDTH + 86;
};

const BaseDropdown = ({
  handleDropdownSelect,
  selectionName,
  options,
  selected,
}) => (
  <StyledFormControl variant="outlined" options={options}>
    <InputLabel>{selectionName}</InputLabel>
    <StyledSelect
      label={selectionName}
      value={selected || 0}
      onChange={handleDropdownSelect}
      data-testid="dropdown"
    >
      {Array.isArray(options)
        ? options.map((item, i) => (
            <MenuItem key={item.label} value={i}>
              {item.label || ''}
            </MenuItem>
          ))
        : null}
    </StyledSelect>
  </StyledFormControl>
);

export function CategoryDropdown() {
  const {
    categoryOptions,
    updateSelectedCategory,
    selectedCategory,
    distributionType,
  } = useContext(TerritoryOverviewContext);

  const handleDropdownSelect = (event) => {
    updateSelectedCategory(event.target.value);
  };

  return (
    <BaseDropdown
      handleDropdownSelect={handleDropdownSelect}
      selectionName={DISTRIBUTION_CONFIG[distributionType]?.dropdownLabel || ''}
      options={categoryOptions}
      selected={selectedCategory}
    />
  );
}
export function MetricDropdown() {
  const { metricOptions, selectedMetric, updateSelectedMetric } = useContext(
    TerritoryOverviewContext
  );

  const handleDropdownSelect = (event) => {
    updateSelectedMetric(event.target.value);
  };

  return (
    <BaseDropdown
      handleDropdownSelect={handleDropdownSelect}
      selectionName="Metric"
      options={metricOptions}
      selected={selectedMetric}
    />
  );
}

const DropdownWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  gridGap: '10px',
  flexGrow: 1,
}));

export const DropdownOptions = () => (
  <DropdownWrapper>
    <CategoryDropdown />
    <MetricDropdown />
  </DropdownWrapper>
);
