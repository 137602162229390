import { Divider } from '@mui/material';
import ProfileDetails from './profileDetails';
import Connections from './connections';

const ProfileDetailsAndConnections = ({ entityUData, onConnectionsLoaded }) => (
  <div
    style={{
      overflow: 'hidden',
    }}
  >
    <ProfileDetails entityUData={entityUData} />
    <Divider style={{ marginTop: 30, marginBottom: 30 }} />
    <Connections
      onConnectionsLoaded={(data) => {
        onConnectionsLoaded(data);
      }}
    />
  </div>
);

export default ProfileDetailsAndConnections;
