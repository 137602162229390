import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import { theme } from '@odaia/ui/src/theme';
import { formatYAxisValue, getYAxisMaxValue } from '../drawerHelpers';
import {
  CHART_LINE_DASH_PATTERN,
  ERROR_TYPES_ENUM,
  SHORT_FORM_CADENCE_ENUM,
  TREND_STATUSES_ENUM,
} from '../../../constants';
import { TrendIcon } from '../../../../application/appViews/shared/TrendIcon';
import { createTooltip, INITIAL_TOOLTIP_STATE, Tooltip } from '../Tooltip';
import { EntityDrawerError } from '../EntityDrawerError';

const formatTimestampLabel = (timestamp, cadence) => {
  if (cadence === SHORT_FORM_CADENCE_ENUM.WK) {
    return timestamp.split(',')[0];
  }

  if (
    cadence === SHORT_FORM_CADENCE_ENUM.MTH ||
    cadence === SHORT_FORM_CADENCE_ENUM.QTR
  ) {
    return timestamp.split(' ')[0];
  }

  return timestamp;
};

export const buildDatasets = (rawData, cadence, themeColors) => {
  const datasets = [];

  if (rawData?.recentYear?.length > 0) {
    const trends = rawData?.recentYear?.map((item) => item.trend);
    datasets.push({
      label: 'Recent year',
      data: buildChartData(rawData?.recentYear, cadence),
      borderColor: themeColors.dataGeneralColor,
      pointStyle: false,
      pointBackgroundColor: themeColors.dataGeneralColor,
      trends,
      timestamps: rawData?.recentYear?.map((item) => item.timestamp),
      segment: cadence === SHORT_FORM_CADENCE_ENUM.MAT && {
        borderDash: (ctx) => getLineDashPattern(ctx, trends.length),
        borderColor: (ctx) => getLineColor(ctx, trends),
      },
    });
  }

  if (rawData?.previousYear?.length > 0) {
    const trends = rawData?.previousYear?.map((item) => item.trend);
    datasets.push({
      label: 'Previous year',
      data: buildChartData(rawData?.previousYear, cadence),
      borderColor: themeColors.dataGeneralColor3,
      pointStyle: false,
      pointBackgroundColor: themeColors.dataGeneralColor3,
      trends,
      timestamps: rawData?.previousYear?.map((item) => item.timestamp),
    });
  }

  return datasets;
};

export const buildChartData = (
  yearData: object[],
  cadence: SHORT_FORM_CADENCE_ENUM
): number[] => {
  if (!yearData) return [];

  if (cadence === SHORT_FORM_CADENCE_ENUM.MAT) {
    const lastHistoricalDataPoint = yearData[yearData.length - 2].data;
    return yearData.map((item, index) =>
      index === yearData.length - 1 &&
      item.trend.status < TREND_STATUSES_ENUM.VALID_METRIC_DATA_STATUS
        ? lastHistoricalDataPoint
        : item.data
    );
  }

  return yearData.map((item) => item.data);
};

export const getLineDashPattern = (ctx: object, dataLength: number): number[] =>
  ctx.p1DataIndex === dataLength - 1 ? CHART_LINE_DASH_PATTERN : undefined;

export const getLineColor = (ctx: object, trends: object[]): string => {
  const trendStatus = trends[ctx.p1DataIndex].status;
  return ctx.p1DataIndex === trends.length - 1 &&
    trendStatus === TREND_STATUSES_ENUM.CANNOT_PREDICT
    ? 'transparent'
    : undefined;
};

export const buildLegendPlugin = (cadence, themeColors) => ({
  display: cadence !== SHORT_FORM_CADENCE_ENUM.MAT,
  position: 'bottom',
  align: 'start',
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    boxHeight: 8,
    color: themeColors.tertiaryColor,
    padding: 12,
  },
  onClick: () => {},
});

export const buildTooltipPlugin = (tooltip, setTooltip, unit) => ({
  enabled: false,
  external: (context) => {
    createTooltip({
      context,
      currentTooltip: tooltip,
      updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
      unit,
    });
  },
});

export const buildAnnotationPlugin = (data, trend, cadence) =>
  data &&
  cadence === SHORT_FORM_CADENCE_ENUM.MAT &&
  (trend?.status === TREND_STATUSES_ENUM.INCREASING_OUT_OF_RANGE ||
    trend?.status === TREND_STATUSES_ENUM.DECREASING_OUT_OF_RANGE)
    ? {
        annotations: {
          label1: {
            type: 'label',
            content: buildTrendArrowImage(trend.status),
            ...calculateTrendArrowPosition(data, trend.status),
          },
        },
      }
    : {};

export const calculateTrendArrowPosition = (
  data: number[],
  trendStatus: TREND_STATUSES_ENUM
): object => ({
  xValue: data.length - 1.5,
  yValue: data[data.length - 2],
  yAdjust:
    trendStatus === TREND_STATUSES_ENUM.INCREASING_OUT_OF_RANGE ? -10 : 10,
});

export const buildTrendArrowImage = (
  trendStatus: TREND_STATUSES_ENUM
): HTMLImageElement => {
  const direction =
    trendStatus === TREND_STATUSES_ENUM.INCREASING_OUT_OF_RANGE ? 'up' : 'down';

  const arrowSvg = ReactDOMServer.renderToStaticMarkup(
    <ThemeProvider theme={theme}>
      <TrendIcon direction={direction} />
    </ThemeProvider>
  );

  const base64SVG = btoa(arrowSvg);
  const imgSrc = `data:image/svg+xml;base64,${base64SVG}`;
  const img = new Image();
  img.src = imgSrc;
  img.width = 20;
  img.height = 40;
  return img;
};

export const buildLineChartScales = (data, unit, cadence, themeColors) => ({
  y: {
    min: 0,
    suggestedMax: getYAxisMaxValue(data),
    border: {
      display: false,
    },
    grid: {
      drawTicks: false,
      color: themeColors.borderPrimaryColor,
      lineWidth: 1,
    },
    ticks: {
      callback: (value) => formatYAxisValue(value, unit),
      color: themeColors.tertiaryColor,
      padding: 10,
      maxTicksLimit: 6,
    },
    title: {
      display: !!unit,
      text: unit,
      font: 12,
      color: themeColors.tertiaryColor,
    },
  },
  x: {
    border: {
      display: false,
    },
    grid: {
      drawTicks: true,
      drawOnChartArea: false,
      color: themeColors.dividerPrimaryColor,
    },
    ticks: {
      color: (context) => {
        const { index } = context;
        const { ticks } = context.chart.scales.x;

        if (
          (cadence === SHORT_FORM_CADENCE_ENUM.MAT &&
            index === ticks.length - 2) ||
          (cadence !== SHORT_FORM_CADENCE_ENUM.MAT &&
            index === ticks.length - 1)
        ) {
          return themeColors.primaryTextColor;
        }

        return themeColors.tertiaryColor;
      },
    },
  },
});

const createHoverLine = (chart, strokeColor) => {
  const x = chart.tooltip.caretX;
  const { ctx } = chart;
  const topY = chart.scales.y.top;
  const bottomY = chart.scales.y.bottom;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, topY);
  ctx.lineTo(x, bottomY);
  ctx.lineWidth = 1;
  ctx.setLineDash([3, 3]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
  ctx.restore();
};

export const EntityDrawerLineGraph = ({ unit, rawData, cadence }) => {
  const { themeColors } = useTheme();

  const [tooltip, setTooltip] = useState(INITIAL_TOOLTIP_STATE);

  let labelData = [];
  if (rawData?.recentYear?.length > 0) {
    labelData = rawData.recentYear;
  } else if (rawData?.previousYear?.length > 0) {
    labelData = rawData.previousYear;
  }

  const labels = labelData.map((item) =>
    formatTimestampLabel(item.timestamp, cadence)
  );
  const data = {
    labels,
    datasets: buildDatasets(rawData, cadence, themeColors),
  };

  const recentYearRawData = rawData?.recentYear;
  const lastTrend = recentYearRawData?.length
    ? recentYearRawData[recentYearRawData.length - 1].trend
    : undefined;
  const recentYearData = data?.datasets[0]?.data;

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: buildLegendPlugin(cadence, themeColors),
      tooltip: buildTooltipPlugin(tooltip, setTooltip, unit),
      annotation: buildAnnotationPlugin(recentYearData, lastTrend, cadence),
    },
    scales: buildLineChartScales(data, unit, cadence, themeColors),
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  const plugin = {
    afterDraw(chart) {
      // eslint-disable-next-line no-underscore-dangle
      if (chart?.tooltip?.caretX && chart.tooltip._active?.length) {
        createHoverLine(chart, themeColors.markerLine);
      }
    },
  };

  if (
    rawData?.recentYear?.length === 0 &&
    rawData?.previousYear?.length === 0
  ) {
    return <EntityDrawerError errorType={ERROR_TYPES_ENUM.NO_DATA} />;
  }

  return (
    <>
      <Line plugins={[plugin]} options={options} data={data} />
      <Tooltip tooltip={tooltip} title={rawData?.title} cadence={cadence} />
    </>
  );
};
