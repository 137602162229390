import { styled, Skeleton } from '@mui/material';
import { RowLabel } from './RowLabel';
import { NumberContainer } from '../numberContainer';
import { PowerScore } from '../powerScore';

const SkeletonRow = styled(Skeleton)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  backgroundColor: themeColors.borderLowContrast,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  marginTop: 8,
  height: 64,
  transform: 'scale(1)',
}));

const StyledRow = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'auto',
  minWidth: 'fit-content',
  backgroundColor: 'none',
  color: themeColors.buttonContentColor,
  borderBottom: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: 0,
  minHeight: '82px',
  gap: '32px',
  cursor: 'pointer',
  '&:visited': {
    backgroundColor: themeColors.contentCardBackgroundColor,
  },
  '&:hover': {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
  '&:hover > div': {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
}));

const LabelElements = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 1,
  height: '100%',
  left: 0,
  paddingLeft: 16,
  position: 'sticky',
  gap: 48,
  backgroundColor: themeColors.cardBackgroundColor,
  '&:hover': {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
}));

const ScoreContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Row = ({
  row,
  isShimmer,
  onRowClick = () => {},
  children: rowElements,
  showPowerscore,
  rowTitle,
  rowSubtitle = '',
  showRank,
  rank,
  productLineId,
  profile,
  showCustomListIcon = false,
}) => {
  if (isShimmer) return <SkeletonRow />;
  return (
    <StyledRow onClick={(e) => onRowClick(e)}>
      <LabelElements>
        {showRank && <NumberContainer big value={rank} />}
        {showPowerscore && (row.score || row.score === 0) && (
          <ScoreContainer>
            <PowerScore score={Math.round(row.score)} />
          </ScoreContainer>
        )}
        <RowLabel
          entityId={row.id}
          title={rowTitle}
          subTitle={rowSubtitle}
          productLineId={productLineId}
          showCustomListIcon={showCustomListIcon}
          profile={profile}
        />
      </LabelElements>
      {rowElements}
    </StyledRow>
  );
};
