import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RegionSelectionButton } from '../../../../shared/RegionSelectionButton';
import { useRegions } from './useRegions';
import { PulseContext } from '../../../context/PulseContext';
import { RegionModalProvider } from './regionModalProvider';
import { AppContext } from '../../../../../appContext';
import { APPVIEWS } from '../../../../../../../constants';

export const RegionSelection = () => {
  const { profile } = useContext(AppContext);
  const { productLineId } = useContext(PulseContext);
  const { regionId: regionIdFromParams } = useParams();

  const userTerritories =
    profile.role.type === APPVIEWS.ADMIN ? [] : profile.role.territoryIds;
  const { data: allRegions, isLoading: isAllRegionsLoading } = useRegions({
    productLineId,
    territories: [],
    isHookEnabled: !!productLineId,
  });
  const { data: userRegions, isLoading: isUserRegionsLoading } = useRegions({
    productLineId,
    territories: userTerritories,
    isHookEnabled: !!productLineId && !!userTerritories,
  });
  const [isRegionSelectionModalOpen, setisRegionSelectionModalOpen] =
    useState(false);

  const regionName = allRegions?.find(
    (i) => i.id === regionIdFromParams
  )?.listName;

  return (
    <>
      <RegionSelectionButton
        regionName={regionName}
        onClick={() => setisRegionSelectionModalOpen(true)}
      />
      <RegionModalProvider
        isOpen={isRegionSelectionModalOpen}
        handleClose={() => setisRegionSelectionModalOpen(false)}
        isAllRegionsLoading={isAllRegionsLoading}
        isUserRegionsLoading={isUserRegionsLoading}
        allRegions={allRegions}
        userRegions={userRegions}
      />
    </>
  );
};
