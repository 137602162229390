/* eslint-disable no-nested-ternary */

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material';
import { Check } from '@mui/icons-material';
import { trackVolumeGraphSelection } from '../../../trackers/appEventTracker';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '5px 0px',
    rowGap: 10,
  },
  chipText: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

const StyledChip = styled(Chip)(
  ({ theme: { themeColors, spacing }, selected }) => ({
    marginRight: 10,
    '&.MuiChip-colorPrimary': {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: spacing(2),
    '&:hover': {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);

export default function ChipSelection({
  options,
  selected,
  onSelect,
  entityName = '',
}) {
  const classes = useStyles();
  const { themeColors } = useTheme();
  return (
    <div className={classes.root}>
      {Array.isArray(options)
        ? options.map((item, i) => (
            <div key={`${item.title}-${i}`}>
              <StyledChip
                className={`test-chip-selection intercom-scripts-volume-chip-${i}`}
                label={
                  <Typography noWrap className={classes.chipText}>
                    {item.title || ''}
                  </Typography>
                }
                icon={selected === i ? <Check /> : null}
                selected={selected === i}
                variant={selected === i ? 'primary' : 'outlined'}
                color={selected === i ? 'primary' : 'default'}
                onClick={(event) => {
                  onSelect(event, i);
                  trackVolumeGraphSelection(entityName, options[i].title);
                }}
                disabled={item.disabled}
              />
              <Typography
                style={{
                  fontSize: '12px',
                  color: themeColors.tertiaryColor,
                }}
              >
                {item.disabled ? 'Not Available*' : ''}
              </Typography>
            </div>
          ))
        : null}
    </div>
  );
}
