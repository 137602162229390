import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCustomerSegments } from '../../request/configViewRequests';
import { SegmentsView } from './segmentsView';
import { ConfigSectionTitle } from './configViewTitle';
import { DEFAULT_TABS } from './configTabs';
import { ConfigSectionBody } from './styledComponents';

const formatSegments = (segments) =>
  segments.map((segment) => ({
    ...segment,
    segmentCreatedDate: formatDate(segment.segmentCreatedDate),
    segmentLastUpdated: formatDate(segment.segmentLastUpdated),
    productLineId: segment.productLineIds[0],
    intercomId: segment.intercomId || null,
    description: formatDescription(segment.description),
    segmentActivityGenerator: segment.segmentActivityGenerator,

    segmentLabelGenerator: segment.segmentLabelGenerator,
    projectSegmentLabelGenerator: segment.projectSegmentLabelGenerator,

    nodes: formatSegments(segment.nodes),
  }));

export const formatDate = (timestamp) =>
  timestamp
    ? new Date(timestamp * 1000).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

export const formatDescription = (description) =>
  Array.isArray(description)
    ? description.map((descriptionObj) => descriptionObj.label).join('')
    : description;

export const formatGeneratorConfig = (config) => {
  if (!config) return null;
  const filteredConfig = Object.entries(config).filter((entry) => {
    const value = entry[1];
    return (
      value !== null &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === 'object' && Object.keys(value).length === 0)
    );
  });

  if (!filteredConfig.length) return null;

  return filteredConfig.map(([key, value]) => {
    if (Array.isArray(value)) {
      return [key, value.join(', ')];
    }
    if (typeof value === 'object') {
      return [key, JSON.stringify(value, null, 2)];
    }
    return [key, String(value)];
  });
};

export const SegmentsViewProvider = () => {
  const [rawSegments, setSegments] = useState([]);
  const [formattedSegments, setFormattedSegments] = useState([]);

  const segmentsQuery = useQuery('segments', async () => {
    const response = await getCustomerSegments();
    setSegments(response.data.segments);
    return response.data.segments;
  });

  useEffect(() => {
    if (rawSegments?.length) {
      const segments = formatSegments(rawSegments);
      setFormattedSegments(segments);
    } else {
      segmentsQuery.refetch();
      const segments = formatSegments(rawSegments);
      setFormattedSegments(segments);
    }
  }, [rawSegments]);

  return (
    <>
      <ConfigSectionTitle currentTabTitle={DEFAULT_TABS.SEGMENTS} />
      <ConfigSectionBody>
        <SegmentsView
          segments={formattedSegments}
          rawSegments={rawSegments}
          setSegments={setSegments}
        />
      </ConfigSectionBody>
    </>
  );
};
