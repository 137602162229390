import {
  Box,
  Checkbox,
  FormHelperText,
  Input,
  InputAdornment,
  Stack,
  styled,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { ObjectiveModalActionsContext } from '../newProjectContext';
import { StyledMetricsRow } from '../styles/styledComponents';
import { AdminContext } from '../../../containers/application/appViews/adminContext';

const StyledMetricEntryRow = styled(StyledMetricsRow)({
  padding: '10px 0px 10px 20px',
});

const StyledMetricName = styled(Box)({
  fontSize: 14,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  padding: 0,
  borderRadius: 0,
  color: themeColors.primaryMaptualListFilteringColor,
}));

export const validate = {
  scoringWeight: {
    whileTyping: (value) => value.match(/^((?!0)(\d*)|0)$/),
    finishedTyping: (value) => value.match(/^(\d+)$/),
  },
};

const ScoringWeightField = styled(Input)({});

export const MetricEntry = ({
  metric,
  basketConfig,
  setBasketConfig,
  entityType = null,
}) => {
  const {
    metricFriendlyName,
    metricRxType,
    rxSourceType,
    scoringWeight: metricScoringWeight,
    visualize: visualizeStatus,
  } = metric;
  const { isProjectBuilderDemoMode } = useContext(AdminContext);
  const { updateBaskets } = useContext(ObjectiveModalActionsContext);

  const [scoringWeight, setScoringWeight] = useState(metricScoringWeight);

  const hasScoringWeightConflict = useMemo(
    () =>
      scoringWeight > 0 &&
      basketConfig.metrics[entityType] &&
      basketConfig.metrics[entityType].some(
        (entityMetric) =>
          entityMetric.scoringWeight === scoringWeight &&
          !(
            entityMetric.metricRxType === metricRxType &&
            entityMetric.rxSourceType === rxSourceType
          )
      ),
    [basketConfig, scoringWeight]
  );
  const [scoringWeightAsPercentage, setScoringWeightAsPercentage] = useState(
    (scoringWeight * 100).toString()
  );
  const [visualize, setVisualize] = useState(visualizeStatus);

  const handleScoringWeightChange = (newValueAsPercentage) => {
    setScoringWeightAsPercentage(newValueAsPercentage);
    setScoringWeight(parseFloat(newValueAsPercentage) / 100);
    const updatedMetrics = basketConfig.metrics[entityType].map((m) =>
      m.metricRxType === metricRxType
        ? {
            ...m,
            scoringWeight: parseFloat(newValueAsPercentage) / 100,
          }
        : m
    );

    const newBasketState = {
      ...basketConfig,
      metrics: {
        ...basketConfig.metrics,
        [entityType]: updatedMetrics,
      },
    };
    setBasketConfig(newBasketState);
    updateBaskets(newBasketState);
  };

  const handleVisualizationChange = (event) => {
    setVisualize(event.target.checked);
    const updatedMetrics = basketConfig.metrics[entityType].map((m) =>
      m.metricRxType === metricRxType
        ? {
            ...m,
            visualize: event.target.checked,
          }
        : m
    );
    const newBasketState = {
      ...basketConfig,
      metrics: {
        ...basketConfig.metrics,
        [entityType]: updatedMetrics,
      },
    };
    setBasketConfig(newBasketState);
    updateBaskets(newBasketState);
  };

  return (
    <StyledMetricEntryRow
      isProjectBuilderDemoMode={isProjectBuilderDemoMode}
      data-testid="test-newproject-metrics-content"
    >
      <StyledMetricName>{metricFriendlyName}</StyledMetricName>

      {!isProjectBuilderDemoMode && (
        <Stack sx={{ width: hasScoringWeightConflict ? '81px' : '50px' }}>
          <ScoringWeightField
            value={scoringWeightAsPercentage}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            error={
              +!validate.scoringWeight.finishedTyping(
                scoringWeightAsPercentage
              ) || hasScoringWeightConflict
            }
            onChange={(e) => {
              const newValue = e.target.value;
              if (validate.scoringWeight.whileTyping(newValue)) {
                handleScoringWeightChange(newValue);
              }
            }}
            data-testid="test-newproject-metric-scoringweight-field"
          />
          {hasScoringWeightConflict && (
            <FormHelperText error>Non-unique</FormHelperText>
          )}
        </Stack>
      )}

      <Box>
        <StyledCheckbox
          checked={visualize}
          onChange={handleVisualizationChange}
        />
      </Box>
    </StyledMetricEntryRow>
  );
};
