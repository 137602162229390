import { useContext, useEffect, useState } from 'react';
import isValidProp from '@emotion/is-prop-valid';
import {
  styled,
  Box,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

import { DropdownOptions } from './DropdownOptions';
import MonthIcon from '../../../assets/tab_monthly_icon.svg?react';
import QuarterIcon from '../../../assets/tab_quarterly_icon.svg?react';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { trackTerritoryOverviewOptionSet } from '../../../trackers/mixpanel';
import { getTimeframeName } from '../utils';
import { Distribution } from '../constants';

const SettingsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
}));

const SettingsSelector = styled(Box)(() => ({
  display: 'flex',
  marginTop: 24,
  gridGap: 16,
  flexWrap: 'wrap',
}));

const DateTextDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'left',
    color: themeColors.neutral60,
    marginTop: 12,
  })
);

const SettingsTabs = styled(Tabs)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  svg: {
    height: 20,
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },

  '.MuiTabs-flexContainer': {
    height: 40,
  },
});

const TimeframeTab = styled(Tab)(({ theme: { themeColors } }) => ({
  minHeight: 'auto',
  padding: '8px 12px',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 400,
  color: themeColors.buttonContentColor,
  backgroundColor: themeColors.buttonBackgroundColor,
  border: `1px solid ${themeColors.buttonActiveBorderColor}`,
  marginRight: -1,
  '&.Mui-selected': {
    color: themeColors.buttonActiveContentColor,
    backgroundColor: themeColors.buttonActiveBackgroundColor,
  },
  '&:hover': {
    color: themeColors.buttonHoverContentColor,
    backgroundColor: themeColors.buttonHoverBackgroundColor,
  },
  '.MuiTab-iconWrapper': {
    margin: '0 6px 0 0',
  },

  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },

  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
    marginRight: 0,
  },
}));

const validateProp = (prop) => prop === 'icon' || isValidProp(prop);

const DisabledTimeframeTab = styled(Tab, { shouldForwardProp: validateProp })(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    opacity: 1,
    minHeight: 'auto',
    padding: '8px 10px',
    border: `1px solid ${themeColors.buttonDisabledSurfaceColor}`,
    textTransform: 'none',
    color: themeColors.buttonDisabledContentColor,
    fontSize: 16,
    cursor: 'not-allowed',
    backgroundColor: themeColors.buttonDisabledSurfaceColor,
    marginRight: -1,

    '.MuiTab-iconWrapper': {
      margin: '0 6px 0 0',
    },

    '&:first-of-type': {
      borderRadius: '4px 0px 0px 4px',
    },

    '&:last-of-type': {
      borderRadius: '0px 4px 4px 0px',
      marginRight: 0,
    },
  })
);

const getTabCursor = (isCurrentSelection, optionAvailable) => {
  if (!optionAvailable) {
    return 'not-allowed';
  }

  return isCurrentSelection ? 'default' : 'pointer';
};

const DistributionTypeTab = styled(Tab, { shouldForwardProp: isValidProp })(
  ({ theme: { themeColors }, optionAvailable, isCurrentSelection }) => ({
    opacity: 1,
    minHeight: 'auto',
    padding: '8px 10px',
    border: '1px solid',
    borderColor: optionAvailable
      ? themeColors.buttonBorderColor
      : themeColors.buttonDisabledSurfaceColor,
    textTransform: 'none',
    color: optionAvailable
      ? themeColors.buttonContentColor
      : themeColors.buttonDisabledContentColor,
    fontSize: 16,
    cursor: getTabCursor(isCurrentSelection, optionAvailable),
    backgroundColor: optionAvailable
      ? themeColors.buttonBackgroundColor
      : themeColors.buttonDisabledSurfaceColor,
    marginRight: -1,

    '&:hover': optionAvailable && {
      color: themeColors.buttonHoverContentColor,
      backgroundColor: themeColors.buttonHoverBackgroundColor,
    },

    '&.Mui-selected': {
      color: themeColors.buttonActiveContentColor,
      backgroundColor: themeColors.buttonActiveBackgroundColor,
    },

    '.MuiTab-iconWrapper': {
      margin: '0 6px 0 0',
    },

    '&:first-of-type': {
      borderRadius: '4px 0px 0px 4px',
    },

    '&:last-of-type': {
      borderRadius: '0px 4px 4px 0px',
      marginRight: 0,
    },
  })
);

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...(props as TooltipProps)} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.themeColors.tooltipContentColor,
    backgroundColor: theme.themeColors.tooltipSurfaceColor,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.themeColors.tooltipSurfaceColor,
  },
}));

const TIMEFRAME_ICONS_MAP = {
  Month: MonthIcon,
  Quarter: QuarterIcon,
};

const DistributionTypeSelectorTabs = ({
  setIsDemandLoading,
  setIsSupplyLoading,
}) => {
  const types = [Distribution.DEMAND, Distribution.SUPPLY];
  const {
    distributionTypesAvailable,
    distributionType,
    updateDistributionType,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (newDistributionType) => {
    if (distributionType === newDistributionType) {
      return;
    }

    if (newDistributionType === Distribution.DEMAND) {
      setIsDemandLoading(true);
    }

    if (newDistributionType === Distribution.SUPPLY) {
      setIsSupplyLoading(true);
    }

    updateDistributionType(newDistributionType);
  };

  return (
    types && (
      <SettingsTabs value={distributionType}>
        {types.map((type) => {
          const firstCharCapitalized =
            type.charAt(0).toUpperCase() + type.slice(1);

          const optionAvailable = distributionTypesAvailable[type];

          return optionAvailable ? (
            <DistributionTypeTab
              key={`${type}-key`}
              label={firstCharCapitalized}
              value={type}
              onClick={() => {
                onTabClick(type);
              }}
              isCurrentSelection={
                distributionType.toUpperCase() === type.toUpperCase()
              }
              optionAvailable={optionAvailable}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <DistributionTypeTab
                key={`${type}-key`}
                label={firstCharCapitalized}
                value={type}
                optionAvailable={optionAvailable}
              />
            </CustomTooltip>
          );
        })}
      </SettingsTabs>
    )
  );
};

const TABS = [
  { id: 'month', name: 'Month' },
  { id: 'quarter', name: 'Quarter' },
];

const TimeframeSelectorTabs = ({ changeDescription }) => {
  const {
    data,
    selectedTimeframeID,
    setSelectedTimeframeID,
    distributionType,
    categoryOptions,
    selectedCategory,
    metricOptions,
    selectedMetric,
    timeframeOptionsAvailable,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (tabId) => {
    changeDescription(data?.timescaleData[tabId]?.description);

    const newTimeframe = getTimeframeName(data, tabId);

    const oldTimeframe = getTimeframeName(data, selectedTimeframeID);

    trackTerritoryOverviewOptionSet(
      'Time Range',
      newTimeframe,
      oldTimeframe,
      [
        newTimeframe,
        distributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );

    setSelectedTimeframeID(tabId);
  };

  return (
    <Box>
      <SettingsTabs value={selectedTimeframeID || 0}>
        {TABS.map((tab) => {
          const Icon = TIMEFRAME_ICONS_MAP[tab.name];

          const { id, name } = tab;

          const optionAvailable =
            timeframeOptionsAvailable.length > 0 &&
            timeframeOptionsAvailable?.includes(id);

          return optionAvailable ? (
            <TimeframeTab
              key={id}
              label={name}
              value={id}
              iconPosition="start"
              icon={<Icon />}
              onClick={() => {
                onTabClick(id);
              }}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <DisabledTimeframeTab
                key={`${id}-key`}
                label={name}
                value={id}
                icon={<Icon />}
                iconPosition="start"
              />
            </CustomTooltip>
          );
        })}
      </SettingsTabs>
    </Box>
  );
};

export const Settings = ({ setIsDemandLoading, setIsSupplyLoading }) => {
  const { data, metadata, selectedTimeframeID } = useContext(
    TerritoryOverviewContext
  );

  const [timeframeDescription, setTimeframeDescription] = useState('');

  useEffect(() => {
    const newDescription =
      data?.timescaleData?.[selectedTimeframeID]?.description;

    if (typeof newDescription === 'string' && newDescription.trim() !== '') {
      setTimeframeDescription(newDescription);
    }
  }, [data]);

  return (
    <SettingsBox>
      {metadata && (
        <>
          <SettingsSelector>
            <TimeframeSelectorTabs
              changeDescription={setTimeframeDescription}
            />

            <DistributionTypeSelectorTabs
              setIsDemandLoading={setIsDemandLoading}
              setIsSupplyLoading={setIsSupplyLoading}
            />
            <DropdownOptions />
          </SettingsSelector>
          {timeframeDescription && (
            <DateTextDescription>{timeframeDescription}</DateTextDescription>
          )}
        </>
      )}
    </SettingsBox>
  );
};
