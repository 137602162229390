import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ProjectSummary } from './projectSummary';
import EmptyView from '../../emptyview/emptyView';
import {
  getProject,
  getDetailedProjectStatus,
} from '../../../request/projectRequests';
import { ProjectStatus } from './projectStatusTimeline';
import { Wrapper } from './generic';

const getProjectById = async (projectId) => {
  const project = await getProject(projectId);
  return project;
};

const getProjectStatus = async (projectId) => {
  const project = await getDetailedProjectStatus(projectId);
  return project;
};

const initialProject = Object.freeze({
  metadata: {},
  objectives: [],
});

export const formatObjectiveName = (objective) => {
  const { products } = objective.params;
  return products.map((product) => product.productFriendlyName).join(' vs. ');
};

export const extractObjectiveNames = (objectives) =>
  objectives.reduce(
    (result, obj) => ({
      ...result,
      [obj.objectiveId]: formatObjectiveName(obj),
    }),
    {}
  );

export const ProjectOverview = () => {
  const path = window.location.pathname.split('/');
  const isAdmin = path.includes('admin');

  if (!isAdmin) {
    return null;
  }

  const projectId = path[3];
  const [objectiveNames, setObjectiveNames] = useState({});

  const {
    isLoading: isProjectStatusLoading,
    data: detailedProjectStatusResponse,
  } = useQuery([`detailedProjectStatus-${projectId}`], () =>
    getProjectStatus(projectId)
  );

  const { isLoading: isProjectLoading, data: projectResponse } = useQuery(
    ['projects', projectId],
    () => getProjectById(projectId)
  );

  useEffect(() => {
    if (projectResponse?.data) {
      setObjectiveNames(extractObjectiveNames(projectResponse.data.objectives));
    }
  }, [projectResponse]);

  if (isProjectStatusLoading || isProjectLoading) {
    return (
      <Wrapper data-testid="projectOverviewLoading">
        <EmptyView isLoading />
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid="projectOverview">
      <ProjectSummary
        project={projectResponse.data || initialProject}
        objectiveNames={objectiveNames}
      />
      <ProjectStatus
        data={detailedProjectStatusResponse?.data?.detailedProjectStatus || {}}
        objectiveNames={objectiveNames}
      />
    </Wrapper>
  );
};
