import { Box, styled } from '@mui/material';
import { SparklineBarGraph } from './barGraph';

const BarGraphBox = styled(Box)(() => ({
  height: '200px',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  padding: '0px 16px 32px 16px',
}));

export const ExpandedBarGraphContainer = ({
  row,
  chartLabels,
  yTitle,
  tooltipDataPointTitle,
}) => {
  const { data } = row;

  return (
    <BarGraphBox>
      <SparklineBarGraph
        id={`expanded-bar-graph-${data.join('-')}`}
        data={data}
        labels={chartLabels}
        expanded
        yTitle={yTitle}
        tooltipDataPointTitle={tooltipDataPointTitle}
      />
    </BarGraphBox>
  );
};
